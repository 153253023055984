import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Container, Draggable } from 'react-smooth-dnd'
import { Form, Input, Modal, Button, message } from 'antd'

import {
  idContractSelector,
  loadingContractSelector,
  loadedContractSelector,
  stepsContractSelector,
  stepLoadingContractSelector,
  stepLoadedContractSelector,
  updatedContractSelector
} from '../../store/reducers/templatesAdmin/selectors'
import {
  getStepList,
  postContractStep,
  deleteContractStep,
  updateContractStep,
  updateContractStepPosition,
  updateContractFieldPosition,
  moveContractFieldPosition
} from '../../store/reducers/templatesAdmin/actions'

import FieldsList from './FieldsList'
import styles from './style.module.scss'
import { FORM_VALIDATE_MESSAGES } from '../../constants/staticErrors'



class AdminStepsBuilder extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isModalVisible: false,
      isModalDelete: false,
      modalStepId: null,
      modalTitle: null,
      modalDescription: null,
      steps: props.contractSteps.map((item) => {
        const o1 = item.toJS()
        const itemFields = Object.entries(o1.fields).map(([key, item], index) => item)

        o1.fields = itemFields.sort((a, b) => parseFloat(a.position) - parseFloat(b.position))
        return o1
      }),
      moveFieldData: {}
    }

    this.formRef = React.createRef()
    this.containerRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.contractSteps.length !== this.props.contractSteps.length ||
      prevProps.contractStepLoading !== this.props.contractStepLoading && !this.props.contractStepLoading
    ) {
      this.setState({
        steps: this.props.contractSteps.map((item) => {
          const o1 = item.toJS()
          const itemFields = Object.entries(o1.fields).map(([key, item], index) => item)

          o1.fields = itemFields.sort((a, b) => parseFloat(a.position) - parseFloat(b.position))
          return o1
        }).sort((a, b) => parseFloat(a.position) - parseFloat(b.position))
      })
    }

    if (
      JSON.stringify(prevState.moveFieldData) !== JSON.stringify(this.state.moveFieldData) &&
      Object.keys(this.state.moveFieldData).length > 1
    ) {
      const { contractId, moveFieldPosition } = this.props
      const { moveFieldData } = this.state

      // Handle move Field action
      if (moveFieldData.stepId && moveFieldData.stepId !== moveFieldData.newStepId) {
        moveFieldPosition({
          title: moveFieldData.fieldTitle,
          new_position: moveFieldData.newPosition,
          new_step_id: moveFieldData.newStepId
        }, contractId, moveFieldData.stepId, moveFieldData.fieldId )

        this.setState({
          moveFieldData: {}
        })
      }
    }

    if (prevProps.contractUpdated !== this.props.contractUpdated && this.props.contractUpdated) {
      const { contractId, getStepList } = this.props

      getStepList(contractId)
    }
  }

  componentWillUnmount() {
    message.destroy()
  }

  render() {
    const { TextArea } = Input
    const { isModalVisible, isModalDelete, modalTitle, modalDescription, steps } = this.state
    const { contractStepLoading, fieldClickHandler } = this.props

    return (
      <>
        <div className={styles.wrapper} ref={this.containerRef}>
          <Container
            onDrop={this.handleStepDrop}
            getChildPayload={this.getStepPayload}
            getGhostParent={() => this.containerRef.current ? this.containerRef.current : document.getElementById('root')}
            dropPlaceholder={{
              animationDuration: 150,
              showOnTop: true,
              className: styles.dropPreview
            }}
          >
            {steps.map(item => {
              return (
                <Draggable key={item.id}>
                  <FieldsList
                    containerRef={this.containerRef}
                    item={item}
                    itemFields={item.fields}
                    onDrop={this.handleFieldDrop}
                    getChildPayload={this.getFieldPayload}
                    onEdit={this.showStepModal}
                    onDelete={this.showStepModal}
                    onClick={fieldClickHandler}
                  />
                </Draggable>
              )
            })}
          </Container>
        </div>
        <Button
          type='primary'
          onClick={this.showStepModal}
        >Add New Step</Button>
        <Modal
          title={isModalDelete ? 'Delete Step?' : 'Step Name'}
          visible={isModalVisible}
          confirmLoading={contractStepLoading}
          okButtonProps={{danger: isModalDelete}}
          okText={isModalDelete ? 'Delete' : 'Save'}
          cancelText='Cancel'
          onCancel={this.hideStepModal}
          onOk={() => {
            this.formRef.current
              .validateFields()
              .then((values) => {
                this.submitStepModal(values)
              })
              .catch((info) => {
                console.log('Validate Failed:', info)
              })
          }}
        >
          <Form
            ref={this.formRef}
            layout='vertical'
            name='step-content'
            validateMessages={FORM_VALIDATE_MESSAGES}
            initialValues={{
              title: modalTitle,
              description: modalDescription,
            }}
          >
            <Form.Item
              name='title'
              label='Step name'
              rules={[
                {
                  required: true,
                  whitespace: true,
                  max: 80,
                },
              ]}
            >
              <Input
                disabled={isModalDelete}
                placeholder='New step'
                ref={(input) => {input && input.focus()}}
              />
            </Form.Item>
            <Form.Item
              name='description'
              label='Description'
              rules={[
                {
                  max: 120,
                },
              ]}
            >
              <TextArea
                rows={4}
                disabled={isModalDelete}
                placeholder='Add description of the step'
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }

  handleStepDrop = (dropResult) => {
    const { contractId, updateStepPosition } = this.props
    const { steps } = this.state

    this.setState({
      steps: this.applyDrag(steps, dropResult)
    }, () => {
      updateStepPosition({position: dropResult.addedIndex}, contractId, dropResult.payload.id)
    })
  }

  handleFieldDrop = (stepId, dropResult) => {
    const { contractId, updateFieldPosition } = this.props
    const { steps } = this.state

    if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
      const stepList = steps.slice()
      const step = stepList.filter(p => p.id === stepId)[0]
      const stepIndex = stepList.indexOf(step)
      const newStep = Object.assign({}, step)

      newStep.fields = Object.entries(newStep.fields).map(([key, item], index) => item)
      newStep.fields = this.applyDrag(newStep.fields, dropResult)
      stepList.splice(stepIndex, 1, newStep)

      this.setState({
        steps: stepList
      }, () => {
        if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
          return updateFieldPosition({position: dropResult.addedIndex}, null, contractId, step.id, dropResult.payload.id)
        }

        if (dropResult.removedIndex !== null) {
          this.setState(prevState => ({
            moveFieldData: {
              ...prevState.moveFieldData,
              stepId : stepId
            }
          }))
        }

        if (dropResult.addedIndex !== null) {
          this.setState(prevState => ({
            moveFieldData: {
              ...prevState.moveFieldData,
              newStepId : stepId,
              newPosition: dropResult.addedIndex,
              fieldId: dropResult.payload.id,
              fieldTitle: dropResult.payload.title
            }
          }))
        }
      })
    }
  }

  applyDrag = (items, dropResult) => {
    const { removedIndex, addedIndex, payload } = dropResult
    if (removedIndex === null && addedIndex === null) return items

    const result = Array.from(items)
    let itemToAdd = payload

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0]
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd)
    }

    return result
  }

  getStepPayload = (index) => {
    return this.state.steps[index]
  }

  getFieldPayload = (stepId, index) => {
    const fields = this.state.steps.filter(p => p.id === stepId)[0].fields

    return fields[index]
  }

  showStepModal = (values) => {
    const { id, title, description, isDelete } = values

    if (id && title) {
      this.setState({
        isModalVisible: true,
        modalStepId: id,
        modalTitle: title,
        modalDescription: description,
        isModalDelete: !!isDelete
      }, () => {
        this.formRef.current && this.formRef.current.setFieldsValue({
          title: title,
          description: description
        })
      })
    } else {
      this.setState({
        isModalVisible: true
      }, () => {
        this.formRef.current && this.formRef.current.setFieldsValue({
          title: null,
          description: null
        })
      })
    }
  }

  hideStepModal = () => {
    this.setState({
      isModalVisible: false,
      isModalDelete: false,
      modalStepId: null,
      modalTitle: null,
      modalDescription: null
    })
  }

  submitStepModal = (values) => {
    const { modalStepId, isModalDelete } = this.state
    const { contractId, postStep, updateStep, deleteStep } = this.props

    modalStepId ?
      isModalDelete ?
        deleteStep(contractId, modalStepId) :
        updateStep(values, contractId, modalStepId) :
      postStep(values, contractId)

    setTimeout(() => {
      this.formRef.current.resetFields()
      this.hideStepModal()
    }, 750)
  }
}

export default connect(
  store => {
    return {
      contractId: idContractSelector(store),
      contractSteps: stepsContractSelector(store),
      contractStepLoading: stepLoadingContractSelector(store),
      contractStepLoaded: stepLoadedContractSelector(store),
      contractLoading: loadingContractSelector(store),
      contractLoaded: loadedContractSelector(store),
      contractUpdated: updatedContractSelector(store),
    }
  },
  {
    postStep: postContractStep,
    deleteStep: deleteContractStep,
    updateStep: updateContractStep,
    getStepList: getStepList,
    updateStepPosition: updateContractStepPosition,
    updateFieldPosition: updateContractFieldPosition,
    moveFieldPosition: moveContractFieldPosition
  }
)(AdminStepsBuilder);
