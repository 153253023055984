import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Steps } from 'antd'
import TextTransition, { presets } from 'react-text-transition'

import ProductIntro from './ProductIntro'
import ProductStep from './ProductStep'
import styles from './product.module.scss'
import { START_PAGE } from '../../constants/siteMap'



const Product = ({productId, templateId, isEditPage, setIsStartScreen, isStartScreen, ...props}) => {
  const { Step } = Steps
  const history = useHistory()
  const [steps, setSteps] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [validationError, setValidationError] = useState(null)
  const [isNextStep, setIsNextStep] = useState(false)
  const [isDataUpdate, handleDataUpdate] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')

  const contractTmpId = useSelector(store => store.templatesAdmin.tmpId)
  const contractTitle = useSelector(store => store.templatesAdmin.title)
  const contractPostLoading = useSelector(store => store.templatesAdmin.postLoading)
  const contractPostLoaded = useSelector(store => store.templatesAdmin.postLoaded)
  const contractSteps = useSelector(store => store.templatesAdmin.steps.valueSeq().toArray())
  const error = useSelector(store => store.templatesAdmin.error)
  const notification = useSelector(store => store.templatesAdmin.notification)

  const isLastStep = steps.length - 1 === currentStep

  useEffect(() => {
    contractTmpId || isEditPage ?
      setIsStartScreen(false) :
      setIsStartScreen(true)
  }, [contractTmpId, isEditPage])

  useEffect(() => {
    const lastStep = {
      key: 'last-step',
      title: 'Confirmation',
      data: {
        title: 'Confirmation',
        description: 'Carefully review your contract, if you want to correct something you can use the Back button below or click the Step up above to return to the field you want to change.'
      }
    }

    if (contractSteps.length === 0 && steps.length !== 0) {
      return setSteps([])
    }

    if (contractSteps.length > 0 && steps.length === 0) {
      return setSteps(contractSteps.map((step) => {
        return {
          key: step.id,
          title: step.title,
          data: step.toJS(),
        }
      }))
    }

    if (contractSteps.length === steps.length && steps.length !== 0) {
      return setSteps([...steps, lastStep])
    }
  }, [contractSteps, steps])

  useEffect(() => {
    if (!contractPostLoading && contractPostLoaded) {
      if (error && error.length > 0) return
      if (isStartScreen) return

      if (isNextStep) {
        if (isLastStep) return

        setCurrentStep(currentStep + 1)
      }
    }
  }, [contractPostLoading])

  useEffect(() => {
    if (notification) {
      setNotificationMessage(notification)
    } else {
      setNotificationMessage('')
    }

    let timer = setTimeout(() => setNotificationMessage(''), 2000)

    return () => {
      clearTimeout(timer)
    }
  }, [notification])

  const prevStep = () => {
    if (isEditPage && currentStep === 0) return history.push({pathname: START_PAGE, search: '?tab=drafts'})
    if (currentStep === 0) return setIsStartScreen(true)

    setCurrentStep(currentStep - 1)
    setIsNextStep(false)
  }

  const nextStep = () => {
    if (isStartScreen) return

    handleDataUpdate(true)
    setIsNextStep(true)
  }

  const onStepsChange = (current) => {
    setCurrentStep(current)
  }

  return isStartScreen ?
    <ProductIntro
      handleNextStep={nextStep}
      templateId={templateId}
      productId={productId}
    /> :
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1>{contractTitle}</h1>
        <Steps current={currentStep} onChange={onStepsChange}>
          {steps.length > 0 && steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className={styles.messageBox}>
          <TextTransition
            text={notificationMessage}
            springConfig={presets.wobbly}
          />
        </div>
      </div>
      <div className={isLastStep ? styles.fullContent : styles.content}>
        {
          steps.length > 0 &&
            <ProductStep
              step={steps[currentStep]}
              nextStep={nextStep}
              prevStep={prevStep}
              isNextStep={isNextStep}
              isLastStep={isLastStep}
              currentStep={currentStep}
              handleStepsChange={onStepsChange}
              handleDataUpdate={handleDataUpdate}
              setValidationError={setValidationError}
              isDataUpdate={isDataUpdate}
              productId={productId}
            />
        }
      </div>
    </div>
}

export default Product;
