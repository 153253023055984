import { API } from '../../../constants/variables'
import * as types from './types'
import { getGMTDateTime } from '../../utils'



export function postTemplate(data) {
  return {
    type: types.POST_TEMPLATE,
    postAPI: `${API}template/create`,
    postData: data
  }
}

export function postTemplatesFolder(data) {
  return {
    type: types.POST_TEMPLATE_FOLDER,
    postAPI: `${API}folder/create`,
    payload: data,
    postData: data,
  }
}

export function updateTemplatesFolder(data) {
  return {
    type: types.UPDATE_TEMPLATE_FOLDER,
    putAPI: `${API}folder/update/${data.id}`,
    payload: data,
    putData: {
      title: data.title,
      chapter: data.chapter,
      parent_id: 0
    }
  }
}

export function updateTemplatesFolderOpenStatus(data) {
  return {
    type: types.UPDATE_TEMPLATE_FOLDER_OPEN_STATUS,
    putAPI: `${API}folder/open_state/${data.id}`,
    payload: data,
    putData: {
      state: data.status === true ? 1 : 0,
    }
  }
}

export function updateTemplatesPosition(data) {
  return {
    type: types.UPDATE_TEMPLATE_POSITION,
    postAPI: `${API}folder/${data.id}/template/set_positions`,
    payload: data,
    postData: data.data
  }
}

export function getTemplatesList() {
  const dt = getGMTDateTime()

  return {
    type: types.GET_TEMPLATES,
    getAPI: `${API}folder/list/template?dt=${dt}`,
  }
}

export function deleteTemplatesFolder(data) {
  return {
    type: types.DELETE_TEMPLATE_FOLDER,
    deleteAPI: `${API}folder/delete/${data.id}`,
    payload: {data},
  }
}

export function deleteTemplatesFolderGlobal(data) {
  return {
    type: types.DELETE_TEMPLATE_FOLDER,
    deleteAPI: `${API}folder/delete/${data.id}/global`,
    payload: {data},
  }
}

export function cloneTemplatesContract(id) {
  return {
    type: types.CLONE_TEMPLATES_CONTRACT,
    postAPI: `${API}template/${id}/clone`,
    postData: {},
  }
}

export function handleTabsChange() {
  return {
    type: types.HANDLE_TABS_CHANGE,
  }
}
