import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Steps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { getContractValidation, publishContract, resetErrors } from '../../store/reducers/templatesAdmin/actions'
import { handleTabsChange } from '../../store/reducers/templates/actions'
import AdminBasicsForm from '../AdminBasicsForm'
import AdminEditor from '../AdminEditor'
import AdminPublish from '../AdminPublish'
import styles from './steps.module.scss'
import { START_PAGE } from '../../constants/siteMap'
import { PROCESSING_COMPLETE_TEXT } from '../../constants/staticTexts'



const AdminSteps = (props) => {
  const { Step } = Steps
  const { locationContractId } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentStep, setCurrentStep] = useState(0)
  const [currentStepValue, setCurrentStepValue] = useState(null)
  const [isNextStep, setIsNextStep] = useState(false)
  const [isDataUpdate, handleDataUpdate] = useState(false)
  const [isSwitchStep, handleSwitchStep] = useState(false)
  const contractId = useSelector(store => store.templatesAdmin.id)
  const contractStatus = useSelector(store => store.templatesAdmin.status)
  const contractPostLoading = useSelector(store => store.templatesAdmin.postLoading)
  const contractPostLoaded = useSelector(store => store.templatesAdmin.postLoaded)
  const error = useSelector(store => store.templatesAdmin.error)
  const notification = useSelector(store => store.templatesAdmin.notification)
  const steps = [
    {
      title: 'Basics',
      content: <AdminBasicsForm
        locationContractId={locationContractId}
        handleDataUpdate={handleDataUpdate}
        handleSwitchStep={handleSwitchStep}
        isDataUpdate={isDataUpdate}
      />,
    },
    {
      title: 'Edit contract',
      content: <AdminEditor
        locationContractId={locationContractId}
        handleDataUpdate={handleDataUpdate}
        handleSwitchStep={handleSwitchStep}
        isDataUpdate={isDataUpdate}
      />,
    },
    {
      title: 'Preview',
      content: <AdminPublish
        handleDataUpdate={handleDataUpdate}
        handleSwitchStep={handleSwitchStep}
        isDataUpdate={isDataUpdate}
      />,
    },
  ]

  useEffect(() => {
    // handle Data Update
    if (!contractPostLoading && contractPostLoaded && isSwitchStep) {
      Number.isInteger(currentStepValue) ?
        setCurrentStep(currentStepValue) :
        isNextStep ?
          setCurrentStep(currentStep + 1) :
          currentStep === 0 ?
            history.push(START_PAGE) :
            setCurrentStep(currentStep - 1)

      handleSwitchStep(false)
      setCurrentStepValue(null)
      dispatch(handleTabsChange())
    }

    if (!contractPostLoading && contractPostLoaded && notification) {
      // Success validation trigger
      if (notification === '...' && error && error.length === 0) dispatch(publishContract(contractId))

      // Success publish trigger
      if (notification === PROCESSING_COMPLETE_TEXT) {
        dispatch(resetErrors())
        history.push({
          pathname: START_PAGE,
          search: '?tab=admin&send=complete',
        })
      }
    }
  }, [contractPostLoading])

  useEffect(() => {
    if (isDataUpdate) {
      let timer = setTimeout(() => {
        handleDataUpdate(false)
      }, 1000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [isDataUpdate])

  const prevStep = () => {
    currentStep !== 0 ?
      handleDataUpdate(true) :
      history.push(START_PAGE)
    setIsNextStep(false)
  }

  const nextStep = () => {
    handleDataUpdate(true)
    setIsNextStep(true)
  }

  const onStepsChange = current => {
    handleDataUpdate(true)
    setCurrentStepValue(current)
  }

  const onPublishComplete = () => {
    setIsNextStep(true)
    dispatch(getContractValidation(contractId))
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.column}>
          <Button
            onClick={() => prevStep()}
            loading={contractPostLoading && isSwitchStep && !isNextStep}
            disabled={contractPostLoading}
          >
            Back to {currentStep > 0 ? steps[currentStep - 1].title : 'Admin Panel'}
          </Button>
        </div>
        <div className={styles.column}>
          <Steps current={currentStep} onChange={onStepsChange}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
        <div className={styles.column}>
          {currentStep < steps.length - 1 && (
            <Button
              type='primary'
              onClick={() => nextStep()}
              loading={contractPostLoading && isSwitchStep && isNextStep}
              disabled={contractPostLoading}
            >
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button
              type='primary'
              onClick={onPublishComplete}
              loading={contractPostLoading && isSwitchStep && isNextStep}
              disabled={contractPostLoading}
            >
              {contractStatus ? 'Update' : 'Publish'}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.content + ' toolbar-anchor'}>
        {
          steps[currentStep].content
        }
      </div>
    </div>
  )
}

export default AdminSteps;
