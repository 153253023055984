import { API } from '../../../constants/variables'
import * as types from './types'
import { getGMTDateTime } from '../../utils'



export function getAdminTemplates() {
  const dt = getGMTDateTime()

  return {
    type: types.GET_CONTRACTS_LIST,
    getAPI: `${API}template/admin_contracts_list?dt=${dt}`,
  }
}

export function getAdminTemplate(id, withParent) {
  return {
    type: types.GET_CONTRACT,
    getAPI: `${API}template/${id}${withParent ? '?with_parent_content=1' : ''}`,
  }
}

export function getTemplateTitle(id) {
  return {
    type: types.GET_CONTRACT_TITLE,
    getAPI: `${API}template/${id}/title`,
  }
}

export function getTemplatesNotifications() {
  return {
    type: types.GET_TEMPLATES_NOTIFICATIONS,
    getAPI: `${API}tabs_notifications`,
  }
}

export function createContractDraft(data) {
  return {
    type: types.CREATE_CONTRACT_DRAFT,
    postAPI: `${API}template/create_draft`,
    postData: {},
  }
}

export function createContractUserDraft(data, id) {
  return {
    type: types.CREATE_CONTRACT_USER_DRAFT,
    postAPI: `${API}template/${id}/create_draft`,
    postData: data,
  }
}

export function undraftContract(id) {
  return {
    type: types.UNDRAFT_CONTRACT,
    putAPI: `${API}template/${id}/undraft`,
    putData: {}
  }
}

export function deleteContract(id) {
  return {
    type: types.DELETE_CONTRACT,
    deleteAPI: `${API}template/${id}/delete`,
    payload: id
  }
}

export function deleteContractBlankPDF(id) {
  return {
    type: types.DELETE_CONTRACT_BLANK_PDF,
    deleteAPI: `${API}template/${id}/delete_uploaded_template_pdf`,
    payload: id
  }
}

export function createContract(data) {
  return {
    type: types.CREATE_CONTRACT,
    postAPI: `${API}template/create`,
    postData: data,
    payload: data
  }
}

export function cloneContract(id) {
  return {
    type: types.CLONE_CONTRACT,
    postAPI: `${API}template/${id}/clone`,
    postData: {},
  }
}

export function updateContract(data, id) {
  return {
    type: types.UPDATE_CONTRACT,
    putAPI: `${API}template/${id}/update`,
    putData: data,
    payload: data
  }
}

export function sendContract(id) {
  return {
    type: types.SEND_CONTRACT,
    putAPI: `${API}template/${id}/send`,
    putData: {},
  }
}

export function updateContractContent(data, id) {
  return {
    type: types.UPDATE_CONTRACT_CONTENT,
    putAPI: `${API}template/update/content/${id}`,
    putData: data,
    payload: data
  }
}

export function updateContractContentAdmin(data, id) {
  return {
    type: types.UPDATE_CONTRACT_CONTENT_ADMIN,
    putAPI: `${API}template/${id}/update_admin_content`,
    putData: data,
    payload: data
  }
}

export function getContractSignData(id) {
  return {
    type: types.GET_CONTRACT_SIGN_DATA,
    getAPI: `${API}envelope/sign/${id}`,
  }
}

export function updateContractSignStatus(id) {
  return {
    type: types.UPDATE_CONTRACT_SIGN_STATUS,
    getAPI: `${API}envelope/${id}/update_status`,
  }
}

export function getContractCategories() {
  return {
    type: types.GET_CONTRACT_CATEGORIES,
    getAPI: `${API}categories_list`,
  }
}

export function postContractStep(data, id) {
  return {
    type: types.POST_CONTRACT_STEP,
    postAPI: `${API}template/${id}/step`,
    postData: data,
    payload: data
  }
}

export function updateContractStep(data, contractId, stepId) {
  return {
    type: types.UPDATE_CONTRACT_STEP,
    putAPI: `${API}template/${contractId}/step/${stepId}/update_title`,
    putData: data,
    payload: {data, stepId}
  }
}

export function deleteContractStep(contractId, stepId) {
  return {
    type: types.DELETE_CONTRACT_STEP,
    deleteAPI: `${API}template/${contractId}/step/${stepId}`,
    payload: {id: stepId}
  }
}

export function updateContractStepPosition(data, contractId, stepId) {
  return {
    type: types.UPDATE_CONTRACT_STEP_POSITION,
    putAPI: `${API}template/${contractId}/step/${stepId}/update_position`,
    putData: data,
    payload: {data, stepId}
  }
}

export function postContractField(data, contractId, stepId) {
  return {
    type: types.POST_CONTRACT_FIELD,
    postAPI: `${API}template/${contractId}/step/${stepId}/field`,
    postData: data,
    payload: {data, stepId}
  }
}

export function getContractField(contractId, stepId, fieldId) {
  return {
    type: types.GET_CONTRACT_FIELD_DATA,
    getAPI: `${API}template/${contractId}/step/${stepId}/field/${fieldId}`,
  }
}

export function updateContractField(data, contractId, stepId, fieldId) {
  return {
    type: types.UPDATE_CONTRACT_FIELD,
    putAPI: `${API}template/${contractId}/step/${stepId}/field/${fieldId}/update`,
    putData: data,
    payload: {data, stepId, fieldId}
  }
}

export function deleteContractField(contractId, stepId, fieldId, fieldData) {
  return {
    type: types.DELETE_CONTRACT_FIELD,
    deleteAPI: `${API}template/${contractId}/step/${stepId}/field/${fieldId}`,
    payload: {stepId, fieldId, fieldData}
  }
}

export function updateContractFieldPosition(data, parties, contractId, stepId, fieldId) {
  return {
    type: types.UPDATE_CONTRACT_FIELD_POSITION,
    putAPI: `${API}template/${contractId}/step/${stepId}/field/${fieldId}/update_position`,
    putData: data,
    payload: {data, parties, stepId, fieldId}
  }
}

export function moveContractFieldPosition(data, contractId, stepId, fieldId) {
  return {
    type: types.MOVE_CONTRACT_FIELD_POSITION,
    putAPI: `${API}template/${contractId}/step/${stepId}/field/${fieldId}/move`,
    putData: data,
    payload: {data, stepId, fieldId}
  }
}

export function getStepList(id) {
  return {
    type: types.GET_CONTRACT_STEPS,
    getAPI: `${API}template/${id}/step/list`,
  }
}

export function getFieldTypes() {
  return {
    type: types.GET_FIELD_TYPES,
    getAPI: `${API}utils/field_types`,
  }
}

export function postHellosignField(data, contractId) {
  return {
    type: types.POST_HELLOSIGN_FIELD,
    postAPI: `${API}template/${contractId}/hs_field`,
    postData: data,
    payload: data
  }
}

export function deleteHellosignField(contractId, fieldId) {
  return {
    type: types.DELETE_HELLOSIGN_FIELD,
    deleteAPI: `${API}template/${contractId}/field/${fieldId}`,
  }
}

export function getContractValidation(id) {
  return {
    type: types.GET_CONTRACT_VALIDATION,
    getAPI: `${API}template/${id}/validation`,
  }
}

export function publishContract(id) {
  return {
    type: types.PUBLISH_CONTRACT,
    putAPI: `${API}template/${id}/publish`,
    putData: {},
  }
}

export function postUserFields(data, contractId, stepId) {
  return {
    type: types.POST_USER_FIELDS,
    postAPI: `${API}template/${contractId}/step/${stepId}/save`,
    postData: data,
    payload: data
  }
}

export function attachContractByTempId(id) {
  return {
    type: types.ATTACH_CONTRACT,
    getAPI: `${API}template/attach/${id}`,
  }
}

export function resetErrors() {
  return {
    type: types.RESET_ERRORS
  }
}
