import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'

import DocumentNavigator from './DocumentNavigator'
import styles from './preview.module.scss'
import { APP_DOMAIN } from '../../constants/variables'



const getNodeChunks = (htmlDocument) => {
  let offscreenDiv = document.createElement('div')
  offscreenDiv.className = 'page'
  offscreenDiv.style.position = 'absolute'
  offscreenDiv.style.top = '-3000px'
  offscreenDiv.innerHTML = htmlDocument
  offscreenDiv.display = 'flex'
  offscreenDiv.flexWrap = 'wrap'
  document.body.appendChild(offscreenDiv)

  let offscreenRect = offscreenDiv.getBoundingClientRect()
  const chunks = []
  let currentChunk = []

  for (let i = 0; i < offscreenDiv.children.length; i++) {
    let current = offscreenDiv.children[i]
    let currentRect = current.getBoundingClientRect()
    currentChunk.push(current)

    if (currentRect.bottom > (offscreenRect.bottom - 150) || current.classList.contains('quill-divider')) {
      // current element is overflowing offscreenDiv, remove it from current chunk
      currentChunk.pop()
      // remove all elements in currentChunk from offscreenDiv
      currentChunk.forEach(elem => elem.remove())
      // since children were removed from offscreenDiv, adjust i to start back at current eleme on next iteration
      i -= currentChunk.length

      // push current completed chunk to the resulting chunklist
      chunks.push(currentChunk)
      // initialise new current chunk
      currentChunk = [current]
      offscreenRect = offscreenDiv.getBoundingClientRect()
    }
  }

  // currentChunk may not be empty but we need the last elements
  if (currentChunk.length > 0) {
    currentChunk.forEach(elem => elem.remove())

    chunks.push(currentChunk)
  }

  // offscreenDiv is not needed anymore
  offscreenDiv.remove()

  return chunks
}

const appendChunksToPages = (chunks, activePageNumber, contractSKU) => {
  let container = document.createElement('div')
  const footerLogoSrc = `${APP_DOMAIN}/images/logo-blue.svg`

  chunks.forEach((chunk, index) => {
    const pageNumber = index + 1
    let page = document.createElement('div')
    page.className = `page ${activePageNumber === pageNumber ? 'active' : 'hidden'}`

    chunk.forEach(elem => page.appendChild(elem))
    container.appendChild(page)

    let footer = document.createElement('footer')
    footer.className = 'page-footer'
    footer.innerHTML = `
        <svg width="90" viewBox="0 0 920 294" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M259.281 292.86V207.227H275.419V282.074H296.674V292.86H259.281Z" fill="#3331B1"/>
          <path d="M343.646 292.86V207.227H380.802V218.231H359.812V242.816H376.406V253.821H359.812V282.074H381.067V292.86H343.646Z" fill="#3331B1"/>
          <path d="M450.975 294C442.583 294 436.542 291.466 432.853 286.397C429.163 281.329 427.315 273.961 427.309 264.293V236.411C427.248 232.135 427.652 227.865 428.514 223.677C429.234 220.317 430.608 217.133 432.558 214.306C434.519 211.573 437.234 209.472 440.371 208.262C444.276 206.821 448.419 206.137 452.579 206.248C461.364 206.248 467.664 208.323 471.48 212.472C475.296 216.622 477.195 222.916 477.176 231.355V235.537H461.702V231.089C461.702 229.188 461.702 227.649 461.569 226.442C461.475 225.211 461.262 223.992 460.933 222.802C460.688 221.744 460.182 220.764 459.462 219.951C458.712 219.231 457.815 218.683 456.832 218.345C455.524 217.902 454.149 217.693 452.769 217.728C451.671 217.714 450.578 217.862 449.522 218.165C448.68 218.412 447.877 218.78 447.14 219.258C446.458 219.731 445.898 220.36 445.507 221.092C445.091 221.818 444.75 222.586 444.491 223.382C444.226 224.314 444.048 225.268 443.96 226.233C443.836 227.402 443.751 228.438 443.722 229.331C443.694 230.224 443.665 231.384 443.665 232.79V267.344C443.665 272.513 444.327 276.292 445.649 278.681C446.972 281.069 449.503 282.267 453.244 282.273C454.921 282.332 456.587 281.966 458.085 281.209C459.409 280.482 460.477 279.363 461.142 278.006C461.909 276.462 462.431 274.808 462.689 273.103C463.018 270.901 463.17 268.676 463.145 266.45V259.427H453.548V249.354H477.689V292.841H467.189L466.04 284.183C463.351 290.728 458.329 294 450.975 294Z" fill="#3331B1"/>
          <path d="M523.664 292.86L541.701 207.227H558.56L576.55 292.86H561.522L557.934 272.979H542.564L538.919 292.86H523.664ZM544.283 262.811H556.168L550.216 227.649L544.283 262.811Z" fill="#3331B1"/>
          <path d="M623.625 292.86V207.227H639.764V282.074H661.019V292.86H623.625Z" fill="#3331B1"/>
          <path d="M49.635 178.641C42.8999 178.749 36.1904 177.787 29.7564 175.79C24.5163 174.17 19.6679 171.481 15.5168 167.893C11.6598 164.373 8.5479 160.114 6.36544 155.368C3.98765 150.268 2.32642 144.863 1.42903 139.308C0.429022 132.92 -0.0472217 126.462 0.00506755 119.997V59.1769C-0.0560689 52.4292 0.436062 45.6878 1.4765 39.0207C2.39166 33.3855 4.05501 27.8979 6.4224 22.7038C8.52899 17.9777 11.6313 13.7634 15.5168 10.3497C19.7036 6.88156 24.547 4.29568 29.7564 2.74714C36.2215 0.814203 42.9457 -0.108957 49.6919 0.0102269C58.375 0.0102269 65.8049 1.16962 71.9817 3.48839C77.6987 5.48678 82.7563 9.02101 86.6011 13.7043C90.2573 18.3497 92.9083 23.7058 94.3854 29.432C96.0845 36.2965 96.8823 43.3535 96.7587 50.4245V65.373H66.1435V50.529C66.1435 41.8241 65.6878 35.9639 64.7765 32.9482C62.9538 27.0499 58.5015 23.8822 51.4197 23.445H49.7394C47.3727 23.4066 45.0139 23.727 42.743 24.3953C40.8784 24.9497 39.1924 25.9854 37.854 27.3983C36.6351 28.7024 35.6089 30.1745 34.8068 31.7698C33.9281 33.6891 33.3425 35.7298 33.0695 37.8233C32.7101 40.1061 32.4661 42.4056 32.3386 44.7131C32.2373 46.8228 32.1835 49.49 32.1772 52.7147V126.611C32.1772 136.862 33.4429 144.148 35.9744 148.469C38.5059 152.789 43.0816 154.962 49.7014 154.988C51.7105 155.023 53.7135 154.757 55.6441 154.199C57.1759 153.763 58.6301 153.09 59.954 152.203C61.1972 151.295 62.206 150.102 62.8968 148.725C63.6365 147.38 64.253 145.971 64.7385 144.515C65.2451 142.748 65.5633 140.933 65.6878 139.098C65.8967 136.78 66.0201 134.822 66.0485 133.207C66.077 131.591 66.1055 129.405 66.1055 126.554V111.055H96.7587V125.376C96.8093 131.265 96.3519 137.147 95.3917 142.957C94.5201 148.026 92.9449 152.949 90.7116 157.582C88.6816 161.934 85.7725 165.817 82.1678 168.986C78.2077 172.243 73.6281 174.661 68.7066 176.094C62.5141 177.901 56.0841 178.76 49.635 178.641Z" fill="#3331B1"/>
          <path d="M134.409 167.076V11.4617H170.036C177.121 11.3515 184.195 12.0303 191.13 13.4858C196.646 14.6715 201.863 16.9706 206.461 20.2426C210.878 23.5011 214.263 27.9653 216.211 33.1003C218.369 38.5171 219.448 45.1345 219.448 52.9524C219.448 62.7153 217.992 70.6917 215.081 76.8814C212.194 83.0675 207.041 87.9057 200.689 90.3949L222.258 167.076H193.854L175.286 96.0303H162.727V167.076H134.409ZM162.727 77.9173H169.571C177.944 77.9173 183.896 76.1338 187.428 72.567C190.959 69.0001 192.725 62.8991 192.725 54.2639C192.725 45.9391 191.193 39.9806 188.13 36.3884C185.067 32.7962 179.441 31.0065 171.251 31.0191H162.708L162.727 77.9173Z" fill="#3331B1"/>
          <path d="M259.281 161.402V17.1444H319.6V35.6755H285.529V77.0903H312.471V95.6405H285.529V143.232H320.037V161.402H259.281Z" fill="#3331B1"/>
          <path d="M347.851 160.034L376.587 18.5225H403.443L432.093 160.034H408.132L402.436 127.172H377.954L372.153 160.034H347.851ZM380.678 110.352H399.588L390.095 52.2303L380.678 110.352Z" fill="#3331B1"/>
          <path d="M463.591 160.034V37.5572H439.858V18.5509H512.272V37.5572H489.336V160.034H463.591Z" fill="#3331B1"/>
          <path d="M582.397 163.113C567.556 163.113 556.921 159.081 550.491 151.016C544.061 142.95 540.846 131.144 540.846 115.597V62.3795C540.734 55.6625 541.464 48.9579 543.02 42.4229C544.309 37.1163 546.693 32.138 550.016 27.807C553.379 23.5809 557.849 20.3732 562.927 18.5414C568.312 16.4761 574.802 15.4466 582.397 15.4529C597.168 15.4529 607.785 19.4252 614.246 27.3699C620.708 35.3145 623.939 46.9844 623.939 62.3795V115.597C624.041 122.316 623.298 129.021 621.727 135.554C620.407 140.94 618.011 146.002 614.683 150.436C611.335 154.728 606.871 158.016 601.782 159.939C596.39 162.055 589.928 163.113 582.397 163.113ZM575.828 142.482C577.922 143.287 580.154 143.671 582.397 143.613C584.621 143.674 586.835 143.289 588.909 142.482C590.553 141.839 592.027 140.828 593.219 139.526C594.383 138.137 595.24 136.518 595.735 134.775C596.331 132.861 596.738 130.894 596.95 128.902C597.172 126.558 597.274 124.204 597.254 121.85V56.2975C597.291 53.387 597.117 50.4776 596.732 47.5926C596.399 45.3102 595.71 43.0943 594.69 41.0259C593.73 39.0224 592.125 37.3995 590.134 36.4169C587.707 35.3163 585.059 34.7926 582.397 34.8869C579.719 34.7952 577.057 35.3186 574.613 36.4169C572.607 37.3822 570.994 39.0099 570.046 41.0259C569.063 43.1048 568.391 45.3175 568.053 47.5926C567.667 50.4776 567.493 53.387 567.531 56.2975V121.812C567.511 124.166 567.612 126.52 567.834 128.864C568.044 130.872 568.451 132.856 569.05 134.784C569.545 136.52 570.388 138.136 571.527 139.536C572.703 140.858 574.178 141.881 575.828 142.52V142.482Z" fill="#3331B1"/>
          <path d="M661.085 167.076V11.4617H696.723C703.807 11.3515 710.882 12.0303 717.816 13.4858C723.332 14.6715 728.549 16.9706 733.148 20.2426C737.565 23.5011 740.95 27.9653 742.897 33.1003C745.049 38.5171 746.128 45.1345 746.134 52.9524C746.134 62.7153 744.682 70.6917 741.777 76.8814C738.881 83.0647 733.727 87.9011 727.376 90.3949L748.935 167.076H720.531L701.963 96.0303H689.403V167.076H661.085ZM689.403 77.9173H696.248C704.621 77.9173 710.573 76.1338 714.104 72.567C717.636 69.0001 719.402 62.8991 719.402 54.2639C719.402 45.9391 717.87 39.9806 714.807 36.3884C711.744 32.7962 706.118 31.0065 697.928 31.0191H689.384L689.403 77.9173Z" fill="#3331B1"/>
          <path d="M834.373 178.641C803.672 178.641 787.534 160.886 785.958 125.376L813.611 119.579C814.314 142.672 820.905 154.218 833.385 154.218C838.214 154.218 841.806 152.742 844.16 149.79C846.514 146.837 847.688 142.875 847.682 137.901C847.735 133.332 846.839 128.802 845.052 124.597C843.569 120.991 841.511 117.65 838.958 114.704C835.832 111.365 832.534 108.193 829.075 105.201L806.15 85.3012C799.422 79.4092 794.41 73.286 791.112 66.9316C787.815 60.5771 786.167 52.6293 786.167 43.0882C786.167 29.5493 790.144 19.023 798.099 11.5092C806.055 3.99532 816.975 0.168731 830.86 0.0293515C836.595 -0.126952 842.316 0.67601 847.786 2.40514C852.234 3.80094 856.364 6.06151 859.938 9.05735C863.238 11.9713 865.951 15.4906 867.931 19.4253C870.035 23.513 871.629 27.8441 872.677 32.3211C873.791 37.2211 874.528 42.1996 874.88 47.2125L847.748 52.5818C847.615 49.0783 847.388 46.0278 847.065 43.4303C846.738 40.7583 846.195 38.1173 845.442 35.5331C844.829 33.2134 843.814 31.0196 842.442 29.052C841.145 27.3479 839.445 25.9927 837.496 25.1082C835.239 24.0905 832.752 23.6904 830.29 23.9488C825.601 24.2339 822.009 25.7956 819.516 28.6338C817.022 31.4721 815.779 35.2734 815.785 40.0376C815.785 45.6572 816.889 50.2725 819.098 53.8837C821.963 58.248 825.452 62.1671 829.455 65.5156L852.172 85.3012C855.656 88.443 858.956 91.7842 862.054 95.308C865.03 98.7672 867.719 102.463 870.095 106.36C872.707 110.607 874.675 115.219 875.933 120.045C877.287 125.196 877.961 130.503 877.936 135.829C877.936 148.538 873.921 158.856 865.89 166.781C857.858 174.707 847.353 178.66 834.373 178.641Z" fill="#3331B1"/>
          <path d="M892.109 18.2842V3.70635H886.907V0.741368H900.824V3.70635H895.631V18.2842H892.109Z" fill="#3331B1"/>
          <path d="M903.064 18.2842V0.741368H908.361L911.542 12.7153L914.693 0.741368H920V18.2842H916.706V4.4761L913.231 18.2842H909.823L906.358 4.4761V18.2842H903.064Z" fill="#3331B1"/>
        </svg>
        <span style="margin-left: 60%">${contractSKU}</span>
        <span class=\'pdfcrowd-page-number\'>${pageNumber}</span>
    `
    activePageNumber === pageNumber && container.appendChild(footer)
  })

  return container
}

const DocumentPreviewer = ({html, originalHtml, activeFieldId, ...props}) => {
  const [numPages, setNumPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const contractSKU = useSelector(store => store.templatesAdmin.sku)
  const content = html.replace(/<style\b[^<]*(?:(?!<\/style)<[^<]*)*<\/style>/g, '')
  const originalContent = originalHtml.replace(/<style\b[^<]*(?:(?!<\/style)<[^<]*)*<\/style>/g, '')
  const chunks = getNodeChunks(content)
  const originalChunks = getNodeChunks(originalContent)
  const pagesNodeElement = appendChunksToPages(chunks, currentPage, contractSKU)

  useEffect(() => {
    setNumPages(chunks.length)
  }, [html])

  useEffect(() => {
    if (!activeFieldId) return

    let activePage
    const id = activeFieldId.toString()

    originalChunks.forEach((page, i) => {
      !activePage && page.forEach((node) => {
        if (node.innerHTML.includes(id)) {
          return activePage = i + 1
        }
      })
    })

    if (activePage && activePage !== currentPage) {
      setLoading(true)

      let timer = setTimeout(() => {
        setLoading(false)
        setCurrentPage(activePage)
      }, 700)

      return () => {
        clearTimeout(timer)
      }
    }

    let timerOut = setTimeout(() => {
      setLoading(false)
    }, 700)

    return () => {
      clearTimeout(timerOut)
    }
  }, [activeFieldId])

  const ComponentFromDOMNode = ({ element }) => {
    const reactElement = React.createElement(
      element.nodeName.toLowerCase(),
      { dangerouslySetInnerHTML: { __html: element.innerHTML }, style: {transform: `scale(0.8) translate(-100px, -180px)`, minHeight: `950px`} },
      null
    )

    return reactElement
  }

  const onPreviousPage = () => {
    setCurrentPage((prev) => prev === 1 ? prev : prev - 1)
  }

  const onNextPage = () => {
    setCurrentPage((prev) => prev === numPages ? prev : prev + 1)
  }

  return <div className={styles.wrapper}>
    <Spin spinning={loading} style={{maxWidth: `595px`}}>
      {
        React.createElement(
          pagesNodeElement.nodeName.toLowerCase(),
          { dangerouslySetInnerHTML: { __html: pagesNodeElement.innerHTML }, className: styles.pageWrapper, style: {} },
          null
        )
      }
      <DocumentNavigator
        currentPage={currentPage}
        numPages={numPages}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    </Spin>
  </div>
}

export default DocumentPreviewer;
