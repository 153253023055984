import { Record } from 'immutable'
import { START, SUCCESS, FAIL } from '../../../constants/actions'
import * as types from './types'
import * as temlateTypes from '../templates/types'



const UserRecord = Record({
  userEmail: null,
  userName: null,
  role: null,
  isAdmin: false,
  loading: false,
  loaded: false,
  error: null,
  message: null,
})

const user = (user = new UserRecord(), action) => {
  const {type, response, error, payload} = action

  switch(type) {
    case types.GET_USER_DATA + START:
      return user
        .set('loading', true)

    case types.GET_USER_DATA + SUCCESS:
      return user
        .set('userName', response.data[0].name)
        .set('userEmail', response.data[0].email)
        .set('role', response.data[0].role)
        .set('isAdmin', !!response.data[0].is_admin)
        .set('loaded', true)
        .set('loading', false)
        .set('error', null)
        .set('message', null)

    case types.GET_USER_DATA + FAIL:
      return user
        .set('userName', null)
        .set('userEmail', null)
        .set('role', null)
        .set('loading', false)
        .set('loaded', true)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case temlateTypes.HANDLE_TABS_CHANGE:
      return user
        .set('error', null)
        .set('message', null)

    default:
      return user
  }
}

export default user;
