import React, { Component } from 'react'
import uuid from 'react-uuid'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Spin, Form, Input, InputNumber, Modal, Select, Menu, Button, Checkbox, Tooltip } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import FieldBlot from './EditorFieldBlot'
import TableBlot from './EditorTableBlot'
import DividerBlot from './EditorDividerBlot'
import EditorStickyToolbar from './EditorStickyToolbar'
import { convertHtmlToPdf } from '../../store/reducers/templatePdf/actions'
import {
  postContractField,
  getContractField,
  updateContractContentAdmin,
  updateContractField,
  deleteContractField,
  postHellosignField,
  deleteHellosignField,
  resetErrors,
  getFieldTypes,
} from '../../store/reducers/templatesAdmin/actions'
import {
  idContractSelector,
  contentContractSelector,
  stepsContractSelector,
  partiesContractSelector,
  stepLoadingContractSelector,
  stepLoadedContractSelector,
  getLoadingContractSelector,
  getLoadedContractSelector,
  fieldContractSelector,
  validationTypesContractSelector,
} from '../../store/reducers/templatesAdmin/selectors'

import { FORM_VALIDATE_MESSAGES, NOTHING_TO_SHOW } from '../../constants/staticErrors'
import { FIELDS_COLORS, PDF_STYLE } from '../../constants/variables'
const Parchment = Quill.import('parchment')



class Editor extends Component {
  constructor(props) {
    super(props)

    Quill.register({'formats/field': FieldBlot}, true)
    Quill.register({'formats/divider': DividerBlot}, true)
    Quill.register({'formats/table': TableBlot}, true)

    this.state = {
      editorHtml: props.contractContent,
      editorDelta: null,
      editorRange: {},
      editorActiveField: {},
      isUpdateContent: false,
      isModalVisible: false,
      stepItems: props.contractSteps.map(item => item.toJS()),
      isDeliverable: false,
      menuFields: [],
      isChecked: true,
      menuFieldType: [{
        'label': 'Text',
        'value': 1
      }, {
        'label': 'Text area',
        'value': 2
      }, {
        'label': 'Deliverable list',
        'value': 3
      }, {
        'label': 'Date',
        'value': 4
      }],
      menuFieldValidation: [{
        'label': 'All symbols',
        'value': 1
      }, {
        'label': 'Text only',
        'value': 2
      }, {
        'label': 'Numbers only',
        'value': 3
      }, {
        'label': 'Email',
        'value': 4
      }]
    }

    this.quillRef = null
    this.reactQuillRef = null
    this.formRef = React.createRef()
    this.menuRef = React.createRef()
    this.tooltipRef = React.createRef()

    this.config = {
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{header: [1, 2, 3, 4, 5, 6, false]}], // {font: []},
            ['bold', 'italic', 'underline', 'strike', 'blockquote', {script: 'sub'}, {script: 'super'}], // {color: []}, {background: []}
            [
              {align: []},
              {list: 'ordered'},
              {list: 'bullet'},
            ],
            ['link', 'clean', 'divider'],
          ],
          handlers: {
            // 'button-link': function (value) {
            //   const href = prompt('Enter the URL')
            //
            //   console.log(value)
            //   console.log(this.quillRef)
            //   console.log(this.reactQuillRef)
            //
            //   // if (value) {
            //   //   this.quillRef.format('button-link', href)
            //   // } else {
            //   //   this.quillRef.format('button-link', false)
            //   // }
            // }
          }
        },
        clipboard: {
          matchVisual: false,
          matchers: [
            ['TABLE', this.handleTableInsert]
          ]
        },
        history: {
          delay: 2000,
          maxStack: 100,
          userOnly: true
        }
      },
      formats: [
        'header', // 'font',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'script', // 'color', 'background',
        'list', 'bullet', 'indent', 'align',
        'link', 'image', 'field', 'divider', 'table',
      ],
    }
    // FOR Nested Blot read => https://github.com/quilljs/quill/issues/1121#issuecomment-529156962
  }

  componentDidMount() {
    let menuArray = []
    const propsSteps = this.props.contractSteps.map(item => item.toJS()).filter(step => step.is_parties_data !== 1)
    const propsFieldsArray = propsSteps.map(o1 => Object.entries(o1.fields).map(([key, item], index) => menuArray.push(item)))

    this.setState({
      menuFields: menuArray
    })

    this.attachQuillRefs()
    this.props.getFieldTypes()
    window.scrollTo(0, 0)

    if (this.quillRef) {
      this.quillRef.focus()

      // Preventing page jump in Chrome
      new EditorStickyToolbar()
      this.quillRef.getModule('toolbar').container.addEventListener('mousedown', this.fixToolbarScrollIssue)
    }

    ReactDOM.findDOMNode(this).addEventListener('field-removed', this.handleFieldRemove)
    ReactDOM.findDOMNode(this).addEventListener('field-clicked', this.handleFieldClick)
    ReactDOM.findDOMNode(this).addEventListener('field-hover-in', this.showFieldTooltip)
    ReactDOM.findDOMNode(this).addEventListener('field-hover-out', this.hideFieldTooltip)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      isDataUpdate,
      handleDataUpdate,
      contractId,
      updateContent,
      handleSwitchStep,
      contractFieldData,
      contractValidationTypes,
      selectedField,
      contractSteps,
      getField
    } = this.props
    const {
      editorActiveField,
      editorHtml,
      editorRange,
      isUpdateContent,
      stepItems
    } = this.state

    if (prevState.editorRange !== editorRange) {
      if (prevState.editorRange.length === 0 || prevState.editorRange.length === 1) {
        // Reset editorActiveField on menu show
        if (editorRange.length > 1) {
          this.setState({
            editorActiveField: {}
          })
        }
      }
    }

    if (contractValidationTypes) {
      if (prevProps.contractValidationTypes.length !== contractValidationTypes.length && contractValidationTypes.length !== 0) {
        this.setState({
          menuFieldValidation: contractValidationTypes
        })
      }
    }

    if (prevState.editorHtml !== editorHtml) {
      // API Update content when insert /delete field
      if (isUpdateContent) {
        const {postHellosignField, contractId} = this.props
        const embedObject = this.state.editorDelta['ops'][1] && this.state.editorDelta['ops'][1]['insert'] ? this.state.editorDelta['ops'][1]['insert']['field'] : null

        // Call API when inserted HelloSign fields
        // if (embedObject && typeof embedObject.id === 'string' && editorActiveField.payload) {
        //   if (embedObject.class.includes('quill-field-disabled')) {
        //     // postHellosignField({
        //     //   'uuid': embedObject.id,
        //     //   'value': `<img class="quill-field quill-field-disabled" id="${embedObject.id}" src="${embedObject.src}" alt="${embedObject.alt}" draggable="true">`,
        //     //   'field_type': editorActiveField.payload.field_type,
        //     //   'party_number': editorActiveField.payload.party_number
        //     // }, contractId)
        //   }
        // }

        this.setState({
          editorActiveField: {},
          isUpdateContent: false
        })
        console.log( 'update content 000 ...' )
        updateContent({content: PDF_STYLE + editorHtml}, contractId)
      }
    }

    if (prevProps.isDataUpdate !== isDataUpdate && isDataUpdate) {
      const rgx = new RegExp(`<img[^>]*id="([^"]{8,})"[^>]*>`, 'g')
      const imgTags = editorHtml && editorHtml.match(rgx)
      const helloSignData = imgTags && imgTags.length > 0 ?
        imgTags.map((item, i) => {
          if ( !/(type="(.*?)(\"))/g.exec(item) || !/(party="(.*?)(\"))/g.exec(item) ) return null

          const id = /(id="(.*?)(\"))/g.exec(item)[2]
          const type = /(type="(.*?)(\"))/g.exec(item)[2]
          const party = /(party="(.*?)(\"))/g.exec(item)[2]

          return {
            'uuid': id,
            'value': item,
            'field_type': type,
            'party_number': party,
          }
        }) :
        []

      // If Editor has content
      if ( editorHtml && editorHtml.replace(/<style\b[^<]*(?:(?!<\/style)<[^<]*)*<\/style>/g, '').length > 11 ) {
        updateContent({
          content: PDF_STYLE + editorHtml,
          hs_fields: helloSignData,
        }, contractId)

        handleSwitchStep(true)
      }

      handleDataUpdate(false)
    }

    if (
      prevProps.contractSteps.length !== this.props.contractSteps.length ||
      prevProps.contractStepLoading !== this.props.contractStepLoading && !this.props.contractStepLoading
    ) {
      let menuArray = []
      const prevPropsSteps = prevProps.contractSteps.map(item => item.toJS()).filter(step => step.is_parties_data !== 1)
      const propsSteps = this.props.contractSteps.map(item => item.toJS()).filter(step => step.is_parties_data !== 1)
      const propsFieldsArray = propsSteps.map(o1 => Object.entries(o1.fields).map(([key, item], index) => menuArray.push(item)))
      const prevPropsStepsArray = Object.entries(prevPropsSteps[prevPropsSteps.length - 1].fields).map(([key, item], index) => item)
      const propsStepsArray = Object.entries(propsSteps[propsSteps.length - 1].fields).map(([key, item], index) => item)
      const diff = propsStepsArray.filter(({id: id1}) => !prevPropsStepsArray.some(({id: id2}) => id2 === id1))

      this.setState({
        menuFields: menuArray
      })

      if (
        Object.entries(prevPropsSteps[prevPropsSteps.length - 1].fields).length !== Object.entries(propsSteps[propsSteps.length - 1].fields).length &&
        diff.length > 0
      ) {
        this.setState(prevState => ({
          stepItems: propsSteps,
          editorActiveField: {
            ...prevState.editorActiveField,
            payload: diff[0]
          }
        }), () => {
          this.handleFieldUpdateInContent()
        })
      }
    }

    // if (prevProps.contractFieldData !== contractFieldData && contractFieldData) {
    //   const propsFieldData = contractFieldData.toJS()
    //
    //   this.setState(prevState => ({
    //     editorActiveField: {
    //       ...prevState.editorActiveField,
    //       ...propsFieldData
    //     }
    //   }))
    // }

    if (prevProps.selectedField !== selectedField && selectedField) {
      const activeStep = contractSteps.map(item => item.toJS()).find(item => item.fields[selectedField.id] && item.id)

      this.setState(prevState => ({
        editorRange: {
          ...prevState.editorRange,
          length: 1,
        },
        editorActiveField: {
          ...prevState.editorActiveField,
          ...selectedField
        }
      }), () => {
        if (activeStep) {
          getField(contractId, activeStep.id, selectedField.id)
        }

        this.showModal()
      })
    }

    if (
      prevState.editorActiveField.id !== editorActiveField.id &&
      Object.keys(editorActiveField).length !== 0
    ) {
      const isActiveField = editorActiveField && Object.keys(editorActiveField).length !== 0

      // Update modal Form initialValues
      this.formRef.current && this.formRef.current.setFieldsValue({
        title: editorActiveField.title,
        tooltip: editorActiveField.tooltip,
        validation_error_text: editorActiveField.validation_error_text,
        maxlength: editorActiveField.maxlength ? editorActiveField.maxlength : null,
        fieldlength: editorActiveField.fieldlength ? editorActiveField.fieldlength : null,
        input_type: editorActiveField.field_type,
        input_validation: editorActiveField.validation_type ? editorActiveField.validation_type : null,
      })

      if (isActiveField) {
        this.setState({
          isChecked: editorActiveField.is_required === 1,
        })
      }
    }
  }

  componentWillUnmount() {
    this.quillRef.getModule('toolbar').container.removeEventListener('mousedown', this.fixToolbarScrollIssue)
    ReactDOM.findDOMNode(this).removeEventListener('field-removed', this.handleFieldRemove)
    ReactDOM.findDOMNode(this).removeEventListener('field-clicked', this.handleFieldClick)
    ReactDOM.findDOMNode(this).removeEventListener('field-hover-in', this.showFieldTooltip)
    ReactDOM.findDOMNode(this).removeEventListener('field-hover-out', this.hideFieldTooltip)
  }

  render() {
    const {SubMenu, Item} = Menu
    const {TextArea} = Input
    const {contractGetLoading, contractParties} = this.props
    const {isModalVisible, editorActiveField, menuFields, isChecked} = this.state
    const isActiveField = editorActiveField && Object.keys(editorActiveField).length !== 0

    return (
      <>
        <ReactQuill
          ref={(element) => {
            this.reactQuillRef = element
          }}
          theme={this.config.theme}
          modules={this.config.modules}
          formats={this.config.formats}
          onChange={this.onEditorChange}
          value={this.state.editorHtml}
        />
        <div
          ref={node => (this.tooltipRef = ReactDOM.findDOMNode(node))}
          className='ant-tooltip editor-tooltip ant-tooltip-placement-top'
          style={{position: 'absolute', top: 0, left: 0, visibility: 'hidden'}}
        >
          <div className="ant-tooltip-content">
            <div className="ant-tooltip-arrow">
              <span className="ant-tooltip-arrow-content" />
            </div>
            <div className="ant-tooltip-inner" role="tooltip">
              {editorActiveField && editorActiveField.title ? editorActiveField.title : NOTHING_TO_SHOW}
            </div>
          </div>
        </div>
        <Menu
          mode='vertical'
          subMenuOpenDelay={0.2}
          ref={node => (this.menuRef = ReactDOM.findDOMNode(node))}
        >
          <Item key='field-action' onClick={this.showModal}>
            {isActiveField ? 'Edit Field' : 'New Field'}
          </Item>
          {!isActiveField &&
            <SubMenu key='parties' title='Parties'>
              {contractParties.map((item, i) => {
                const partyNumber = i + 1
                const partyTitle = item.filter(party => party.is_predefined === 3)[0].title
                const signatureData = {
                  'type': 'hello-sign',
                  'id': uuid(),
                  'title': 'e-Signature',
                  'is_deletable': 0,
                  'field_type': 1,
                  'party_number': partyNumber - 1,
                }
                const signatorData = {
                  'type': 'hello-sign',
                  'id': uuid(),
                  'title': 'Initials',
                  'is_deletable': 0,
                  'field_type': 2,
                  'party_number': partyNumber - 1,
                }
                const signDateData = {
                  'type': 'hello-sign',
                  'id': uuid(),
                  'title': 'Date',
                  'is_deletable': 0,
                  'field_type': 3,
                  'party_number': partyNumber - 1,
                }

                return <SubMenu key={i} title={partyTitle}>
                  {item.map(party => party.is_predefined !== 3 && <Item key={party.id} onClick={() => this.handlePartyFieldCreate(party)}>{party.title}</Item>)}
                  <Item key={signatorData.id} onClick={() => this.handlePartyFieldCreate(signatorData)}>
                    Initials
                    <svg width="78" height="74" viewBox="0 0 78 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M22.9375 12.1523L37.7227 33.9102L53.8281 12.1523H22.9375ZM37.3242 54.9102L0 0.00390625H77.9219L37.3242 54.9102Z"
                        fill="#05B5E8"/>
                      <path d="M11.9688 61.3633H66.3945V73.5117H11.9688V61.3633Z" fill="#05B5E8"/>
                    </svg>
                  </Item>
                  <Item key={signatureData.id} onClick={() => this.handlePartyFieldCreate(signatureData)}>
                    e-Signature
                    <svg width="78" height="74" viewBox="0 0 78 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M22.9375 12.1523L37.7227 33.9102L53.8281 12.1523H22.9375ZM37.3242 54.9102L0 0.00390625H77.9219L37.3242 54.9102Z"
                        fill="#05B5E8"/>
                      <path d="M11.9688 61.3633H66.3945V73.5117H11.9688V61.3633Z" fill="#05B5E8"/>
                    </svg>
                  </Item>
                  <Item key={signDateData.id} onClick={() => this.handlePartyFieldCreate(signDateData)}>
                    Date
                    <svg width="78" height="74" viewBox="0 0 78 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M22.9375 12.1523L37.7227 33.9102L53.8281 12.1523H22.9375ZM37.3242 54.9102L0 0.00390625H77.9219L37.3242 54.9102Z"
                        fill="#05B5E8"/>
                      <path d="M11.9688 61.3633H66.3945V73.5117H11.9688V61.3633Z" fill="#05B5E8"/>
                    </svg>
                  </Item>
                </SubMenu>
              })}
            </SubMenu>
          }
          {!isActiveField && menuFields.length > 0 &&
            <SubMenu key='fields' title='Created fields'>
              {menuFields.map((field) => {
                return <Item key={field.id} onClick={() => this.handlePartyFieldCreate(field)}>{field.title}</Item>
              })}
            </SubMenu>
          }
        </Menu>
        <Modal
          title={isActiveField ? 'Edit Field' : 'New Field'}
          visible={isModalVisible}
          onCancel={this.hideModal}
          footer={isActiveField ? [
              <Button key='back' onClick={this.hideModal}>
                Cancel
              </Button>,
              <Button key='submit' type='primary' onClick={() => {
                this.formRef.current
                  .validateFields()
                  .then((values) => {
                    this.formRef.current.resetFields()
                    this.handleFieldCreate(values)
                  })
                  .catch((info) => {
                    console.log('Validate Failed:', info)
                  })
              }}>
                Save
              </Button>,
            ] :
            [
              <Button key='back' onClick={this.hideModal}>
                Cancel
              </Button>,
              <Button key='submit' type='primary' onClick={() => {
                this.formRef.current
                  .validateFields()
                  .then((values) => {
                    this.formRef.current.resetFields()
                    this.handleFieldCreate(values)
                  })
                  .catch((info) => {
                    console.log('Validate Failed:', info)
                  })
              }}>
                Save
              </Button>,
            ]}
        >
          <Spin spinning={contractGetLoading} size='small'>
            <Form
              ref={this.formRef}
              layout='vertical'
              name='step-content'
              validateMessages={FORM_VALIDATE_MESSAGES}
              initialValues={{
                title: editorActiveField.title,
                tooltip: editorActiveField.tooltip || null,
                validation_error_text: editorActiveField.validation_error_text || null,
                maxlength: editorActiveField.maxlength || null,
                fieldlength: editorActiveField.fieldlength || null,
                input_type: editorActiveField.field_type || null,
                input_validation: editorActiveField.validation_type || null
              }}
            >
              <Form.Item
                name='title'
                label='Field name'
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  ref={(input) => {
                    input && input.focus()
                  }}
                  placeholder='Field name'
                />
              </Form.Item>
              <Form.Item
                name='tooltip'
                label='Tooltip (optional)'
              >
                <TextArea
                  rows={3}
                  maxLength={500}
                  placeholder='Description of the field'
                />
              </Form.Item>
              <Form.Item
                name='validation_error_text'
                label='Validation error text'
                rules={[
                  {
                    // required: true,
                    // whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder='Enter validation error text'
                />
              </Form.Item>
              <Form.Item
                name='maxlength'
                label='Max length (optional)'
              >
                <Input
                  disabled={this.state.isDeliverable}
                  placeholder='Enter number of symbols'
                />
              </Form.Item>
              <Form.Item
                name='fieldlength'
                label='Preview Placeholder Length (optional)'
                rules={[
                  {
                    whitespace: true,
                    type: 'number'
                  },
                ]}
              >
                <InputNumber
                  placeholder='Enter Preview placeholder length'
                />
              </Form.Item>
              <Form.Item
                name='input_type'
                label='Input type'
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={this.handleInputTypeChange}
                  options={this.state.menuFieldType}
                  placeholder='Select Input type'
                  suffixIcon={<CaretDownOutlined/>}
                />
              </Form.Item>
              <Form.Item
                name='input_validation'
                label='Validation'
              >
                <Select
                  disabled={this.state.isDeliverable}
                  options={this.state.menuFieldValidation}
                  placeholder='Choose type of validation'
                  suffixIcon={<CaretDownOutlined/>}
                />
              </Form.Item>
              <Form.Item
                name='is_required'
              >
                <Checkbox checked={isChecked} onChange={this.onCheckboxChange} defaultChecked>Required field</Checkbox>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </>
    )
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== 'function') return

    if (this.quillRef != null) return

    const quillRef = this.reactQuillRef.getEditor()

    if (quillRef != null) this.quillRef = quillRef

    this.quillRef.on('selection-change', this.onSelectionChange)
  }

  onSelectionChange = range => {
    if (range) {
      this.setState({
        editorRange: range
      }, () => {
        if (range.length === 0) {
          this.hideEditorToolbar()
        } else {
          this.showEditorToolbar()
        }
      })
    } else {
      this.hideEditorToolbar()
    }
  }

  onEditorChange = (html, delta, source, editor) => {
    let htmlOps = html
    const regex = /\s*?style="(.*?)"/g
    // const isEmbedTable = delta['ops'].filter(item => item['insert'] && item['insert']['table'])

    htmlOps = html.replaceAll(regex, '')

    this.setState({
      editorHtml: htmlOps,
      editorDelta: delta,
    })
  }

  showEditorToolbar = (target) => {
    const {editorRange, editorActiveField} = this.state
    const menuNode = this.menuRef
    const {top, left} = this.quillRef.getBounds(editorRange)
    const shift = 68

    if (target) {
      const targetBounds = target.getBoundingClientRect()

      menuNode.style.visibility = 'visible'
      menuNode.style.position = 'fixed'
      menuNode.style.top = `${targetBounds.top + targetBounds.height}px`
      menuNode.style.left = `${targetBounds.left}px`
      this.setState(prevState => ({
        editorRange: {
          ...prevState.editorRange,
          length: 1,
        }
      }))
    } else {
      menuNode.style.visibility = 'visible'
      menuNode.style.position = 'absolute'
      menuNode.style.top = `${top + shift}px`
      menuNode.style.left = `${left}px`
    }

    if (editorRange.length !== 0 && Object.keys(editorActiveField).length === 0) {
      if (this.formRef.current) {
        this.formRef.current.setFieldsValue({
          title: null,
          tooltip: null,
          validation_error_text: null,
          maxlength: null,
          fieldlength: null,
          input_type: null,
          input_validation: null,
        })
        // this.formRef.current.resetFields()
      }

      this.setState({
        isChecked: true,
      })
    }
  }

  hideEditorToolbar = () => {
    const {resetErrors} = this.props
    const {editorRange} = this.state
    const menuNode = this.menuRef
    const isToolbarFocused = menuNode && (menuNode.classList.contains('ant-menu-item-active') || menuNode.classList.contains('ant-menu-submenu-active'))

    if (!isToolbarFocused) {
      menuNode.style.visibility = 'hidden'
    }

    if (editorRange.length === 0) {
      // this.setState({
      //   editorActiveField: {}
      // }, () => {
      //   // resetErrors()
      // })
    }
  }

  handlePartyFieldCreate = (party) => {
    this.setState({
      editorActiveField: {
        payload: party
      }
    }, () => {
      this.handleFieldUpdateInContent()
    })
  }

  handleFieldCreate = (values) => {
    const {contractId, contractSteps, postField, updateField} = this.props
    const {editorActiveField, isChecked} = this.state
    const isFieldUpdate = editorActiveField && Object.keys(editorActiveField).length !== 0
    const fieldValues = {
      title: values.title,
      is_required: isChecked ? 1 : 0,
      maxlength: values.maxlength,
      fieldlength: values.fieldlength ? values.fieldlength : 0,
      tooltip: values.tooltip,
      val: 'FIELD',
      validation_error_text: values.validation_error_text,
      field_type: values.input_type,
      validation_type: values.input_validation || 0,
    }
    const propsStepsArray = contractSteps.map(item => item.toJS()).filter(step => step.is_parties_data !== 1)
    const updatedStepId = propsStepsArray.filter(x => x.fields.hasOwnProperty(editorActiveField.id))

    console.log('UPDATE or POST')
    console.log(values)
    console.log(editorActiveField)

    if (isFieldUpdate) {
      updateField(fieldValues, contractId, updatedStepId[0].id, editorActiveField.id)
      this.handleFieldUpdateInContent(values)
    } else {
      postField(fieldValues, contractId, propsStepsArray[propsStepsArray.length - 1].id)
    }

    this.hideModal()
  }

  handleFieldUpdateInContent = (values) => {
    const {editorRange, editorActiveField} = this.state
    const isActiveField = editorActiveField && Object.keys(editorActiveField).length !== 0
    const isFieldUpdate = editorRange.length === 1
    const isPartyField = typeof editorActiveField.party_number === 'number' || editorActiveField.payload && typeof editorActiveField.payload.party_number === 'number'
    let fieldFill = '#A087F1'
    let fieldStroke = '#602BF5'

    if (!isActiveField) return null

    if (editorActiveField.payload) {
      fieldFill = editorActiveField.payload.party_number || editorActiveField.payload.party_number === 0 ? FIELDS_COLORS[editorActiveField.payload.party_number].backgroundColor : '#A087F1'
      fieldStroke = editorActiveField.payload.party_number || editorActiveField.payload.party_number === 0 ? FIELDS_COLORS[editorActiveField.payload.party_number].borderColor : '#602BF5'
    }

    console.log('Update In Content ...')
    console.log(isFieldUpdate)
    console.log(values)
    console.log(editorActiveField)

    const Delta = Quill.import('delta')
    const position = editorRange ? editorRange.index : 0
    const replacedText = this.quillRef.getText(editorRange.index, editorRange.length).trim() // .replaceAll(/\s/g, '&nbsp;')
    // const isHelloSignField = editorActiveField.payload && editorActiveField.payload.type && editorActiveField.payload.type === 'hello-sign'
    // const fieldTitle = values ? values.title : editorActiveField.payload ? editorActiveField.payload.title : editorActiveField.title
    const fieldLength = values ?
      values.fieldlength :
      editorActiveField.payload ?
        editorActiveField.payload.fieldlength :
        editorActiveField.fieldlength ?
          editorActiveField.fieldlength :
          null
    const fieldIcon = `<svg width="${fieldLength ? fieldLength * 4.4 : 110}" height="20" viewBox="0 0 ${fieldLength ? fieldLength * 4.4 : 110} 20" xmlns="http://www.w3.org/2000/svg">
      <rect
          x="0.5"
          y="0.5"
          width="${fieldLength ? (fieldLength * 4.4) - 1 : 109}"
          height="19"
          rx="2.5"
          fill-opacity="0.5"
          fill="${fieldFill}"
          stroke="${fieldStroke}"
      />
    </svg>`
    const insertedIconBase64 = window.btoa(fieldIcon)

    if (isFieldUpdate) {
      let id = editorActiveField.id
      let node = document.getElementById(id)
      let blot = Parchment.find(node)
      let index = blot.offset(this.quillRef.scroll)
      const replaceDelta = new Delta()
        .retain(index)
        .insert('')
        .delete(1)
      const replaceData = {
        id: editorActiveField.id ? editorActiveField.id : editorActiveField.payload.id,
        src: `data:image/svg+xml;base64,${insertedIconBase64}`,
        alt: editorActiveField.alt ? editorActiveField.alt : replacedText,
        type: isPartyField ? editorActiveField.payload.field_type : null,
        party: isPartyField ? editorActiveField.payload.party_number : null,
        class: isPartyField ?
          'quill-field quill-field-disabled' :
          'quill-field'
      }

      // console.log(id)
      // console.log(node)
      // console.log(blot)
      // console.log(index)
      // console.log(position)

      this.quillRef.updateContents(replaceDelta)
      this.quillRef.insertEmbed(index, 'field', replaceData)
    } else {
      const delta = new Delta()
        .retain(editorRange.index)
        .delete(editorRange.length)
      const fieldData = {
        id: editorActiveField.payload.id,
        src: `data:image/svg+xml;base64,${insertedIconBase64}`,
        alt: replacedText,
        type: isPartyField ? editorActiveField.payload.field_type : null,
        party: isPartyField ? editorActiveField.payload.party_number : null,
        class: isPartyField ?
          'quill-field quill-field-disabled' :
          'quill-field',
      }

      this.quillRef.updateContents(delta)
      this.quillRef.insertEmbed(position, 'field', fieldData)
    }

    this.setState({isUpdateContent: true})
  }

  handleFieldRemove = (data) => {
    console.log(data)

    if (!data) return null

    const {deleteField, contractSteps, contractId} = this.props
    const {editorRange, editorActiveField} = this.state
    const isActiveField = editorActiveField && Object.keys(editorActiveField).length !== 0
    const isFieldUpdate = editorRange.length === 1
    const fieldData = data.detail.data
    const fieldNode = data.target
    const position = editorRange ? editorRange.index : 0
    const stepId = contractSteps.map(item => item.toJS()).filter(item => item.fields[fieldData.id] && item.id)

    if (!isFieldUpdate) this.quillRef.insertText(position, fieldData.alt)

    if (!fieldNode.classList.contains('quill-field-disabled') && stepId.length > 0) {
      setTimeout(() => {
        // Check & Delete after editor delta change...
        if (!this.state.editorHtml.includes(`id="${fieldData.id}"`)) {
          deleteField(contractId, stepId[0].id, fieldData.id)
        }
      }, 500)
    }

    if (fieldNode.id.length > 30) {
      // deleteHellosignField(contractId, fieldNode.id)
    }

    this.setState({
      isUpdateContent: true
    })

    this.hideModal()
  }

  handleFieldClick = (data) => {
    if (!data) return null

    const {getField, contractId, contractSteps} = this.props
    const fieldData = data.detail.data
    const fieldNode = data.target
    const activeStep = contractSteps.map(item => item.toJS()).find(item => item.fields[fieldData.id] && item.id)
    const image = Parchment.find(fieldNode)

    if (image.statics.blotName === 'field') {
      const indexSelectedImage = this.quillRef.getIndex(image)

      this.quillRef.setSelection(indexSelectedImage)
    }

    if (fieldData) {
      if (fieldNode.classList.contains('quill-field-disabled')) {
        this.hideEditorToolbar()
      } else {
        this.setState(prevState => ({
          editorActiveField: {
            ...prevState.editorActiveField,
            id: fieldData.id,
            alt: fieldData.alt
          }
        }), () => {
          if (activeStep) {
            const activeStepFields = Object.entries(activeStep.fields).map(([key, item], index) => item)
            const activeField = activeStepFields.filter(o => o.id === fieldData.id)

            activeField && this.setState(prevState => ({
              editorActiveField: {
                ...prevState.editorActiveField,
                ...activeField[0]
              }
            }))

            getField(contractId, activeStep.id, fieldData.id)
          }

          this.showEditorToolbar(data.target)
        })
      }
    } else {
      this.setState({
        editorActiveField: {},
      })
    }
  }

  showFieldTooltip = (event) => {
    if (!event) return null

    const {contractSteps} = this.props
    const fieldNode = event.target
    const activeFieldId = event.target.id
    const activeStep = contractSteps.map(item => item.toJS()).find(item => item.fields[activeFieldId] && item.id)
    const blot = Parchment.find(fieldNode)
    const blotBounds = fieldNode.getBoundingClientRect()
    const tooltipNode = this.tooltipRef

    if (activeFieldId) {
      if (activeStep) {
        const activeStepFields = Object.entries(activeStep.fields).map(([key, item], index) => item)
        const activeField = activeStepFields.filter(o => o.id === Number.parseInt(activeFieldId))

        activeField && this.setState({
          editorActiveField: activeField[0]
        })
      }

      // HS field
      if (activeFieldId.length > 30) {
        let fieldType = fieldNode.getAttribute('type')

        if (fieldType) fieldType = Number.parseInt(fieldType)

        this.setState(prevState => ({
          editorActiveField: {
            ...prevState.editorActiveField,
            title: `HS ${fieldType ? fieldType === 1 ? 'Initials' : fieldType === 2 ? 'e-Signature' : fieldType === 3 ? 'Date' : '' : ''}`
          }
        }))
      }
    }

    if (blot && blot.statics.blotName === 'field') {
      tooltipNode.style.position = 'fixed'
      tooltipNode.style.top = `${blotBounds.top - tooltipNode.offsetHeight}px`
      tooltipNode.style.left = `${blotBounds.left}px`
      tooltipNode.style.visibility = 'visible'
    }
  }

  hideFieldTooltip = (event) => {
    if (!event) return null

    const tooltipNode = this.tooltipRef

    tooltipNode.style.visibility = 'hidden'
  }

  handleInputTypeChange = (value) => {
    if (value === 3 || value === 4) {
      this.formRef.current && this.formRef.current.setFieldsValue({
        maxlength: null,
        input_validation: null,
      })
      this.setState({
        isDeliverable: true
      })
    } else {
      this.setState({
        isDeliverable: false
      })
    }
  }

  onCheckboxChange = (e) => {
    this.setState({
      isChecked: e.target.checked
    })
  }

  handleTableInsert = (node, delta) => {
    const Delta = Quill.import('delta')
    const tableTagStyles = node.getAttribute('style')

    this.fixToolbarScrollIssue()
    return new Delta([
      {
        insert: {
          table:
            `<style>#tableId {${tableTagStyles} margin: 0 auto !important; }</style>` + delta.ops[0].insert.table
        }
      }
    ])
  }

  showModal = () => {
    this.setState({
      isModalVisible: true,
    })
  }

  hideModal = () => {
    const {resetErrors} = this.props

    this.setState({
      isModalVisible: false,
      editorActiveField: {}
    }, () => {
      resetErrors()
      this.onSelectionChange()
    })
  }

  encodeSVG = (data) => {
    const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g

    data = data.replace(/"/g, `'`)
    data = data.replace(/>\s{1,}</g, `><`)
    data = data.replace(/\s{2,}/g, ` `)

    return data.replace(symbols, encodeURIComponent)
  }

  handleHtmlToPdf = () => {
    const { convertHtmlToPdf } = this.props

    convertHtmlToPdf({html: this.state.editorHtml})
  }

  fixToolbarScrollIssue = () => {
    const Y = window.scrollY

    let timer = setTimeout(() => {
      window.scrollTo(0, Y)
    }, 100)

    return () => {
      clearTimeout(timer)
    }
  }
}

export default connect(
  store => {
    return {
      contractId: idContractSelector(store),
      contractContent: contentContractSelector(store),
      contractSteps: stepsContractSelector(store),
      contractParties: partiesContractSelector(store),
      contractStepLoading: stepLoadingContractSelector(store),
      contractStepLoaded: stepLoadedContractSelector(store),
      contractGetLoading: getLoadingContractSelector(store),
      contractGetLoaded: getLoadedContractSelector(store),
      contractFieldData: fieldContractSelector(store),
      contractValidationTypes: validationTypesContractSelector(store),
    }
  },
  {
    updateContent: updateContractContentAdmin,
    postField: postContractField,
    getField: getContractField,
    updateField: updateContractField,
    deleteField: deleteContractField,
    resetErrors: resetErrors,
    getFieldTypes: getFieldTypes,
    postHellosignField: postHellosignField,
    deleteHellosignField: deleteHellosignField,
    convertHtmlToPdf: convertHtmlToPdf
  }
)(Editor);
