import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Spin } from 'antd'

import AdminStepsBuilder from '../AdminStepsBuilder'
import Editor from './Editor'
import styles from './style.module.scss'



const AdminEditor = (props) => {
  const history = useHistory()
  const [selectedField, setSelectedField] = useState(null)
  const contractId = useSelector(store => store.templatesAdmin.id)
  const contractStepLoading = useSelector(store => store.templatesAdmin.stepLoading)

  useEffect(() => {
    const { locationContractId } = props

    if (!locationContractId) {
      contractId && history.push(`/contract/${contractId}`)
    }
  }, [contractId])

  const fieldClickHandler = (data) => {
    setSelectedField(data)

    let timer = setTimeout(() => {
      setSelectedField(null)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }

  return (
    <Spin spinning={contractStepLoading} size='small'>
      <div className={styles.wrapper}>
        <div className={styles.column}>
          <h2>Steps and Fields:</h2>
          <AdminStepsBuilder
            fieldClickHandler={fieldClickHandler}
            {...props}
          />
        </div>
        <div className={styles.column}>
          <h2>Paste text of the contract here:</h2>
          <div className={styles.editor} id='editor'>
            <Editor
              selectedField={selectedField}
              {...props}
            />
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default AdminEditor;
