import { API } from '../../../constants/variables'
import * as types from './types'
import { getGMTDateTime } from '../../utils'



export function getSentTemplatesList() {
  const dt = getGMTDateTime()

  return {
    type: types.GET_SENT_TEMPLATES,
    getAPI: `${API}folder/list/envelope_complete?dt=${dt}`,
  }
}

export function addTemplatesFolder(data) {
  return {
    type: types.ADD_SENT_TEMPLATE_FOLDER,
    postAPI: `${API}folder/create`,
    payload: data,
    postData: data,
  }
}

export function deleteTemplatesSentFolder(data) {
  return {
    type: types.DELETE_SENT_TEMPLATE_FOLDER,
    deleteAPI: `${API}folder/delete/${data.id}`,
    payload: {data},
  }
}

export function deleteTemplatesSentFolderGlobal(data) {
  return {
    type: types.DELETE_SENT_TEMPLATE_FOLDER,
    deleteAPI: `${API}folder/delete/${data.id}/global`,
    payload: {data},
  }
}

export function updateTemplatesSentPosition(data) {
  return {
    type: types.UPDATE_SENT_TEMPLATE_POSITION,
    postAPI: `${API}folder/${data.id}/envelope_complete/set_positions`,
    payload: data,
    postData: data.data
  }
}

export function updateTemplatesSentFolderOpenStatus(data) {
  return {
    type: types.UPDATE_SENT_TEMPLATE_FOLDER_OPEN_STATUS,
    putAPI: `${API}folder/open_state/${data.id}`,
    payload: data,
    putData: {
      state: data.status === true ? 1 : 0,
    }
  }
}

export function updateTemplatesFolder(data) {
  return {
    type: types.UPDATE_SENT_TEMPLATE_FOLDER,
    putAPI: `${API}folder/update/${data.id}`,
    payload: data,
    putData: {
      title: data.title,
      chapter: data.chapter,
      parent_id: 0
    }
  }
}

export function handleActionSentTemplate(data) {
  return {
    type: types.HANDLE_ACTION_SENT_TEMPLATE,
    putAPI: `${API}envelope/${data.id}/action`,
    putData: {
      action: `${data.action}`,
    },
    isBlob: true
  }
}

export function putActionSentTemplate(data) {
  return {
    type: types.PUT_SENT_TEMPLATE_ACTION,
    putAPI: `${API}envelope/${data.id}/action`,
    putData: data.payload,
  }
}

export function getSentTemplatesPDF(id) {
  return {
    type: types.GET_SENT_TEMPLATES_PDF,
    getAPI: `${API}envelope/${id}/download_certificate`,
  }
}

export function updateTemplatesSentNotifications() {
  return {
    type: types.UPDATE_SENT_TEMPLATE_NOTIFICATIONS,
    getAPI: `${API}set_red_notification_complete`,
  }
}
