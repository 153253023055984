import React, { Component, useContext, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import HelloSign from 'hellosign-embedded'
import { Table, Menu, Dropdown, Button, Space, Form, Input, Badge, message } from 'antd'
import {
  MenuOutlined,
  MoreOutlined,
  SearchOutlined,
  RightOutlined,
  DownOutlined,
  DeleteOutlined,
  DownloadOutlined,
  AuditOutlined,
  CloseOutlined,
  StopOutlined,
  SmileOutlined,
} from '@ant-design/icons'
import FileSaver from 'file-saver'

import {
  foldersTemplatesInProgressSelector,
  templatesTemplatesInProgressSelector,
  loadingTemplatesInProgressSelector,
  loadedTemplatesInProgressSelector,
  historyTemplatesInProgressSelector,
  pdfResponseTemplatesInProgressSelector,
  messageTemplatesInProgressSelector,
  errorTemplatesInProgressSelector,
  helloSignOriginTemplatesInProgressSelector,
  updatedTemplatesInProgressSelector
} from '../../store/reducers/templatesInProgress/selectors'
import { updatedContractSelector } from '../../store/reducers/templatesAdmin/selectors'
import {
  postProgressTemplatesFolder,
  updateProgressTemplatesFolder,
  deleteProgressTemplatesFolder,
  deleteProgressTemplatesFolderGlobal,
  getTemplateHistory,
  downloadProgressTemplatePdf,
  updateProgressTemplatesPosition,
  putProgressTemplatesAction,
  getProgressTemplatesList,
  clearTemplateHS,
  updateProgressTemplatesFolderOpenStatus
} from '../../store/reducers/templatesInProgress/actions'
import { getTemplatesNotifications, updateContractSignStatus } from '../../store/reducers/templatesAdmin/actions'

import { MAX_SYMBOLS_LIMIT_80, REQUIRED_FIELD } from '../../constants/staticErrors'
import {PROCESSING_COMPLETE_TEXT, SIGNED_DONE_MESSAGE} from '../../constants/staticTexts'
import { SITE_DOMAIN } from '../../constants/variables'
import { deepMergeArrays, getTime12Format } from '../utils'
import styles from './table.module.scss'



const EditableContext = React.createContext()
const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)
const SortableItem = sortableElement(props => <tr {...props} className={props.classes} />)
const SortableContainer = sortableContainer(props => <tbody {...props} />)
const EditableRow = ({ className, index, ...props }) => {
  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      validateTrigger='onBlur'
      component={false}
    >
      <EditableContext.Provider value={form}>
        <SortableItem index={index} classes={className} {...props}/>
      </EditableContext.Provider>
    </Form>
  )
}
const EditableCell = ({ title, editable, children, dataIndex, record, handleRowSave, handleRowDelete, className, ...restProps }) => {
  const isNewCell = record && record.newFolder
  const [editing, setEditing] = useState(!!isNewCell)
  const [dynamicClassName, setDynamicClassName] = useState(className)
  const inputRef = useRef()
  const form = useContext(EditableContext)
  let childNode = children

  useEffect(() => {
    if (editing) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      })
      inputRef.current.focus()
      setDynamicClassName(className.concat(' ant-table-row-editable'))
    } else {
      setDynamicClassName(className)
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)

    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async e => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleRowSave({ ...record, ...values })
    } catch (errInfo) {
      console.log( 'Save failed:', errInfo )
      if (errInfo.values.title.length > 80) return

      handleRowDelete(record)
    }
  }

  if (editable) {
    const childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {onClick: toggleEdit})
      }

      return child
    })

    childNode = editing ? (
      <>
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: REQUIRED_FIELD,
            },
            {
              max: 80,
              message: MAX_SYMBOLS_LIMIT_80,
            }
          ]}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            placeholder='Write a project name'
            className={styles.tableFolderInput}
          />
        </Form.Item>
      </>
    ) : (
      <span>
        {childrenWithProps}
      </span>
    )
  }

  return <td
    {...restProps}
    className={dynamicClassName}
  >{childNode}</td>
}

class TemplatesInProgressTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadFile: null,
      downloadLoading: false,
      folderLoading: false,
      searchText: '',
      searchedColumn: '',
      historyId: null,
      selectedFiles: [],
      templates: [],
      tableData: [],
      fullTableData: [],
      expandedRows: [],
      tableIndexArray: [],
      folderStartPosition: -1,
      dragOldIndex: null,
      dragNewIndex: null,
    }

    this.columns = [
      {
        title: () => {
          return (
            <div className={styles.groupActionWrapper}>
              <div className={styles.groupActionButtons}>
                <Button
                  size='large'
                  onClick={(e) => this.handleAddFolder()}
                  disabled={this.state.folderLoading}
                >
                  + New Project
                </Button>
              </div>
            </div>
          )
        },
        dataIndex: 'title',
        key: 'title',
        width: 700,
        editable: true,
        className: 'column-with-search-bar drag-visible',
        ...this.getColumnSearchProps('title'),
      },
      {
        title: 'Progress',
        dataIndex: 'status',
        key: 'status',
        width: 150,
        className: 'drag-visible',
        render: (text, record) => {
          if (!record || record.parentId >= 0 || record.isHistory) return
          const percentageStyle = record.status === 100 ?
            {
              width: `${record.status}%`,
              borderRadius: `28px`
            } :
            {
              width: `${record.status}%`
            }

          return <span className={styles.statusBar}>
            <span>Pending</span>
            <span style={percentageStyle}>Status Percentage {record.status}%</span>
          </span>
        }
      },
      {
        title: 'Last updated',
        dataIndex: 'updated_at',
        width: 220,
        className: 'drag-visible',
        render: (record) => {
          const dateString = record
          const D = new Date(dateString)
          const time = getTime12Format(D)
          const dateGMTString = D.toLocaleString('en-US', {timeZone: 'Africa/Abidjan'})
          const date = dateGMTString.replace(/:\d\d([ ap]|$)/, ' ').toLowerCase()

          return record ?
            date.replace(/,/g, ' at') :
            null
        }
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: 130,
        render: (text, record, index) => {
          if (!record || record.isHistory || record.actions && Object.entries(record.actions).length === 0) return

          return <Space size='middle' align='end'>
            <Dropdown
              trigger={['click']}
              placement='bottomRight'
              overlay={() => this.moreActionsMenu(record)}
            >
              <Button
                size='large'
                icon={<MoreOutlined/>}
              />
            </Dropdown>
          </Space>
        },
      },
    ]

    this.helloSignClient = null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.foldersEntities.length !== this.props.foldersEntities.length ||
      prevProps.templatesEntities.length !== this.props.templatesEntities.length ||
      prevProps.contractUpdated !== this.props.contractUpdated && this.props.contractUpdated ||
      prevProps.activeContractUpdated !== this.props.activeContractUpdated && this.props.activeContractUpdated
    ) {
      // Set tree structure
      console.log('Set tree structure...')

      const foldersArr = JSON.parse(JSON.stringify(this.props.foldersEntities))
      const templatesArr = JSON.parse(JSON.stringify(this.props.templatesEntities))
      this.props.getTemplatesNotifications()

      this.setState({
        templates: deepMergeArrays(foldersArr, templatesArr, 'key')
      }, () => {

        console.log(this.state.templates)

        this.setState({
          tableData: this.state.templates.map((item) => {
            // init children array
            if (item.children) {
              if (!Array.isArray(item.children)) {
                console.log('children array 1 :' + item.children)

                item.children = Object.keys(item.children).map(key => item.children[key])

                item.children.map((el) => {
                  if (el.templates) {
                    Object.keys(el.templates).map((key, index) => el.children.push(el.templates[key]))
                  }

                  return el
                })

                console.log('children array 2 :' + item.children)
              }
            }

            // add templates to children array
            if (item.templates) {
              Object.entries(item.templates).map(item => item[1].children = [])
              Object.keys(item.templates).map((key, index) => item.children.push(item.templates[key]))
            }

            item.children && item.children.sort((a, b) => a.position - b.position)
            return item
          }).sort((a, b) => a.position - b.position)
        }, () => {
          const fullDataArray = []

          console.log(this.state.tableData)

          this.state.tableData.map((item) => {
            fullDataArray.push(item)

            item.children ?
              item.children.map(item => fullDataArray.push(item)) :
              item.children = []
          })
          this.setState({
            fullTableData: fullDataArray,
            folderStartPosition: this.state.tableData.length > 0 ? this.state.tableData[0].position - 1 : -1,
          })
        })
      })
    }

    if (
      prevState.tableData.length === 0 && this.state.tableData.length > 0 &&
      prevState.expandedRows.length === 0 && this.state.expandedRows.length === 0 &&
      prevState.fullTableData === this.state.fullTableData
    ) {
      // Open all folders trigger
      this.state.tableData.map((item) => {
        if (item.isOpen) {
          this.setState(prevState =>
            prevState.expandedRows.includes(item.index) ?
              null :
              {
                expandedRows: [...prevState.expandedRows, item.index]
              }
          )
        }
      })
    }

    if (
      prevState.fullTableData.length === 0 &&
      this.state.fullTableData.length > 0
    ) {
      this.setState({
        tableIndexArray: this.state.fullTableData.map(({ index }) => index)
      })
    }

    if (
      !prevProps.templatePdfResponse &&
      this.props.templatePdfResponse &&
      !this.props.templatesLoading &&
      this.state.downloadFile
    ) {
      // download 1 pdf
      FileSaver.saveAs(new Blob([this.props.templatePdfResponse]), `${this.state.downloadFile[0].title}.pdf`)
    }

    if (
      !this.props.templatesLoading &&
      this.state.historyId
    ) {
      // get History data
      const isHistoryExist = this.state.fullTableData.filter(item => item.id === this.state.historyId)

      if (isHistoryExist[0] && isHistoryExist[0].children.length === 0) {
        this.props.getTemplateHistory(this.state.historyId)
      }
      this.setState({historyId: null})
    }

    if (
      !this.props.templatesLoading &&
      this.props.templateHistory !== prevProps.templateHistory &&
      this.props.templateHistory.length > 0
    ) {
      this.props.getTemplatesNotifications()
      // Setting history data to template children
      let parentId = null
      let historyArr = []
      this.props.templateHistory.map((item, key) => {
        const dateString = item.date
        const D = new Date(dateString)
        const time = getTime12Format(D)
        const dateGMTString = D.toLocaleString('en-US', {timeZone: 'Africa/Abidjan'})
        const date = dateGMTString.replace(/:\d\d([ ap]|$)/, ' ').toLowerCase()
        const historyItemId = item.id
        const historyTitleString = `<span class='date'>${date.replace(/,/g, ' at')}</span> ${
          item.action.includes('View') ?
            `<svg width='16' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M8 2.6875c-.30078.02734-.60156.05469-.875.13672.13672.21875.19141.49219.21875.73828 0 .84766-.71094 1.53125-1.53125 1.53125-.27344 0-.54688-.05469-.73828-.19141-.08203.27344-.13672.54688-.13672.84766 0 1.69531 1.36719 3.0625 3.0625 3.0625s3.0625-1.36719 3.0625-3.0625c0-1.66797-1.36719-3.03516-3.0625-3.03516V2.6875zm7.7656 2.67969C14.2891 2.46875 11.3359.5 8 .5 4.63672.5 1.68359 2.46875.207031 5.36719.152344 5.47656.125 5.61328.125 5.75c0 .16406.027344.30078.082031.41016C1.68359 9.05859 4.63672 11 8 11c3.3359 0 6.2891-1.94141 7.7656-4.83984.0547-.10938.0821-.2461.0821-.38282 0-.16406-.0274-.30078-.0821-.41015zM8 9.6875c-2.70703 0-5.19531-1.50391-6.50781-3.9375C2.80469 3.31641 5.29297 1.8125 8 1.8125c2.6797 0 5.168 1.50391 6.4805 3.9375C13.168 8.18359 10.6797 9.6875 8 9.6875z' fill='#050A1A' /></svg>` :
            item.action.includes('Sign') ?
              `<svg width='14' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7 .96875C3.25391.96875.21875 4.03125.21875 7.75c0 3.7461 3.03516 6.7812 6.78125 6.7812 3.7188 0 6.7812-3.0351 6.7812-6.7812C13.7812 4.03125 10.7188.96875 7 .96875zm0 1.3125c3.0078 0 5.4688 2.46094 5.4688 5.46875 0 3.0352-2.461 5.4688-5.4688 5.4688-3.03516 0-5.46875-2.4336-5.46875-5.4688 0-3.00781 2.43359-5.46875 5.46875-5.46875zm3.8281 3.58203l-.6289-.6289c-.1094-.13672-.32811-.13672-.46482 0L5.87891 9.0625 4.23828 7.42188c-.13672-.13672-.32812-.13672-.46484 0l-.62891.60156c-.10937.13672-.10937.35547 0 .46484l2.48828 2.51562c.13672.1367.32813.1367.46485 0l4.73044-4.67578c.1094-.13671.1094-.35546 0-.46484z' fill='#219653' /></svg>` :
              item.action.includes('Decline') ?
                `<svg width='14' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7 .96875C3.25391.96875.21875 4.03125.21875 7.75c0 3.7461 3.03516 6.7812 6.78125 6.7812 3.7188 0 6.7812-3.0351 6.7812-6.7812C13.7812 4.03125 10.7188.96875 7 .96875zm3.8555 2.92578c1.9961 1.99609 2.1054 5.11328.4375 7.24607L3.60938 3.45703c2.13281-1.66797 5.25-1.55859 7.24612.4375zM3.11719 11.6328c-1.9961-1.99608-2.10547-5.11327-.4375-7.24608l7.68361 7.68358c-2.13283 1.668-5.25002 1.5586-7.24611-.4375z' fill='#EB5757' /></svg>` :
                `<svg width='12' height='4' fill='none' xmlns='http://www.w3.org/2000/svg'><path d="M7.3125 1.75C7.3125 1.03906 6.71094.4375 6 .4375c-.73828 0-1.3125.60156-1.3125 1.3125 0 .73828.57422 1.3125 1.3125 1.3125.71094 0 1.3125-.57422 1.3125-1.3125zM10.5938.4375c-.73833 0-1.31255.60156-1.31255 1.3125 0 .73828.57422 1.3125 1.31255 1.3125.7109 0 1.3124-.57422 1.3124-1.3125 0-.71094-.6015-1.3125-1.3124-1.3125zm-9.18755 0c-.738281 0-1.3125.60156-1.3125 1.3125 0 .73828.574219 1.3125 1.3125 1.3125.71094 0 1.3125-.57422 1.3125-1.3125 0-.71094-.60156-1.3125-1.3125-1.3125z" fill="#050A1A"/></svg>`
        } <span class='text'>${item.activity}</span>`
        const historyItem = {
          id: key,
          key: key,
          index: key,
          status: null,
          title: historyTitleString,
          updated_at: null,
          isHistory: true,
        }

        this.state.fullTableData.map((obj) => {
          if (obj.id === historyItemId) {
            parentId = obj.folder_id
            historyArr.push(historyItem)
          }
        })

        this.setState(prevState => ({
          fullTableData: prevState.fullTableData.map(obj =>
            (
              obj.id === historyItemId ?
                Object.assign(obj, {children: historyArr, is_new: false}) :
                obj
            )
          ),
          tableData: prevState.tableData.map(obj =>
            (
              parentId && parentId === obj.id && obj.new_objects_count > 0 ?
                Object.assign(obj, {new_objects_count: obj.new_objects_count - 1}) :
                obj.id === historyItemId ?
                  Object.assign(obj, {children: historyArr, is_new: false}) :
                  obj.children && obj.children.length > 0 ?
                    Object.assign(obj, {children: obj.children.map((template) => {
                        if (template.id === historyItemId) template.children = historyArr
                        return template
                    })}) :
                  obj
            )
          )
        }))
      })
    }

    if (prevProps.templatesMessage !== this.props.templatesMessage && this.props.templatesMessage) {
      message.destroy()
      message.info(this.props.templatesMessage, 3)
    }

    if (prevProps.templateHelloSign !== this.props.templateHelloSign && this.props.templateHelloSign) {
      const {templateHelloSign} = this.props

      if ( templateHelloSign.data.hs_url ) {
        this.helloSignClient = new HelloSign({ clientId: templateHelloSign.data.hs_key })
        this.showContractModal(templateHelloSign.data.hs_url)
      }

      if (templateHelloSign.data.errors && templateHelloSign.data.errors.length > 0) {
        message.error({
          content: templateHelloSign.data.errors[0],
          className: 'error-message',
          duration: 3
        })
      }
    }

    if (prevProps.activeContractUpdated !== this.props.activeContractUpdated && this.props.activeContractUpdated) {
      const { templateHelloSign, getInProgressTemplatesList, getTemplatesNotifications } = this.props

      message.destroy()
      message.success({
        content: SIGNED_DONE_MESSAGE,
        className: 'success-message',
        duration: 5
      })
      getInProgressTemplatesList()
      getTemplatesNotifications()

      if (this.props.templateHistory.length > 0) {
        templateHelloSign && templateHelloSign.templateId && this.props.getTemplateHistory(templateHelloSign.templateId)
      }
    }

    if (this.helloSignClient) {
      const {templateHelloSign, clearTemplateHelloSignData} = this.props

      // Fixed body (?)
      this.helloSignClient.on('sign', () => {
        const { updateContractStatus } = this.props

        updateContractStatus(templateHelloSign.data.envelope_id)
        this.helloSignClient.close()
      })

      this.helloSignClient.on('close', () => {
        this.helloSignClient.close()
        clearTemplateHelloSignData()
      })
    }
  }

  componentWillUnmount() {
    message.destroy()
  }

  render() {
    return this.body
  }

  get body() {
    const {templatesLoading} = this.props
    const {downloadLoading} = this.state
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: record.parentId >= 0,
          dataIndex: col.dataIndex,
          title: col.title,
          handleRowSave: this.handleRowSave,
          handleRowDelete: this.handleRowDelete,
        })
      }
    })
    const tableComponents = {
      body: {
        wrapper: this.DraggableContainer,
        row: this.DraggableBodyRow,
        cell: EditableCell,
      },
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={styles.headButton}>
          <a
            className='ant-btn ant-btn-primary ant-btn-lg'
            href={`${SITE_DOMAIN}/catalog`}
            style={{
              paddingLeft: `10px`,
              paddingRight: `10px`
            }}
          >
            + New Contract
          </a>
        </div>
        <Table
          className={styles.table}
          loading={templatesLoading || downloadLoading}
          columns={columns}
          dataSource={this.state.tableData}
          components={tableComponents}
          rowKey='index'
          rowClassName={record => this.setRowClassName(record)}
          pagination={false}
          expandable={{
            expandedRowKeys: this.state.expandedRows,
            expandIcon: ({ expanded, onExpand, record }) => this.expandedIconRender({ expanded, onExpand, record }),
          }}
          onExpand={(expanded, record) => this.handleRowExpand(expanded, record)}
        />
      </div>
    )
  }

  moreActionsMenu = (record) => {
    const {SubMenu, Item} = Menu
    const {templateHelloSign} = this.props
    const isFolder = record.parentId >= 0

    return <Menu className={styles.dropDown} subMenuOpenDelay={0.2}>
      {
        isFolder ?
          <>
            <Item key={record.id} onClick={() => this.handleDeleteFolder(record)} style={{ color: '#EB5757' }}>
              <DeleteOutlined color={'#EB5757'} />
              Delete folder
            </Item>
            <Item key={record.key} onClick={() => this.handleDeleteFolderGlobally(record)} style={{ color: '#EB5757' }}>
              <DeleteOutlined color={'#EB5757'} />
              Delete from dashboard
            </Item>
          </> :
          <>
            {
              Object.entries(record.actions).length > 0 &&
                Object.entries(record.actions).map((item, index) => {
                  return <>
                    {
                      typeof item[1] !== 'object' ?
                        templateHelloSign && templateHelloSign.data && record.id === templateHelloSign.templateId ?
                          null :
                          <Item
                            key={item[0]}
                            style={item[0] === 'cancel' || item[0] === 'delete' ? { color: '#EB5757' } : {}}
                            onClick={
                              item[0].includes('sign') || item[0].includes('send') ?
                                () => this.handleTemplateAction(record, item[0]) :
                              item[0] === 'download_template' ?
                                () => this.downloadTemplateHandler(record) :
                                item[0] === 'cancel' || item[0] === 'delete' ?
                                  () => this.handleTemplateAction(record, item[0]) :
                                  () => message.error({
                                    content: 'This action has not yet been implemented',
                                    className: 'error-message',
                                    duration: 3
                                  })
                            }
                          >
                            {
                              item[0].includes('sign') || item[0].includes('send') ?
                                <AuditOutlined style={{fontSize: `16px`}} /> :
                              item[0] === 'download_template' ?
                                <DownloadOutlined style={{fontSize: `16px`}} /> :
                                item[0] === 'cancel' || item[0] === 'delete' ?
                                  <StopOutlined color={'#EB5757'} style={{fontSize: `16px`}} /> :
                                  <SmileOutlined style={{fontSize: `16px`}} />
                            }
                            {item[1]}
                          </Item> :
                        <SubMenu
                          key='sub-1'
                          title='Resend to'
                          popupOffset={[-5, -5]}
                          icon={<span role='img' className='anticon anticon-audit'><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.7188 5.28125C14.9062 5.5 15 5.75 15 6C15 6.28125 14.9062 6.53125 14.7188 6.71875L9.71875 11.7188C9.5 11.9062 9.25 12 9 12C8.71875 12 8.46875 11.9062 8.28125 11.7188C8.09375 11.5312 8 11.3125 8 11V8.78125C6.6875 8.84375 5.71875 8.96875 5.125 9.1875C4.46875 9.4375 4.09375 9.8125 3.96875 10.3438C3.84375 10.9062 3.90625 11.6875 4.21875 12.7188C4.28125 13.0312 4.25 13.2812 4.09375 13.5312C3.9375 13.7812 3.71875 13.9062 3.4375 13.9688C3.15625 14.0312 2.90625 14 2.65625 13.8125C1.8125 13.1875 1.15625 12.5 0.71875 11.6875C0.21875 10.8438 0 9.9375 0 8.9375C0 6.8125 0.875 5.28125 2.65625 4.34375C3.875 3.71875 5.65625 3.34375 8 3.28125V1C8 0.71875 8.09375 0.46875 8.28125 0.28125C8.46875 0.09375 8.71875 0 9 0C9.25 0 9.5 0.09375 9.71875 0.28125L14.7188 5.28125ZM9 11L14 6L9 1V4.25C7.375 4.28125 6.09375 4.375 5.125 4.5625C3.8125 4.84375 2.84375 5.28125 2.15625 5.90625C1.375 6.65625 1 7.65625 1 8.9375C1 9.875 1.25 10.7188 1.75 11.4688C2.125 12.0312 2.625 12.5625 3.25 13C2.8125 11.5625 2.78125 10.4375 3.15625 9.625C3.46875 8.9375 4.15625 8.4375 5.1875 8.125C6.03125 7.90625 7.3125 7.78125 9 7.75V11ZM17.7188 6.71875C17.9062 6.53125 18 6.28125 18 6C18 5.75 17.9062 5.5 17.7188 5.28125L12.7188 0.28125C12.5 0.09375 12.25 0 12 0C11.7188 0 11.4688 0.09375 11.2812 0.28125L17 6L11.2812 11.7188C11.4688 11.9062 11.7188 12 12 12C12.25 12 12.5 11.9062 12.7188 11.7188L17.7188 6.71875Z" fill="#4F4F4F"/></svg></span>}
                        >
                          { // Resend menu
                            Object.entries(item[1]).map((submenu) => {
                              return <Item
                                key={submenu[0]}
                                onClick={() => this.handleTemplateAction(record, submenu[0])}
                              >
                                {submenu[1]}
                              </Item>
                            })
                          }
                        </SubMenu>
                    }
                  </>
                })
            }
          </>
      }
    </Menu>
  }

  setRowClassName = (record) => {
    const isFolder = record.parentId >= 0
    const isHistory = record.isHistory

    return isFolder ?
      'ant-table-row-with-children' :
      record.children && record.children.length > 0 ?
        'ant-table-row-history-parent' :
        isHistory ?
          'ant-table-row-history' :
          ''
  }

  expandedIconRender = ({expanded, onExpand, record}) => {
    const isFolder = record.parentId >= 0

    return !isFolder ?
      expanded ? (
        <>
          <DownOutlined onClick={e => onExpand(record, e)}/>
        </>
      ) : (
        <>
          {record.is_new && !record.isHistory ? <Badge color='#602BF5' /> : null}
          <RightOutlined onClick={e => onExpand(record, e)} />
        </>
      ) : expanded ? (
        <>
          {record.new_objects_count ? <Badge count={record.new_objects_count}/> : null}
          <DownOutlined onClick={e => onExpand(record, e)} />
        </>
      ) : (
        <>
          {record.new_objects_count ? <Badge count={record.new_objects_count}/> : null}
          <RightOutlined onClick={e => onExpand(record, e)} />
        </>
      )
  }

  handleRowExpand(expanded, record) {
    const isFolder = record.parentId >= 0
    const {historyId} = this.state
    const {updateFolderOpenStatus} = this.props

    isFolder && updateFolderOpenStatus({
      id: record.id,
      status: expanded
    })

    if (!isFolder && !historyId) {
      expanded && this.setState({historyId: record.id})
    }

    this.setState(prevState =>
      prevState.expandedRows.includes(record.index) ?
        {
          expandedRows: prevState.expandedRows.filter(
            key => key !== record.index
          )
        } :
        {
          expandedRows: [...prevState.expandedRows, record.index]
        }
    )
  }

  handleAddFolder = () => {
    const {tableData} = this.state
    const newTableData = [...this.state.tableData]
    const isFile = (element) => element.folder_id >= 0
    const isFileIndex = newTableData.findIndex(isFile)
    const newFolderData = {
      id: tableData.length > 0 ? tableData[0].id - 1 : 0,
      key: `${tableData.length > 0 ? tableData[0].id - 1 : 0}-${(~~(Math.random() * 1e8)).toString(16)}`,
      index: (~~(Math.random() * 1e8)).toString(16), // tableData.length > 0 ? isFileIndex + 1 : 0,
      parentId: 0,
      newFolder: true,
      title: '',
      children: [],
    }

    newTableData.splice(0, 0, {...newFolderData})

    this.setState({
      tableData: newTableData,
      folderLoading: true
    })
  }

  handleRowSave = row => {
    const newData = [...this.state.tableData]
    const newFullData = [...this.state.fullTableData]
    const index = newData.findIndex(item => row.key === item.key)
    const item = newData[index]
    const indexFull = newFullData.findIndex(item => row.key === item.key)
    const itemFull = newFullData[indexFull]
    const newFolderData = {
      title: row.title,
      chapter: 'ENVELOPE_PROGRESS',
      parent_id: row.parentId,
      index: row.index,
      position: this.state.folderStartPosition,
    }
    const updateFolderData = {
      id: row.id,
      title: row.title,
      chapter: 'ENVELOPE_PROGRESS'
    }

    newData.splice(index, 1, { ...item, ...row })
    newFullData.splice(indexFull, 1, { ...itemFull, ...row })

    this.setState({
      tableData: newData,
      fullTableData: newFullData
    }, () => {
      row.newFolder ?
        this.props.postFolder(newFolderData) :
        this.props.updateFolder(updateFolderData)

      this.setState((prevState) => ({
        folderStartPosition: prevState.folderStartPosition - 1,
        folderLoading: false
      }))

      this.handleRowExpand(false, row)
    })
  }

  handleRowDelete = row => {
    const newTableData = [...this.state.tableData]
    const index = newTableData.findIndex(item => row.key === item.key)

    index >= 0 && newTableData.splice(index, 1)

    this.setState({
      tableData: newTableData,
      folderLoading: false
    })
  }

  handleDeleteFolder = (record) => {
    const { deleteFolder } = this.props

    deleteFolder(record)
  }

  handleDeleteFolderGlobally = (record) => {
    const { deleteFolderGlobal } = this.props

    deleteFolderGlobal(record)
  }

  handleTemplateAction = (record, action) => {
    const {putAction} = this.props

    if (!record) return

    return putAction({
      id: record.id,
      action: action,
      record: record
    })
  }

  downloadTemplateHandler = (record) => {
    console.log(record)
    message.error({
      content: 'This action has not yet been implemented',
      className: 'error-message',
      duration: 3
    })
    // const { downloadPdf } = this.props
    // const { fullTableData, selectedFiles } = this.state
    // let downloadFile = fullTableData.filter(item => item.id === id)
    //
    // // Multiple files download
    // if (!id && selectedFiles.length > 0) {
    //   selectedFiles.forEach((item) => {
    //     this.setState({
    //       downloadLoading: true
    //     })
    //     axios({
    //       url: `${API}envelope/${item.id}/download_template`,
    //       method: 'GET',
    //       responseType: 'blob',
    //     })
    //       .then(response => {
    //         FileSaver.saveAs(new Blob([response]), `${item.title}.pdf`)
    //         this.setState({
    //           downloadLoading: false
    //         })
    //       })
    //       .catch((error) => {
    //         console.log(error)
    //       })
    //   })
    //
    //   return false
    // }
    //
    // this.setState({downloadFile}, () => {
    //   downloadPdf(id)
    // })
  }

  showContractModal = signingUrl => {
    this.helloSignClient.open(signingUrl, {
      // allowCancel: false,
      testMode: true,
      skipDomainVerification: true,
      locale: 'EN_US',
      whiteLabelingOptions: {
        'page_background_color': '#F7F8F9',
        'header_background_color': '#060357',
        'primary_button_color': '#FE7C38',
        'primary_button_color_hover': '#FF3C61',
        'secondary_button_color': '#DAF3D6',
        'secondary_button_text_color': '#4F4F4F',
        'secondary_button_color_hover': '#48C333',
        'legal_version': 'terms2',
      },
    })
  }

  // D`n`D
  DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      lockAxis='y'
      helperClass='row-dragging'
      useWindowAsScrollContainer
      transitionDuration={150}
      onSortEnd={this.onSortEnd}
      updateBeforeSortStart={this.updateBeforeSortStart}
      {...props}
    />
  )

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = this.state.fullTableData.findIndex(x => x.index === restProps['data-row-key'])

    for (const [key, value] of Object.entries(restProps)) {
      if (key === 'children' && Array.isArray(value)) {
        // console.log(value[1].props.record)
      }
    }

    return <EditableRow index={index} className={className} {...restProps} />
  }

  updateBeforeSortStart = ({node, index, collection, isKeySorting}, event) => {
    const {fullTableData, expandedRows} = this.state
    const record = fullTableData[index]
    const isFolder = record.parentId >= 0

    return new Promise((resolve) => {
      isFolder && expandedRows.includes(record.index) && this.handleRowExpand(false, record)

      fullTableData.map((item) => {
        // IF History item
        if (expandedRows.includes(item.index) && !('parentId' in item)) {
          item.children = []
          this.handleRowExpand(false, item)
        }
      })
      resolve()
    })
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    const {tableData, fullTableData, expandedRows} = this.state
    const record = fullTableData[oldIndex]
    const isFolder = record.parentId >= 0 || record.children.length > 0

    if (isFolder && !expandedRows.includes(record.index)) {
      this.handleRowExpand(true, record)
    }

    // if (oldIndex !== newIndex) {
      const newTableData = arrayMove([].concat(tableData), oldIndex, newIndex).filter(el => !!el)
      const newFullTableData = arrayMove([].concat(fullTableData), oldIndex, newIndex).filter(el => !!el)
      const newIndexArray = newFullTableData.map(({ index }) => index)

      // console.log('Sorted newData: ', newTableData)
      // console.log('Sorted fullTableData: ', newFullTableData)
      // console.log('Sorted indexes: ', newIndexArray)

      this.setState({
        // tableData: newTableData,
        fullTableData: newFullTableData,
        tableIndexArray: newIndexArray,
        dragOldIndex: oldIndex,
        dragNewIndex: newIndex
      }, () => {
        this.handleSortingTableChange()
      })
    // }
  }

  handleSortingTableChange = () => {
    let prevFolderId = null
    let folderId = null
    const deepCopyFullTableData = JSON.parse(JSON.stringify(this.state.fullTableData)).map((item) => {
      const isFolder = item.parentId >= 0

      // Clean tree structure
      if (isFolder) item.children = []

      return item
    })
    console.log('FullTableData copy ...', deepCopyFullTableData)
    const filteredTableData = deepCopyFullTableData.map((item, i) => {
      const isFolder = item.parentId >= 0
      const isTemplate = item.folder_id >= 0
      const previousItem = deepCopyFullTableData[i - 1]
      const isPreviousFolder = previousItem && previousItem.parentId >= 0
      const isPreviousInFolder = previousItem && previousItem.folder_id > 0
      const nextItem = deepCopyFullTableData[i + 1]
      const isNextInFolder = nextItem && nextItem.folder_id > 0
      const newIndex = this.state.dragNewIndex

      // Move to Folder
      if (isTemplate && i === newIndex) {

        // Inside
        if (isPreviousFolder) {
          prevFolderId = item.folder_id
          item.folder_id = previousItem.id

          folderId = item.folder_id
          this.setState((prevState) => ({
            fullTableData: prevState.fullTableData.map((obj, index) => {
              if (obj.id === item.id) {
                return {
                  ...obj,
                  folder_id: previousItem.id
                }
              }

              return obj
            })
          }))
          console.log('IN: ', item)
        } else if (isPreviousInFolder) {
          prevFolderId = item.folder_id
          item.folder_id = previousItem.folder_id

          folderId = item.folder_id
          this.setState((prevState) => ({
            fullTableData: prevState.fullTableData.map((obj, index) => {
              if (obj.id === item.id) {
                return {
                  ...obj,
                  folder_id: previousItem.folder_id
                }
              }

              return obj
            })
          }))
          console.log('IN-0: ', item)
        } else if (isNextInFolder) {
          if (item.folder_id === 0) {
            prevFolderId = item.folder_id
            item.folder_id = nextItem.folder_id

            folderId = item.folder_id
            this.setState((prevState) => ({
              fullTableData: prevState.fullTableData.map((obj, index) => {
                if (obj.id === item.id) {
                  return {
                    ...obj,
                    folder_id: nextItem.folder_id
                  }
                }

                return obj
              })
            }))
            console.log('IN-2: ', item)
          }

          // Outside
        } else {
          if (item.folder_id !== 0) {
            prevFolderId = item.folder_id
            item.folder_id = 0
            folderId = item.folder_id
            this.setState((prevState) => ({
              fullTableData: prevState.fullTableData.map((obj, index) => {
                if (obj.id === item.id) {
                  return {
                    ...obj,
                    folder_id: 0
                  }
                }

                return obj
              })
            }))
            console.log('OUT: ', item)
          }
        }
      }

      // Setting tree structure
      if (item.folder_id > 0) {
        deepCopyFullTableData.forEach((template) => {
          const isFolder = template.parentId >= 0

          if (isFolder && template.id === item.folder_id) {
            template.children.push(item)
          }
        })
      }

      return item
    })
      // Remove templates that put inside folder
      .filter(item => item.folder_id === 0 || item.parentId >= 0)

    this.handlePostSortingData(folderId, prevFolderId, deepCopyFullTableData, filteredTableData)

    console.log('HANDLE SORTING C H A N G E:')
    console.log('FULL: ', deepCopyFullTableData)
    console.log('FILTERED: ', filteredTableData)

    prevFolderId = null
    folderId = null
    this.setState({
      tableData: filteredTableData
    })
  }

  handlePostSortingData = (folderId, prevFolderId, deepCopyFullTableData, filteredTableData) => {
    const {updatePosition} = this.props
    const {dragNewIndex} = this.state
    const dragTemplate = deepCopyFullTableData[dragNewIndex]
    const dragId = deepCopyFullTableData[dragNewIndex].id
    let folderData = []

    if (Number.isInteger(folderId)) {
      const folder = deepCopyFullTableData.filter(item => item.id === folderId)
      folderData = folder.length > 0 ?
        folder[0]['children'].map((item, i) => {
          return {
            id: item.id,
            type: 'envelope'
          }
        }) :
        filteredTableData.map((item, i) => {
          const isFolder = item.parentId >= 0

          return {
            id: item.id,
            type: isFolder ? 'folder' : 'envelope'
          }
        })
    } else {
      folderId = 0
      folderData = filteredTableData.map((item, i) => {
        const isFolder = item.parentId >= 0

        return {
          id: item.id,
          type: isFolder ? 'folder' : 'envelope'
        }
      })
    }

    updatePosition({
      id: folderId,
      data: folderData,
      dragId: dragId,
      prevFolderId: prevFolderId,
      dragTemplate: dragTemplate
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdownVisible: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return <div className={styles.tableSearchWrapper}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({closeDropdown: false})
            this.setState({
              searchText: e.target.value,
              searchedColumn: dataIndex,
            })
          }}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          prefix={<SearchOutlined />}
          disabled={this.props.templatesLoading}
          placeholder='Search ...'
        />
        {selectedKeys.length > 0 &&
        <Button
          icon={<CloseOutlined/>}
          onClick={() => this.handleResetSearch(clearFilters)}
          aria-label='Reset'
          style={{zIndex: 99, width: 20}}
        />
        }
      </div>
    },
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record.children && record.children.length > 0 ?
        record.children.some(record => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())) :
        record[dataIndex] ?
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) :
          false
    },
    render: (text, record) => {
      const recordTitleHtml = this.stringToHTML(record.title)

      return <span>
        <DragHandle />
        {this.state.searchedColumn === dataIndex ?
          record.isHistory ?
            <span dangerouslySetInnerHTML={{__html: recordTitleHtml.outerHTML}} /> :
            <Highlighter
              autoEscape
              searchWords={[this.state.searchText]}
              highlightStyle={{ backgroundColor: '#FFE5BD', padding: `2px` }}
              textToHighlight={recordTitleHtml.innerText ? recordTitleHtml.innerText.toString() : ''}
            /> :
          record.title ?
            <span dangerouslySetInnerHTML={{__html: recordTitleHtml.outerHTML}} /> :
            'Untitled'
        }
      </span>
    }
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  handleResetSearch = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  stringToHTML = str => {
    const dom = document.createElement('span')
    dom.innerHTML = str

    return dom
  }
}

export default connect(
  store => {
    return {
      foldersEntities: foldersTemplatesInProgressSelector(store),
      templatesEntities: templatesTemplatesInProgressSelector(store),
      templatesLoaded: loadedTemplatesInProgressSelector(store),
      templatesLoading: loadingTemplatesInProgressSelector(store),
      templatesError: errorTemplatesInProgressSelector(store),
      templatesMessage: messageTemplatesInProgressSelector(store),
      templateHistory: historyTemplatesInProgressSelector(store),
      templatePdfResponse: pdfResponseTemplatesInProgressSelector(store),
      templateHelloSign: helloSignOriginTemplatesInProgressSelector(store),
      activeContractUpdated: updatedContractSelector(store),
      contractUpdated: updatedTemplatesInProgressSelector(store),
    }
  },
  {
    postFolder: postProgressTemplatesFolder,
    updateFolder: updateProgressTemplatesFolder,
    getTemplateHistory: getTemplateHistory,
    downloadPdf: downloadProgressTemplatePdf,
    deleteFolder: deleteProgressTemplatesFolder,
    deleteFolderGlobal: deleteProgressTemplatesFolderGlobal,
    updatePosition: updateProgressTemplatesPosition,
    putAction: putProgressTemplatesAction,
    getInProgressTemplatesList: getProgressTemplatesList,
    updateContractStatus: updateContractSignStatus,
    getTemplatesNotifications: getTemplatesNotifications,
    clearTemplateHelloSignData: clearTemplateHS,
    updateFolderOpenStatus: updateProgressTemplatesFolderOpenStatus
  }
)(withRouter(TemplatesInProgressTable));
