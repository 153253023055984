export const GET_SENT_TEMPLATES = 'GET_SENT_TEMPLATES'
export const GET_SENT_TEMPLATES_PDF = 'GET_SENT_TEMPLATES_PDF'

export const ADD_SENT_TEMPLATE_FOLDER = 'ADD_SENT_TEMPLATE_FOLDER'
export const DELETE_SENT_TEMPLATE_FOLDER = 'DELETE_SENT_TEMPLATE_FOLDER'
export const UPDATE_SENT_TEMPLATE_FOLDER = 'UPDATE_SENT_TEMPLATE_FOLDER'
export const UPDATE_SENT_TEMPLATE_POSITION = 'UPDATE_SENT_TEMPLATE_POSITION'
export const UPDATE_SENT_TEMPLATE_FOLDER_OPEN_STATUS = 'UPDATE_SENT_TEMPLATE_FOLDER_OPEN_STATUS'
export const UPDATE_SENT_TEMPLATE_NOTIFICATIONS = 'UPDATE_SENT_TEMPLATE_NOTIFICATIONS'

export const PUT_SENT_TEMPLATE_ACTION = 'PUT_SENT_TEMPLATE_ACTION'
export const HANDLE_ACTION_SENT_TEMPLATE = 'HANDLE_ACTION_SENT_TEMPLATE'
