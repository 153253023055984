import React from 'react'
import { Tooltip } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

import styles from './style.module.scss'
import { FIELDS_COLORS } from '../../constants/variables'



const FieldItem = ({ item, onClick, ...props }) => {
  return <div
    className={styles.item}
    style={FIELDS_COLORS[item.party_number]}
    onClick={() => onClick(item)}
  >
    <Tooltip
      title='Edit Field'
      placement='right'
      mouseEnterDelay={1.5} // sec
      destroyTooltipOnHide={true}
    >
      <span className='drag-handler'>
        <span role='img' className='anticon'>
          <svg width='6' height='12' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fill='#747379' d='M1.04 4C0.75875 4 0.50875 4.125 0.32125 4.3125C0.13375 4.5 0.04 4.75 0.04 5C0.04 5.28125 0.13375 5.53125 0.32125 5.71875C0.50875 5.90625 0.75875 6 1.04 6C1.29 6 1.54 5.90625 1.7275 5.71875C1.915 5.53125 2.04 5.28125 2.04 5C2.04 4.75 1.915 4.5 1.7275 4.3125C1.54 4.125 1.29 4 1.04 4ZM0.04 1.25C0.04 1 0.13375 0.75 0.32125 0.5625C0.50875 0.375 0.75875 0.25 1.04 0.25C1.29 0.25 1.54 0.375 1.7275 0.5625C1.915 0.75 2.04 1 2.04 1.25C2.04 1.53125 1.915 1.78125 1.7275 1.96875C1.54 2.15625 1.29 2.25 1.04 2.25C0.75875 2.25 0.50875 2.15625 0.32125 1.96875C0.13375 1.78125 0.04 1.53125 0.04 1.25ZM0.04 8.75C0.04 8.5 0.13375 8.25 0.32125 8.0625C0.50875 7.875 0.75875 7.75 1.04 7.75C1.29 7.75 1.54 7.875 1.7275 8.0625C1.915 8.25 2.04 8.5 2.04 8.75C2.04 9.03125 1.915 9.28125 1.7275 9.46875C1.54 9.65625 1.29 9.75 1.04 9.75C0.75875 9.75 0.50875 9.65625 0.32125 9.46875C0.13375 9.28125 0.04 9.03125 0.04 8.75ZM4.96 4C4.67875 4 4.42875 4.125 4.24125 4.3125C4.05375 4.5 3.96 4.75 3.96 5C3.96 5.28125 4.05375 5.53125 4.24125 5.71875C4.42875 5.90625 4.67875 6 4.96 6C5.21 6 5.46 5.90625 5.6475 5.71875C5.835 5.53125 5.96 5.28125 5.96 5C5.96 4.75 5.835 4.5 5.6475 4.3125C5.46 4.125 5.21 4 4.96 4ZM3.96 1.25C3.96 1 4.05375 0.75 4.24125 0.5625C4.42875 0.375 4.67875 0.25 4.96 0.25C5.21 0.25 5.46 0.375 5.6475 0.5625C5.835 0.75 5.96 1 5.96 1.25C5.96 1.53125 5.835 1.78125 5.6475 1.96875C5.46 2.15625 5.21 2.25 4.96 2.25C4.67875 2.25 4.42875 2.15625 4.24125 1.96875C4.05375 1.78125 3.96 1.53125 3.96 1.25ZM3.96 8.75C3.96 8.5 4.05375 8.25 4.24125 8.0625C4.42875 7.875 4.67875 7.75 4.96 7.75C5.21 7.75 5.46 7.875 5.6475 8.0625C5.835 8.25 5.96 8.5 5.96 8.75C5.96 9.03125 5.835 9.28125 5.6475 9.46875C5.46 9.65625 5.21 9.75 4.96 9.75C4.67875 9.75 4.42875 9.65625 4.24125 9.46875C4.05375 9.28125 3.96 9.03125 3.96 8.75Z' />
          </svg>
        </span>
      </span>
      {
        item.title
      }
    </Tooltip>
  </div>
}

export default FieldItem;
