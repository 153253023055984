import React, { Component } from 'react'
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'
import axios from 'axios'

import StartPage from '../pages/StartPage'
import ContractPage from '../pages/ContractPage'
import ProductPage from '../pages/ProductPage'
import ThankYouPage from '../pages/ThankYouPage'
import ErrorPage from '../pages/ErrorPage'
import SignPage from '../pages/SignPage'
import LogoutPage from '../pages/LogoutPage'

import {
  START_PAGE,
  CONTRACT_PAGE,
  PRODUCT_PAGE,
  THANK_YOU_PAGE,
  SIGN_PAGE,
  LOGOUT_PAGE,
} from '../constants/siteMap'
import { TOKEN_DOMAIN, TOKEN_REFRESH_INTERVAL_MS } from '../constants/variables'

import { getBrowserVisibilityProp, getIsDocumentHidden } from '../components/utils'
import { setTokenCookies } from '../store/utils'



export default class AppRouter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isPageVisible: getIsDocumentHidden()
    }
  }

  componentDidMount() {
    const visibilityChange = getBrowserVisibilityProp()

    // document.addEventListener(visibilityChange, this.onVisibilityChange, false)
  }

  componentWillUnmount() {
    const visibilityChange = getBrowserVisibilityProp()
    // document.removeEventListener(visibilityChange, this.onVisibilityChange)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.isPageVisible) {
      // document.title = 'Active Tab'
      // this.interval = setInterval(this.refreshToken, TOKEN_REFRESH_INTERVAL_MS)
    } else {
      // document.title = 'Inactive Tab'
      // clearInterval(this.interval)
    }
  }

  onVisibilityChange = () => this.setState({
    isPageVisible: getIsDocumentHidden()
  })

  refreshToken = () => {
    const instance = axios.create({
      withCredentials: true,
    })

    instance.post(TOKEN_DOMAIN)
      .then(tokenRefreshResponse => {
        const token = tokenRefreshResponse.data.token

        return setTokenCookies(token)
      })
      .catch(error => {
        console.log({ error })
      })
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path={START_PAGE} exact strict component={StartPage}/>

          <Route path={CONTRACT_PAGE} exact component={ContractPage}/>
          <Route path={`${CONTRACT_PAGE}/:ContractId`} exact component={ContractPage}/>
          <Route path={`${PRODUCT_PAGE}/:ContractId/:ProductId`} exact component={ProductPage}/>
          <Route path={`${THANK_YOU_PAGE}/:TempId`} exact component={ThankYouPage}/>

          <Route path={`${SIGN_PAGE}/:TempId`} exact component={SignPage}/>

          <Route path={LOGOUT_PAGE} exact strict component={LogoutPage}/>
          <Route component={ErrorPage}/>
        </Switch>
      </Router>
    )
  }
}
