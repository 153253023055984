import React from 'react'

import Header from '../Header'
import Footer from '../Footer'
import styles from './global.module.scss'



const PageWrapper = ({ className, withClickHandler, children }) => {
  styles.pageClass = className ? className : ''
  const componentClassName = `${styles.wrapper} ${styles.pageClass}`

  return (
    <div className={componentClassName}>
      <Header withClickHandler={withClickHandler} />
      <main role='main' className='container'>
        {children}
      </main>
      <Footer withClickHandler={withClickHandler} />
    </div>
  )
}

export default PageWrapper
