import React, { Component, createContext, useContext, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import arrayMove from 'array-move'
import axios from 'axios'
import Cookies from 'universal-cookie'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import { Button, Dropdown, Menu, Space, Table, Input, Badge, Form, message } from 'antd'
import {
  MoreOutlined,
  SearchOutlined,
  RightOutlined,
  DownOutlined,
  CloseOutlined,
  EditOutlined,
  DownloadOutlined,
  CopyOutlined,
  DeleteOutlined,
  MenuOutlined
} from '@ant-design/icons'

import {
  errorTemplatesSelector,
  foldersTemplatesSelector,
  loadedTemplatesSelector,
  loadingTemplatesSelector,
  messageTemplatesSelector,
  templatesTemplatesSelector
} from '../../store/reducers/templates/selectors'
import {
  postTemplatesFolder,
  updateTemplatesFolder,
  updateTemplatesPosition,
  deleteTemplatesFolder,
  deleteTemplatesFolderGlobal,
  cloneTemplatesContract,
  updateTemplatesFolderOpenStatus
} from '../../store/reducers/templates/actions'
import {
  loadedTemplateDataSelector,
  loadingTemplateDataSelector,
  updateLoadingTemplateDataSelector,
  updateLoadedTemplateDataSelector,
  titleTemplateDataSelector,
  idTemplateDataSelector,
  folderIdTemplateDataSelector
} from '../../store/reducers/templateData/selectors'
import {
  postLoadingTemplatesInProgressSelector,
  postLoadedTemplatesInProgressSelector,
  loadedTemplatesInProgressSelector,
} from '../../store/reducers/templatesInProgress/selectors'
import { getTemplatesNotifications } from '../../store/reducers/templatesAdmin/actions'
import { getTokenCookies, setTokenCookies } from '../../store/utils'

import { PRODUCT_PAGE } from '../../constants/siteMap'
import { REQUIRED_FIELD, MAX_SYMBOLS_LIMIT_80 } from '../../constants/staticErrors'
import { API, SITE_DOMAIN, TOKEN_COOKIE, TOKEN_DOMAIN } from '../../constants/variables'

import { deepMergeArrays, getTime12Format } from '../utils'
import styles from './table.module.scss'



const EditableContext = createContext()
const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)
const SortableItem = sortableElement(props => <tr {...props} className={props.classes} />)
const SortableContainer = sortableContainer(props => <tbody {...props} />)
const EditableRow = ({ className, index, ...props }) => {
  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      validateTrigger='onBlur'
      component={false}
    >
      <EditableContext.Provider value={form}>
        <SortableItem index={index} classes={className} {...props} />
      </EditableContext.Provider>
    </Form>
  )
}
const EditableCell = ({ title, editable, children, dataIndex, record, handleRowSave, handleRowDelete, className, ...restProps }) => {
  const isNewCell = record && record.newFolder
  const [editing, setEditing] = useState(!!isNewCell)
  const [dynamicClassName, setDynamicClassName] = useState(className)
  const inputRef = useRef()
  const form = useContext(EditableContext)
  let childNode = children

  useEffect(() => {
    if (editing) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      })
      inputRef.current.focus()
      setDynamicClassName(className.concat(' ant-table-row-editable'))
    } else {
      setDynamicClassName(className)
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)

    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async e => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleRowSave({ ...record, ...values })
    } catch (errInfo) {
      console.log( 'Save failed:', errInfo )
      if (errInfo.values.title.length > 80) return

      handleRowDelete(record)
    }
  }

  if (editable) {
    const childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {onClick: toggleEdit})
      }

      return child
    })

    childNode = editing ? (
      <>
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: REQUIRED_FIELD,
            },
            {
              max: 80,
              message: MAX_SYMBOLS_LIMIT_80,
            }
          ]}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            placeholder='Write a project name'
            className={styles.tableFolderInput}
          />
        </Form.Item>
      </>
    ) : (
      <span>
        {childrenWithProps}
      </span>
    )
  }

  return <td
    {...restProps}
    className={dynamicClassName}
  >{childNode}</td>
}

class TemplatesTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalVisible: false,
      folderLoading: false,
      openModalButtonId: null,
      downloadLoading: false,
      searchText: '',
      searchedColumn: '',
      templates: [],
      tableData: [],
      fullTableData: [],
      expandedRows: [],
      folderStartPosition: -1,
      dragOldIndex: null,
      dragNewIndex: null,
    }

    this.columns = [
      {
        title: () => {
          return (
            <div className={styles.groupActionWrapper}>
              <div className={styles.groupActionButtons}>
                <Button
                  size='large'
                  onClick={(e) => this.handleAddFolder()}
                  disabled={this.state.folderLoading}
                >
                  + New Project
                </Button>
              </div>
            </div>
          )
        },
        dataIndex: 'title',
        key: 'title',
        width: 700,
        editable: true,
        className: 'column-with-search-bar drag-visible',
        ...this.getColumnSearchProps('title'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 150,
        className: 'drag-visible',
        render: (text, record) => {
          if (!record || record.parentId >= 0) return

          return record.status > 0 ?
            <span className={styles.statusMarker}>
              New
            </span> :
            <span>
              Draft
            </span>
        }
      },
      {
        title: 'Last Updated',
        dataIndex: 'updated_at',
        key: 'updated_at',
        width: 220,
        className: 'drag-visible',
        render: (record) => {
          const dateString = record
          const D = new Date(dateString)
          const time = getTime12Format(D)
          const dateGMTString = D.toLocaleString('en-US', {timeZone: 'Africa/Abidjan'})
          const date = dateGMTString.replace(/:\d\d([ ap]|$)/, ' ').toLowerCase()

          return record ?
            date.replace(/,/g, ' at') :
            null
        }
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: 130,
        render: (text, record, index) => {
          const isFolder = record.parentId >= 0

          return <Space size='middle' align='end'>
            <Dropdown
              trigger={['click']}
              placement='bottomRight'
              overlay={() => this.moreActionsMenu(record)}
            >
              <Button
                size='large'
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </Space>
        },
      },
    ]
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.foldersEntities.length !== this.props.foldersEntities.length ||
      prevProps.templatesEntities.length !== this.props.templatesEntities.length
    ) {
      // Set tree structure
      console.log('Set tree structure...')

      const foldersArr = JSON.parse(JSON.stringify(this.props.foldersEntities))
      const templatesArr = JSON.parse(JSON.stringify(this.props.templatesEntities))
      this.props.getTemplatesNotifications()

      this.setState({
        templates: deepMergeArrays(foldersArr, templatesArr, 'key') // entitiesMergedArray // .map(el => el.toJS())
      }, () => {

        console.log(this.state.templates)

        this.setState({
          tableData: this.state.templates.map((item) => {
            // add templates to children array
            if (item.templates) {
              Object.keys(item.templates).map((key, index) => item.children.push(item.templates[key]))
            }

            if (item.children) {
              item.children.sort((a, b) => a.position - b.position)
            }

            return item
          }).sort((a, b) => a.position - b.position)
        }, () => {
          const fullDataArray = []

          console.log(this.state.tableData)

          this.state.tableData.map((item) => {
            fullDataArray.push(item)

            item.children &&
            item.children.map((item) => {
              fullDataArray.push(item)

              item.children &&
              item.children.map(item => fullDataArray.push(item))
            })
          })
          this.setState({
            fullTableData: fullDataArray,
            folderStartPosition: this.state.tableData.length > 0 ? this.state.tableData[0].position - 1 : -1,
          })
        })
        })
    }

    if (
      prevState.fullTableData.length !== 0 &&
      this.state.fullTableData.length !== 0 &&
      prevState.fullTableData !== this.state.fullTableData
    ) {
      // Setting tree structure on update
      console.log('fullTableData change...')
      console.log(this.state.fullTableData)
    }

    if (
      prevState.tableData.length === 0 && this.state.tableData.length > 0 &&
      prevState.expandedRows.length === 0 && this.state.expandedRows.length === 0 &&
      prevState.fullTableData === this.state.fullTableData
    ) {
      this.state.tableData.map((item) => {
        if (item.isOpen) {
          this.setState(prevState =>
            prevState.expandedRows.includes(item.index) ?
              null :
              {
                expandedRows: [...prevState.expandedRows, item.index]
              }
          )
        }
      })
    }

    if (
      prevState.fullTableData.length === 0 &&
      this.state.fullTableData.length > 0
    ) {
      this.setState({
        tableIndexArray: this.state.fullTableData.map(({ index }) => index)
      })
    }
  }

  componentWillUnmount() {
    message.destroy()
  }

  render() {
    return this.body
  }

  get body() {
    const { templatesLoading } = this.props
    const { downloadLoading } = this.state
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: record.parentId >= 0,
          dataIndex: col.dataIndex,
          title: col.title,
          handleRowSave: this.handleRowSave,
          handleRowDelete: this.handleRowDelete,
        })
      }
    })
    const tableComponents = {
      body: {
        wrapper: this.DraggableContainer,
        row: this.DraggableBodyRow,
        cell: EditableCell,
      },
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={styles.headButton}>
          <a
            className='ant-btn ant-btn-primary ant-btn-lg'
            href={`${SITE_DOMAIN}/catalog`}
            style={{
              paddingLeft: `10px`,
              paddingRight: `10px`
            }}
          >
            + New Contract
          </a>
        </div>
        <Table
          className={styles.table}
          loading={templatesLoading || downloadLoading}
          components={tableComponents}
          columns={columns}
          dataSource={this.state.tableData}
          rowClassName={record => this.setRowClassName(record)}
          rowKey='index'
          pagination={false}
          expandable={{
            expandedRowKeys: this.state.expandedRows,
            rowExpandable: record => record.parentId >= 0,
            expandIcon: ({ expanded, onExpand, record }) =>
              record.parentId >= 0 ?
                expanded ? (
                  <>
                    {record.new_objects_count ? <Badge count={record.new_objects_count}/> : null}
                    <DownOutlined onClick={e => onExpand(record, e)} />
                  </>
                ) : (
                  <>
                    {record.new_objects_count ? <Badge count={record.new_objects_count}/> : null}
                    <RightOutlined onClick={e => onExpand(record, e)} />
                  </>
                ) : record.is_new ?
                <Badge color='#602BF5' /> :
                null
          }}
          onExpand={(expanded, record) => this.handleRowExpand(expanded, record)}
        />
      </div>
    )
  }

  moreActionsMenu = (record) => {
    const isFolder = record.parentId >= 0

    return <Menu className={styles.dropDown}>
      {
        isFolder ?
          <>
            <Menu.Item key={record.id} onClick={() => this.handleDeleteFolder(record)} style={{ color: '#EB5757' }}>
              <DeleteOutlined color={'#EB5757'} />
              Delete folder
            </Menu.Item>
            <Menu.Item key={record.key} onClick={() => this.handleDeleteFolderGlobally(record)} style={{ color: '#EB5757' }}>
              <DeleteOutlined color={'#EB5757'} />
              Delete from dashboard
            </Menu.Item>
          </> :
          <>
            <Menu.Item key='000'>
              <EditOutlined />
              <Link to={`${PRODUCT_PAGE}/${record.id}/${record.wp_product_id}?edit`}>Edit Contract</Link>
            </Menu.Item>
            {
              record.isDownloadBlank &&
                <Menu.Item key='001' onClick={() => this.downloadClickHandler(record, true)}>
                  <DownloadOutlined />
                  Download Blank
                </Menu.Item>
            }
            {record.can_be_used &&
              <Menu.Item key='002' onClick={() => this.downloadClickHandler(record, false)}>
                <DownloadOutlined />
                Download
              </Menu.Item>
            }
            <Menu.Item key='003'>
              <CopyOutlined />
              <Link to={`${PRODUCT_PAGE}/${record.parent_template_id}/${record.wp_product_id}`} style={{whiteSpace: 'nowrap'}}>Buy Again</Link>
            </Menu.Item>
          </>
      }
    </Menu>
  }

  setRowClassName = (record) => {
    return record.parentId >= 0 ?
      'ant-table-row-with-children' :
      record.folder_id > 0 ?
        'ant-table-row-nested' : ''
  }

  handleRowExpand(expanded, record) {
    const {updateFolderOpenStatus} = this.props

    updateFolderOpenStatus({
      id: record.id,
      status: expanded
    })

    this.setState(prevState =>
      prevState.expandedRows.includes(record.index) ?
        {
          expandedRows: prevState.expandedRows.filter(
            key => key !== record.index
          )
        } :
        {
          expandedRows: [...prevState.expandedRows, record.index]
        }
    )
  }

  handleAddFolder = () => {
    const {tableData} = this.state
    const newTableData = [...this.state.tableData]
    const isFile = (element) => element.folder_id >= 0
    const isFileIndex = newTableData.findIndex(isFile)
    const newFolderData = {
      id: tableData.length > 0 ? tableData[0].id - 1 : 0,
      key: `${tableData.length > 0 ? tableData[0].id - 1 : 0}-${(~~(Math.random() * 1e8)).toString(16)}`,
      index: (~~(Math.random() * 1e8)).toString(16), // tableData.length > 0 ? isFileIndex + 1 : 0,
      parentId: 0,
      newFolder: true,
      title: '',
      children: [],
    }

    newTableData.splice(0, 0, {...newFolderData})

    this.setState({
      tableData: newTableData,
      folderLoading: true
    })
  }

  handleRowSave = row => {
    const newData = [...this.state.tableData]
    const newFullData = [...this.state.fullTableData]
    const index = newData.findIndex(item => row.key === item.key)
    const item = newData[index]
    const indexFull = newFullData.findIndex(item => row.key === item.key)
    const itemFull = newFullData[indexFull]
    const newFolderData = {
      title: row.title,
      chapter: 'TEMPLATE',
      parent_id: row.parentId,
      index: row.index,
      position: this.state.folderStartPosition,
    }
    const updateFolderData = {
      id: row.id,
      title: row.title,
      chapter: 'TEMPLATE'
    }

    newData.splice(index, 1, { ...item, ...row })
    newFullData.splice(indexFull, 1, { ...itemFull, ...row })

    this.setState({
      tableData: newData,
      fullTableData: newFullData
    }, () => {
      row.newFolder ?
        this.props.postFolder(newFolderData) :
        this.props.updateFolder(updateFolderData)

      this.setState((prevState) => ({
        folderStartPosition: prevState.folderStartPosition - 1,
        folderLoading: false
      }))

      this.handleRowExpand(false, row)
    })
  }

  handleRowDelete = row => {
    const newTableData = [...this.state.tableData]
    const index = newTableData.findIndex(item => row.key === item.key)

    index >= 0 && newTableData.splice(index, 1)

    this.setState({
      tableData: newTableData,
      folderLoading: false
    })
  }

  handleDeleteFolder = (record) => {
    const { deleteFolder } = this.props

    deleteFolder(record)
  }

  handleDeleteFolderGlobally = (record) => {
    const { deleteFolderGlobal } = this.props

    deleteFolderGlobal(record)
  }

  downloadClickHandler = (record, isBlank) => {
    const cookies = new Cookies()
    const token = cookies.get(TOKEN_COOKIE)
    const docTitle = `${isBlank ? record.title + '– Blank' : record.title}.pdf`
    let config = {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf',
      'wp-token': `${token}`,
    }
    const instance = axios.create({
      withCredentials: true,
    })

    this.setState({
      downloadLoading: true
    })

    // Use interceptor to inject the token to requests
    axios.interceptors.request.use(request => {
      request.headers['wp-token'] = getTokenCookies()

      return request
    })

    // Function that will be called to refresh authorization
    const refreshAuthLogic = failedRequest => instance.post(TOKEN_DOMAIN)
      .then(tokenRefreshResponse => {
        const token = tokenRefreshResponse.data.token

        setTokenCookies(token)
        // failedRequest.response.config.headers['wp-token'] = token

        return Promise.resolve()
      })

    createAuthRefreshInterceptor(axios, refreshAuthLogic)

    axios.get(`${API}template/${record.id}/${isBlank ? 'download_template_pdf' : 'download_filled_template_pdf'}`,
      {
        responseType: 'blob',
        headers: config
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', docTitle)
        document.body.appendChild(link)
        link.click()
        this.setState({
          downloadLoading: false
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          downloadLoading: false
        })
      })
  }

  cloneClickHandler = (record) => {
    const { cloneTemplate } = this.props

    console.log( record )
    cloneTemplate(record.parent_template_id)
  }

  // D`n`D
  DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      lockAxis='y'
      helperClass='row-dragging'
      useWindowAsScrollContainer
      transitionDuration={150}
      onSortEnd={this.onSortEnd}
      updateBeforeSortStart={this.updateBeforeSortStart}
      {...props}
    />
  )

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = this.state.fullTableData.findIndex(x => x.index === restProps['data-row-key'])

    for (const [key, value] of Object.entries(restProps)) {
      if (key === 'children' && Array.isArray(value)) {
        // console.log(value[1].props.record)
      }
    }

    return <EditableRow index={index} className={className} {...restProps} />
  }

  updateBeforeSortStart = ({node, index, collection, isKeySorting}, event) => {
    const {fullTableData, expandedRows} = this.state
    const record = fullTableData[index]
    const isFolder = record.parentId >= 0

    // console.log('BEFORE SORT S T A R T:')
    // console.log(node)
    // console.log(record)

    return new Promise((resolve) => {
      if (isFolder && expandedRows.includes(record.index)) {
        this.handleRowExpand(false, record)
      }

      resolve()
    })
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    const {tableData, fullTableData, expandedRows} = this.state
    const record = fullTableData[oldIndex]
    const isFolder = record.parentId >= 0

    console.log('ON SORT E N D:')
    console.log(oldIndex)
    console.log(newIndex)

    if (isFolder && !expandedRows.includes(record.index)) {
      this.handleRowExpand(true, record)
    }

    // if (oldIndex !== newIndex) {
      const newTableData = arrayMove([].concat(tableData), oldIndex, newIndex).filter(el => !!el)
      const newFullTableData = arrayMove([].concat(fullTableData), oldIndex, newIndex).filter(el => !!el)
      const newIndexArray = newFullTableData.map(({ index }) => index)

      // console.log('Sorted newData: ', newTableData)
      // console.log('Sorted fullTableData: ', newFullTableData)
      // console.log('Sorted indexes: ', newIndexArray)

      this.setState({
        // tableData: newTableData,
        fullTableData: newFullTableData,
        tableIndexArray: newIndexArray,
        dragOldIndex: oldIndex,
        dragNewIndex: newIndex
      }, () => {
        this.handleSortingTableChange()
      })
    // }
  }

  handleSortingTableChange = () => {
    let prevFolderId = null
    let folderId = null
    const deepCopyFullTableData = JSON.parse(JSON.stringify(this.state.fullTableData)).map((item) => {
      const isFolder = item.parentId >= 0

      // Clean tree structure
      if (isFolder) item.children = []

      return item
    })
    const filteredTableData = deepCopyFullTableData.map((item, i) => {
      const isFolder = item.parentId >= 0
      const isTemplate = item.folder_id >= 0
      const previousItem = deepCopyFullTableData[i - 1]
      const isPreviousFolder = previousItem && previousItem.parentId >= 0
      const isPreviousInFolder = previousItem && previousItem.folder_id > 0
      const nextItem = deepCopyFullTableData[i + 1]
      const isNextInFolder = nextItem && nextItem.folder_id > 0
      const newIndex = this.state.dragNewIndex

      // Move to Folder
      if (isTemplate && i === newIndex) {

        // Inside
        if (isPreviousFolder) {
          prevFolderId = item.folder_id
          item.folder_id = previousItem.id

          folderId = item.folder_id
          this.setState((prevState) => ({
            fullTableData: prevState.fullTableData.map((obj, index) => {
              if (obj.id === item.id) {
                return {
                  ...obj,
                  folder_id: previousItem.id
                }
              }

              return obj
            })
          }))
          console.log('IN: ', item)
        }
        else if (isPreviousInFolder) {
          prevFolderId = item.folder_id
          item.folder_id = previousItem.folder_id

          folderId = item.folder_id
          this.setState((prevState) => ({
            fullTableData: prevState.fullTableData.map((obj, index) => {
              if (obj.id === item.id) {
                return {
                  ...obj,
                  folder_id: previousItem.folder_id
                }
              }

              return obj
            })
          }))
          console.log('IN-0: ', item)
        }
        else if (isNextInFolder) {
          if (item.folder_id === 0) {
            prevFolderId = item.folder_id
            item.folder_id = nextItem.folder_id

            folderId = item.folder_id
            this.setState((prevState) => ({
              fullTableData: prevState.fullTableData.map((obj, index) => {
                if (obj.id === item.id) {
                  return {
                    ...obj,
                    folder_id: nextItem.folder_id
                  }
                }

                return obj
              })
            }))
            console.log('IN-2: ', item)
          }

          // Outside
        }
        else {
          if (item.folder_id !== 0) {
            prevFolderId = item.folder_id
            item.folder_id = 0
            folderId = item.folder_id
            this.setState((prevState) => ({
              fullTableData: prevState.fullTableData.map((obj, index) => {
                if (obj.id === item.id) {
                  return {
                    ...obj,
                    folder_id: 0
                  }
                }

                return obj
              })
            }))
            console.log('OUT: ', item)
          }
        }
      }

      // Setting tree structure
      if (item.folder_id > 0) {
        deepCopyFullTableData.forEach((template) => {
          const isFolder = template.parentId >= 0

          if (isFolder && template.id === item.folder_id) {
            template.children.push(item)
          }
        })
      }

      return item
    })
      // Remove templates that put inside folder
      .filter(item => item.folder_id === 0 || item.parentId >= 0)

    this.handlePostSortingData(folderId, prevFolderId, deepCopyFullTableData, filteredTableData)

    console.log('HANDLE SORTING C H A N G E:')
    console.log('FULL: ', deepCopyFullTableData )
    console.log('FILTERED: ', filteredTableData )

    prevFolderId = null
    folderId = null
    this.setState({
      tableData: filteredTableData
    })
  }

  handlePostSortingData = (folderId, prevFolderId, deepCopyFullTableData, filteredTableData) => {
    const {updatePosition} = this.props
    const {dragNewIndex} = this.state
    const dragTemplate = deepCopyFullTableData[dragNewIndex]
    const dragId = deepCopyFullTableData[dragNewIndex].id
    let folderData = []

    if (Number.isInteger(folderId)) {
      const folder = deepCopyFullTableData.filter(item => item.id === folderId)
      folderData = folder.length > 0 ?
        folder[0]['children'].map((item, i) => {
          return {
            id: item.id,
            type: 'template'
          }
        }) :
        filteredTableData.map((item, i) => {
          const isFolder = item.parentId >= 0

          return {
            id: item.id,
            type: isFolder ? 'folder' : 'template'
          }
        })
    } else {
      folderId = 0
      folderData = filteredTableData.map((item, i) => {
        const isFolder = item.parentId >= 0

        return {
          id: item.id,
          type: isFolder ? 'folder' : 'template'
        }
      })
    }

    updatePosition({
      id: folderId,
      data: folderData,
      dragId: dragId,
      prevFolderId: prevFolderId,
      dragTemplate: dragTemplate
    })
  }

  // Search
  getColumnSearchProps = dataIndex => ({
    filterDropdownVisible: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return <div className={styles.tableSearchWrapper}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({closeDropdown: false})
            this.setState({
              searchText: e.target.value,
              searchedColumn: dataIndex,
            })
          }}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          prefix={<SearchOutlined />}
          disabled={this.props.templatesLoading}
          placeholder='Search ...'
        />
        {selectedKeys.length > 0 &&
          <Button
            icon={<CloseOutlined/>}
            onClick={() => this.handleResetSearch(clearFilters)}
            aria-label='Reset'
            style={{zIndex: 99, width: 20}}
          />
        }
      </div>
    },
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record.children && record.children.length > 0 ?
        record.children.some(record => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())) :
        record[dataIndex] ?
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) :
          false
    },
    render: (text, record) => {
      const isFolder = record.parentId >= 0

      return <span>
        <DragHandle />
        {this.state.searchedColumn === dataIndex ?
          !isFolder ?
            <Link to={`${PRODUCT_PAGE}/${record.id}/${record.wp_product_id}?edit`}>
              <Highlighter
                autoEscape
                searchWords={[this.state.searchText]}
                highlightStyle={{ backgroundColor: '#FFE5BD', padding: `2px` }}
                textToHighlight={text ? text.toString() : ''}
              />
            </Link> :
            <Highlighter
              autoEscape
              searchWords={[this.state.searchText]}
              highlightStyle={{ backgroundColor: '#FFE5BD', padding: `2px` }}
              textToHighlight={text ? text.toString() : ''}
            /> :
          record.title ?
            !isFolder ?
              <Link to={`${PRODUCT_PAGE}/${record.id}/${record.wp_product_id}?edit`}>{record.title}</Link> :
            record.title :
            'Untitled'
        }
      </span>
    }
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  handleResetSearch = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }
}

export default connect(
  store => {
    return {
      foldersEntities: foldersTemplatesSelector(store),
      templatesEntities: templatesTemplatesSelector(store),
      templatesLoaded: loadedTemplatesSelector(store),
      templatesLoading: loadingTemplatesSelector(store),
      templatesError: errorTemplatesSelector(store),
      templatesMessage: messageTemplatesSelector(store),
      templateDataLoading: loadingTemplateDataSelector(store),
      templateDataLoaded: loadedTemplateDataSelector(store),
      templateUpdateLoading: updateLoadingTemplateDataSelector(store),
      templateUpdateLoaded: updateLoadedTemplateDataSelector(store),
      templateDataTitle: titleTemplateDataSelector(store),
      templateDataId: idTemplateDataSelector(store),
      templateDataFolderId: folderIdTemplateDataSelector(store),
      templatePostLoading: postLoadingTemplatesInProgressSelector(store),
      templatePostLoaded: postLoadedTemplatesInProgressSelector(store),
      templatesInProgressLoaded: loadedTemplatesInProgressSelector(store),
    }
  },
  {
    postFolder: postTemplatesFolder,
    updateFolder: updateTemplatesFolder,
    deleteFolder: deleteTemplatesFolder,
    deleteFolderGlobal: deleteTemplatesFolderGlobal,
    updatePosition: updateTemplatesPosition,
    cloneTemplate: cloneTemplatesContract,
    getTemplatesNotifications: getTemplatesNotifications,
    updateFolderOpenStatus: updateTemplatesFolderOpenStatus
  }
)(withRouter(TemplatesTable));
