import React, { useEffect } from 'react'
import { Dropdown, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { START_PAGE, LOGOUT_PAGE } from '../../constants/siteMap'
import { SITE_DOMAIN } from '../../constants/variables'
import { getUserData } from  '../../store/reducers/user/actions'
import { useMediaQuery } from '../utils'
import styles from './user.module.scss'



const User = ({ withClickHandler, ...props }) => {
  let isTablet = useMediaQuery('(max-width: 1024px)')
  const dispatch = useDispatch()
  const userName = useSelector(store => store.user.toJS().userName)
  const userEmail = useSelector(store => store.user.toJS().userEmail)
  const userLoaded = useSelector(store => store.user.toJS().loaded)

  useEffect(() => {
    !userEmail && !userLoaded && dispatch(getUserData())
  }, [])

  const menu = (
    <Menu>
      <Menu.Item key='edit'>
        <a href={`${SITE_DOMAIN}/profile/edit-account/`} onClick={withClickHandler}>Contact Information</a>
      </Menu.Item>
      <Menu.Item key='subscriptions'>
        <a href={`${SITE_DOMAIN}/profile/subscriptions/`} onClick={withClickHandler}>Subscriptions</a>
      </Menu.Item>
      <Menu.Item key='payment'>
        <a href={`${SITE_DOMAIN}/profile/payment-methods/`} onClick={withClickHandler}>Billing Information</a>
      </Menu.Item>
      <Menu.Item key='history'>
        <a href={`${SITE_DOMAIN}/profile/orders/`} onClick={withClickHandler}>Order History</a>
      </Menu.Item>
      <Menu.Item key='logout'>
        <Link to={LOGOUT_PAGE} onClick={withClickHandler}>Logout</Link>
      </Menu.Item>
    </Menu>
  )

  return <div className={styles.wrapper}>
    {
      !isTablet &&
        <nav className={styles.userNav}>
          {
            userName || userEmail ?
              <>
                <Link to={{pathname: START_PAGE, search: '?tab=drafts'}} className={styles.userNavCTA} onClick={withClickHandler}>Go to Briefcase</Link>
                <Dropdown
                  overlay={menu}
                  placement='bottomCenter'
                  trigger={['click', 'hover']}
                >
                  <a className={styles.userNavDropdown} onClick={e => e.preventDefault()} style={{ padding: `40px 0` }}>
                    My Account
                  </a>
                </Dropdown>
              </> :
              <>
                <a href={`${SITE_DOMAIN}/login`} onClick={withClickHandler}>Sign In</a>
                <a href={`${SITE_DOMAIN}/register`} className={styles.userNavCTA} onClick={withClickHandler}>Sign Up</a>
              </>
          }
        </nav>
    }
    </div>
}

export default User;
