import { List, OrderedMap, Record } from 'immutable'
import { arrToMap } from '../../utils'
import { START, SUCCESS, FAIL } from '../../../constants/actions'
import { VALIDATION_TEXT, PUBLISHING_TEXT, PROCESSING_COMPLETE_TEXT } from '../../../constants/staticTexts'
import { NOT_ALL_FIELDS_ERROR } from '../../../constants/staticErrors'
import * as types from './types'
import * as templatesTypes from '../templates/types'
import * as templatesInProgressTypes from '../templatesInProgress/types'
import * as templatesSentTypes from '../templatesSent/types'



const ContractRecord = Record({
  id: null,
  tmpId: null,
  status: null,
  title: null,
  categoryId: null,
  sku: null,
  partiesNumber: null,
  hsData: null,
  validationTypes: List([]),
  categories: List([]),
  parties: List([]),
  steps: OrderedMap({}),
  templates: OrderedMap({}),
  field: OrderedMap({}),
  contentPdf: null,
  canBeUsed: false,
  content: null,
  parentContent: null,
  isDownloadBlank: false,
  downloadBlankTitle: null,
  downloadBlankUrl: null,
  postLoading: false,
  postLoaded: false,
  getLoading: false,
  getLoaded: false,
  stepLoading: false,
  stepLoaded: false,
  loading: false,
  loaded: false,
  updated: false,
  error: null,
  message: null,
  notification: null,
})

const TemplateRecord = Record({
  id: null,
  key: null,
  title: null,
  status: null,
  category_id: null,
  parties_number: null,
  sku: null,
  updated_at: null,
  isDownloadBlank: false,
  downloadBlankUrl: null,
})

const StepRecord = Record({
  id: null,
  title: null,
  description: null,
  position: null,
  is_parties_data: null,
  fields: OrderedMap({}),
})

const FieldRecord = Record({
  id: null,
  original_id: null,
  title: null,
  party_number: null,
  position: null,
  tooltip: null,
  validation_error_text: null,
  maxlength: null,
  fieldlength: null,
  field_type: null,
  validation_type: null,
  is_required: 1,
  is_predefined: 0,
  val: null,
})

const templatesAdmin = (templatesAdmin = new ContractRecord(), action) => {
  const {type, response, error, payload} = action

  switch(type) {
    case types.GET_CONTRACT + START:
      return templatesAdmin
        .set('loading', true)

    case types.GET_CONTRACT + SUCCESS:
      const stepsMap = []

      response.data.template.steps &&
      response.data.template.steps.forEach((step) => {
        const stepData = {
          id: step.id,
          title: step.title,
          description: step.description,
          position: step.position,
          is_parties_data: step.is_parties_data,
          fields: OrderedMap({}),
        }

        if (step.fields && step.fields.length > 0) {
          const fieldsData = []

            step.fields.forEach((field) => {
              const fieldData = {
                id: field.id,
                original_id: field.original_id,
                position: field.position,
                title: field.title,
                party_number: field.party_number,
                is_required: field.is_required,
                field_type: field.field_type,
                fieldlength: field.fieldlength,
                val: field.val,
                tooltip: field.tooltip,
                maxlength: field.maxlength,
                is_predefined: field.is_predefined,
                validation_error_text: field.validation_error_text,
                validation_type: field.validation_type
              }

            fieldsData.push(fieldData)
          })

          stepData.fields = arrToMap(fieldsData, FieldRecord)
        }

        stepsMap.push(stepData)
      })

      return templatesAdmin
        .set('id', response.data.template.id)
        .set('tmpId', response.data.template.tmp_id)
        .set('status', response.data.template.status)
        .set('title', response.data.template.title)
        .set('categoryId', response.data.template.category_id)
        .set('sku', response.data.template.sku)
        .set('partiesNumber', response.data.template.parties_number)
        .set('content', response.data.template.content)
        .set('parentContent', response.data.template.parent_content ? response.data.template.parent_content : null)
        .set('contentPdf', response.data.template.content_pdf)
        .set('canBeUsed', response.data.template.can_be_used)
        .setIn(['steps'], arrToMap(stepsMap, StepRecord))
        .setIn(['parties'], response.data.template.parties ? List(response.data.template.parties) : List())
        .set('isDownloadBlank', response.data.template.download_template === 1)
        .set('downloadBlankTitle', response.data.template.download_file_name)
        .set('downloadBlankUrl', response.data.template.download_template_url)
        .set('loaded', true)
        .set('loading', false)
        .set('updated', false)

    case types.GET_CONTRACT + FAIL:
      return templatesAdmin
        .set('loading', false)
        .set('loaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.GET_CONTRACT_TITLE + START:
      return templatesAdmin
        .set('loading', true)

    case types.GET_CONTRACT_TITLE + SUCCESS:
      return templatesAdmin
        .set('title', response.data.template_title)
        .set('loaded', true)
        .set('loading', false)
        .set('error', response.errors ? response.errors : [])
        .set('message', response.errors.length > 0 ?
          response.errors[0] :
          null
        )

    case types.GET_CONTRACT_TITLE + FAIL:
      return templatesAdmin
        .set('loading', false)
        .set('loaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.GET_CONTRACTS_LIST + START:
      return templatesAdmin
        .set('loading', true)

    case types.GET_CONTRACTS_LIST + SUCCESS:
      const templatesData = []

      response.data.templates &&
      response.data.templates.forEach((template) => {
        const templateData = {
          id: template.id,
          key: `${template.id}-${(~~(Math.random() * 1e8)).toString(16)}`,
          title: template.title,
          status: template.status,
          category_id: template.category_id,
          parties_number: template.parties_number,
          sku: template.sku,
          updated_at: template.updated_at,
          isDownloadBlank: template.download_template === 1,
          downloadBlankUrl: template.download_template_url
        }

        templatesData.push(templateData)
      })

      return templatesAdmin
        .setIn(['templates'], arrToMap(templatesData, TemplateRecord))
        .set('loaded', true)
        .set('loading', false)
        .set('error', null)
        .set('message', null)
        .set('notification', null)

    case types.GET_CONTRACTS_LIST + FAIL:
      return templatesAdmin
        .set('templates', OrderedMap({}))
        .set('loading', false)
        .set('loaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.CREATE_CONTRACT_DRAFT + START:
      return templatesAdmin
        .set('loading', true)

    case types.CREATE_CONTRACT_DRAFT + SUCCESS:
      const steps = []

      response.data.template.steps &&
      response.data.template.steps.forEach((step) => {
        const stepData = {
          id: step.id,
          title: step.title,
          description: step.description,
          position: step.position,
          is_parties_data: step.is_parties_data,
          fields: OrderedMap({}),
        }

        if (step.fields && step.fields.length > 0) {
          const fieldsData = []

            step.fields.forEach((field) => {
              const fieldData = {
                id: field.id,
                original_id: field.original_id,
                position: field.position,
                title: field.title,
                party_number: field.party_number,
                is_required: field.is_required,
                field_type: field.field_type,
                fieldlength: field.fieldlength,
                val: field.val,
                tooltip: field.tooltip,
                maxlength: field.maxlength,
                is_predefined: field.is_predefined,
                validation_error_text: field.validation_error_text,
                validation_type: field.validation_type,
              }

            fieldsData.push(fieldData)
            stepData.fields = arrToMap(fieldsData, FieldRecord)
          })
        }

        steps.push(stepData)
      })

      return templatesAdmin
        .set('id', response.data.template.id)
        .set('tmpId', response.data.template.tmp_id)
        .set('categoryId', response.data.template.category_id)
        .set('title', response.data.template.title)
        .set('sku', response.data.template.sku)
        .set('partiesNumber', 2)
        .set('canBeUsed', response.data.template.can_be_used)
        .set('content', null)
        .setIn(['steps'], arrToMap(steps, StepRecord))
        .setIn(['parties'], response.data.template.parties ? List(response.data.template.parties) : List())
        .set('loaded', true)
        .set('loading', false)

    case types.CREATE_CONTRACT_DRAFT + FAIL:
      return templatesAdmin
        .set('loading', false)
        .set('loaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.CREATE_CONTRACT_USER_DRAFT + START:
      return templatesAdmin
        .set('postLoading', true)
        .setIn(['steps'], OrderedMap({}))

    case types.CREATE_CONTRACT_USER_DRAFT + SUCCESS:
      const stepsArray = []

      response.data.template.steps &&
      response.data.template.steps.forEach((step) => {
        const stepData = {
          id: step.id,
          title: step.title,
          description: step.description,
          position: step.position,
          is_parties_data: step.is_parties_data,
          fields: OrderedMap({}),
        }

        if (step.fields && step.fields.length > 0) {
          const fieldsData = []

          step.fields.forEach((field) => {
            const fieldData = {
              id: field.id,
              original_id: field.original_id,
              position: field.position,
              title: field.title,
              party_number: field.party_number,
              is_required: field.is_required,
              field_type: field.field_type,
              fieldlength: field.fieldlength,
              val: field.val,
              tooltip: field.tooltip,
              maxlength: field.maxlength,
              is_predefined: field.is_predefined,
              validation_error_text: field.validation_error_text,
              validation_type: field.validation_type
            }

            fieldsData.push(fieldData)
            stepData.fields = arrToMap(fieldsData, FieldRecord)
          })
        }

        stepsArray.push(stepData)
      })

      return templatesAdmin
        .set('id', response.data.template.id)
        .set('tmpId', response.data.template.tmp_id)
        .set('title', response.data.template.title)
        .set('sku', response.data.template.sku)
        .set('contentPdf', response.data.template.content_pdf)
        .set('partiesNumber', response.data.template.parties_number)
        .set('canBeUsed', response.data.template.can_be_used)
        .set('content', response.data.template.content)
        .setIn(['steps'], arrToMap(stepsArray, StepRecord))
        .setIn(['parties'], response.data.template.parties ? List(response.data.template.parties) : List())
        .set('postLoaded', true)
        .set('postLoading', false)

    case types.CREATE_CONTRACT_USER_DRAFT + FAIL:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.UNDRAFT_CONTRACT + START:
      return templatesAdmin
        .set('stepLoading', true)

    case types.UNDRAFT_CONTRACT + SUCCESS:
      return templatesAdmin
        .set('stepLoading', false)

    case types.UNDRAFT_CONTRACT + FAIL:
      return templatesAdmin
        .set('stepLoading', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.CLONE_CONTRACT + START:
      return templatesAdmin
        .set('loading', true)
        .set('updated', false)

    case types.CLONE_CONTRACT + SUCCESS:
      const tmplData = [{
        id: response.data.template.id,
        key: `${response.data.template.id}-${(~~(Math.random() * 1e8)).toString(16)}`,
        title: response.data.template.title,
        status: response.data.template.status,
        category_id: response.data.template.category_id,
        parties_number: response.data.template.parties_number,
        sku: response.data.template.sku,
        updated_at: response.data.template.updated_at,
        isDownloadBlank: response.data.template.download_template === 1,
        downloadBlankUrl: response.data.template.download_template_url
      }]

      return templatesAdmin
        .set('id', response.data.template.id)
        .set('title', response.data.template.title)
        .set('sku', response.data.template.sku)
        .set('partiesNumber', response.data.template.parties_number)
        .setIn(['parties'], response.data.template.parties ? List(response.data.template.parties) : List())
        .mergeIn(['templates'], arrToMap(tmplData, TemplateRecord))
        .set('loading', false)
        .set('updated', true)

    case types.CLONE_CONTRACT + FAIL:
      return templatesAdmin
        .set('loading', false)
        .set('updated', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.DELETE_CONTRACT + START:
      return templatesAdmin
        .set('id', null)
        .set('title', null)
        .set('sku', null)
        .set('loading', true)
        .set('updated', false)

    case types.DELETE_CONTRACT + SUCCESS:
      return templatesAdmin
        .set('loading', false)
        .set('updated', true)
        .deleteIn(['templates', payload])

    case types.DELETE_CONTRACT + FAIL:
      return templatesAdmin
        .set('loading', false)
        .set('updated', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.CREATE_CONTRACT + START:
      return templatesAdmin
        .set('postLoading', true)

    case types.CREATE_CONTRACT + SUCCESS:
      return templatesAdmin
        .set('id', response.data.template_id)
        .set('title', payload.title)
        .set('categoryId', payload.category_id)
        .set('sku', payload.sku)
        .set('partiesNumber', payload.parties_number)
        .set('postLoaded', true)
        .set('postLoading', false)

    case types.CREATE_CONTRACT + FAIL:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.UPDATE_CONTRACT + START:
      return templatesAdmin
        .set('postLoading', true)
        .set('error', null)
        .set('message', null)

    case types.UPDATE_CONTRACT + SUCCESS:
      const stepsArr = []

      response.data.steps &&
      response.data.steps.forEach((step) => {
        const stepData = {
          id: step.id,
          title: step.title,
          description: step.description,
          position: step.position,
          is_parties_data: step.is_parties_data,
          fields: OrderedMap({}),
        }

        if (step.fields && step.fields.length > 0) {
          const fieldsData = []

            step.fields.forEach((field) => {
              const fieldData = {
                id: field.id,
                original_id: field.original_id,
                position: field.position,
                title: field.title,
                party_number: field.party_number,
                is_required: field.is_required,
                field_type: field.field_type,
                fieldlength: field.fieldlength,
                val: field.val,
                tooltip: field.tooltip,
                maxlength: field.maxlength,
                is_predefined: field.is_predefined,
                validation_error_text: field.validation_error_text,
                validation_type: field.validation_type
              }

            fieldsData.push(fieldData)

            // if (step.is_parties_data === 1) {
            //   stepData.fields = fieldsData
            // } else {
            //   stepData.fields = arrToMap(fieldsData, FieldRecord)
            // }

            stepData.fields = arrToMap(fieldsData, FieldRecord)
          })
        }

        stepsArr.push(stepData)
      })

      return response.errors.length > 0 ?
        templatesAdmin
          .set('postLoaded', false)
          .set('postLoading', false)
          .set('error', response.errors ? response.errors : [])
          .set('message', response.errors.length > 0 ?
            response.errors[0] :
            null
          ) :
        templatesAdmin
          .set('title', payload.title)
          .set('categoryId', payload.category_id)
          .set('sku', payload.sku)
          .set('partiesNumber', payload.parties_number)
          .setIn(['parties'], response.data.parties ? List(response.data.parties) : List())
          .setIn(['steps'], arrToMap(stepsArr, StepRecord))
          .set('postLoaded', true)
          .set('postLoading', false)
          .set('error', null)
          .set('message', null)

    case types.UPDATE_CONTRACT + FAIL:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.UPDATE_CONTRACT_CONTENT_ADMIN + START:
      return templatesAdmin
        .set('postLoading', true)

    case types.UPDATE_CONTRACT_CONTENT_ADMIN + SUCCESS:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', true)
        .set('content', payload.content)

    case types.UPDATE_CONTRACT_CONTENT_ADMIN + FAIL:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.UPDATE_CONTRACT_CONTENT + START:
      return templatesAdmin
        .set('postLoading', true)
        .set('stepLoaded', false)

    case types.UPDATE_CONTRACT_CONTENT + SUCCESS:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', true)
        .set('stepLoaded', true)
    // .set('content', payload.content)
    // .set('error', null)
    // .set('message', null)

    case types.UPDATE_CONTRACT_CONTENT + FAIL:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('stepLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.GET_CONTRACT_CATEGORIES + START:
      return templatesAdmin
        .set('loading', true)

    case types.GET_CONTRACT_CATEGORIES + SUCCESS:
      const categoriesData = response.data.categories ? response.data.categories : null

      return templatesAdmin
        .set('loading', false)
        .setIn(['categories'], categoriesData ? List(categoriesData) : List())

    case types.GET_CONTRACT_CATEGORIES + FAIL:
      return templatesAdmin
        .set('loading', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.POST_CONTRACT_STEP + START:
      return templatesAdmin
        .set('stepLoading', true)

    case types.POST_CONTRACT_STEP + SUCCESS:
      const stepData = {
        id: response.data.step_id,
        title: payload.title,
        description: payload.description,
        is_parties_data: 0,
        fields: OrderedMap({}),
      }

      return templatesAdmin
        .mergeIn(['steps'], arrToMap([stepData], StepRecord))
        .set('stepLoaded', true)
        .set('stepLoading', false)

    case types.POST_CONTRACT_STEP + FAIL:
      return templatesAdmin
        .set('stepLoading', false)
        .set('stepLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.DELETE_CONTRACT_STEP + START:
      return templatesAdmin
        .set('stepLoading', true)

    case types.DELETE_CONTRACT_STEP + SUCCESS:
      return templatesAdmin
        .set('stepLoading', false)
        .deleteIn(['steps', payload.id])

    case types.DELETE_CONTRACT_STEP + FAIL:
      return templatesAdmin
        .set('stepLoading', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.UPDATE_CONTRACT_STEP + START:
      return templatesAdmin
        .set('stepLoading', true)

    case types.UPDATE_CONTRACT_STEP + SUCCESS:
      return templatesAdmin
        .updateIn(['steps', payload.stepId],
          (val) => {
            return val
              .set('title', payload.data.title)
              .set('description', payload.data.description)
          })
        .set('stepLoaded', true)
        .set('stepLoading', false)

    case types.UPDATE_CONTRACT_STEP + FAIL:
      return templatesAdmin
        .set('stepLoading', false)
        .set('stepLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.UPDATE_CONTRACT_STEP_POSITION + START:
      return templatesAdmin
        .set('updated', false)

    case types.UPDATE_CONTRACT_STEP_POSITION + SUCCESS:
      return templatesAdmin
        .set('updated', true)
        .updateIn(['steps', payload.stepId],
          (val) => {
            return val
              .set('position', payload.data.position)
          })

    case types.UPDATE_CONTRACT_STEP_POSITION + FAIL:
      return templatesAdmin
        .set('updated', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.POST_CONTRACT_FIELD + START:
      return templatesAdmin
        .set('stepLoading', true)

    case types.POST_CONTRACT_FIELD + SUCCESS:
      const partyNumber = payload.data.party_number
      const newField = {
        id: response.data.field_id,
        title: payload.data.title,
        party_number: partyNumber,
        is_deletable: true,
        is_predefined: 0,
        maxlength: payload.data.maxlength,
        fieldlength: payload.data.fieldlength,
        field_type: payload.data.field_type,
        tooltip: payload.data.tooltip,
        validation_error_text: payload.data.validation_error_text,
        validation_type: payload.data.validation_type,
        is_required: payload.data.is_required,
      }

      return templatesAdmin
        .mergeIn(['parties', partyNumber], [newField])
        .mergeIn(['steps', payload.stepId, 'fields'], arrToMap([newField], FieldRecord))
        .set('stepLoaded', true)
        .set('stepLoading', false)
        .set('error', null)
        .set('message', null)

    case types.POST_CONTRACT_FIELD + FAIL:
      return templatesAdmin
        .set('stepLoading', false)
        .set('stepLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.GET_CONTRACT_FIELD_DATA + START:
      return templatesAdmin
        .set('getLoading', true)
        .set('field', OrderedMap({}))

    case types.GET_CONTRACT_FIELD_DATA + SUCCESS:
      const fieldData = response.data.field
      const field = [{
        id: fieldData.id,
        position: fieldData.position,
        title: fieldData.title,
        party_number: fieldData.party_number,
        tooltip: fieldData.tooltip,
        validation_error_text: fieldData.validation_error_text,
        maxlength: fieldData.maxlength > 0 ? fieldData.maxlength : null,
        fieldlength: fieldData.fieldlength,
        field_type: fieldData.field_type,
        validation_type: fieldData.validation_type,
        is_required: fieldData.is_required,
        is_predefined: fieldData.is_predefined,
        val: fieldData.val
      }]

      return templatesAdmin
        .set('getLoading', false)
        .set('getLoaded', true)
        .setIn(['field'], arrToMap(field, FieldRecord))

    case types.GET_CONTRACT_FIELD_DATA + FAIL:
      return templatesAdmin
        .set('getLoading', false)
        .set('getLoaded', false)
        .set('field', OrderedMap({}))
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.UPDATE_CONTRACT_FIELD + START:
      return templatesAdmin
        .set('stepLoading', true)

    case types.UPDATE_CONTRACT_FIELD + SUCCESS:
      return templatesAdmin
        .updateIn(['parties', payload.data.party_number, payload.data.field_index],
          (val) => {
            if (!Number.isInteger(payload.data.party_number)) return null

            val.title = payload.data.title

            return val
          })
        .updateIn(['steps', payload.stepId, 'fields', payload.fieldId],
          (val) => {
            return val
              .set('title', payload.data.title)
              .set('tooltip', payload.data.tooltip)
              .set('validation_error_text', payload.data.validation_error_text)
              .set('maxlength', payload.data.maxlength)
              .set('fieldlength', payload.data.fieldlength)
              .set('field_type', payload.data.field_type)
              .set('validation_type', payload.data.validation_type)
              .set('is_required', payload.data.is_required)
          })
        .set('stepLoaded', true)
        .set('stepLoading', false)
        .set('error', null)
        .set('message', null)

    case types.UPDATE_CONTRACT_FIELD + FAIL:
      return templatesAdmin
        .set('stepLoading', false)
        .set('stepLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.DELETE_CONTRACT_FIELD + START:
      return templatesAdmin
        .set('stepLoading', true)

    case types.DELETE_CONTRACT_FIELD + SUCCESS:
      return payload.fieldData ?
        templatesAdmin
          .set('stepLoading', false)
          .set('stepLoaded', true)
          .deleteIn(['parties', payload.fieldData.fieldPartyNumber, payload.fieldData.fieldIndex]) :
        templatesAdmin
          .set('stepLoading', false)
          .set('stepLoaded', true)
          .deleteIn(['steps', payload.stepId, 'fields', payload.fieldId])

    case types.DELETE_CONTRACT_FIELD + FAIL:
      return templatesAdmin
        .set('stepLoading', false)
        .set('stepLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.UPDATE_CONTRACT_FIELD_POSITION + START:
      return templatesAdmin
        .set('updated', false)

    case types.UPDATE_CONTRACT_FIELD_POSITION + SUCCESS:
      return payload.parties ?
        templatesAdmin.setIn(['parties'], List(payload.parties)) :
        templatesAdmin.set('updated', true)

    case types.UPDATE_CONTRACT_FIELD_POSITION + FAIL:
      return templatesAdmin
        .set('updated', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.MOVE_CONTRACT_FIELD_POSITION + START:
      return templatesAdmin

    case types.MOVE_CONTRACT_FIELD_POSITION + SUCCESS:
      const movedStep = {
        id: payload.fieldId,
        title: payload.data.title,
        party_number: null,
        is_deletable: true
      }

      return templatesAdmin
        .deleteIn(['steps', payload.stepId, 'fields', payload.fieldId])
        .mergeIn(['steps', payload.data.new_step_id, 'fields'], arrToMap([movedStep], FieldRecord))

    case types.MOVE_CONTRACT_FIELD_POSITION + FAIL:
      return templatesAdmin
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.GET_CONTRACT_STEPS + START:
      return templatesAdmin

    case types.GET_CONTRACT_STEPS + SUCCESS:
      const responseSteps = []

      response.data.steps &&
      response.data.steps.forEach((step) => {
        const stepData = {
          id: step.id,
          title: step.title,
          description: step.description,
          position: step.position,
          is_parties_data: step.is_parties_data,
          fields: OrderedMap({}),
        }

        if (step.fields && step.fields.length > 0) {
          const fieldsData = []

          step.fields.forEach((field) => {
            const fieldData = {
              id: field.id,
              original_id: field.original_id,
              position: field.position,
              title: field.title,
              party_number: field.party_number,
              is_required: field.is_required,
              field_type: field.field_type,
              fieldlength: field.fieldlength,
              val: field.val,
              tooltip: field.tooltip,
              maxlength: field.maxlength,
              is_predefined: field.is_predefined,
              validation_error_text: field.validation_error_text,
              validation_type: field.validation_type,
            }

            fieldsData.push(fieldData)
          })

          stepData.fields = arrToMap(fieldsData, FieldRecord)
        }

        responseSteps.push(stepData)
      })

      return templatesAdmin
        .setIn(['steps'], arrToMap(responseSteps, StepRecord))

    case types.GET_CONTRACT_STEPS + FAIL:
      return templatesAdmin

    case types.GET_FIELD_TYPES + START:
      return templatesAdmin

    case types.GET_FIELD_TYPES + SUCCESS:
      const fieldsVal = response.map((item) => {
        return {
          value: item.id,
          label: item.title
        }
      })

      return templatesAdmin
        .setIn(['validationTypes'], List(fieldsVal))

    case types.GET_FIELD_TYPES + FAIL:
      return templatesAdmin

    case types.GET_CONTRACT_VALIDATION + START:
      return templatesAdmin
        .set('postLoading', true)
        .set('error', null)
        .set('message', null)
        .set('notification', VALIDATION_TEXT)

    case types.GET_CONTRACT_VALIDATION + SUCCESS:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', true)
        .set('notification', '...')
        .set('error', response.errors ? response.errors : [])
        .set('message', response.errors.length > 0 ?
          response.errors[0] :
          null
        )

    case types.GET_CONTRACT_VALIDATION + FAIL:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('notification', null)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.PUBLISH_CONTRACT + START:
      return templatesAdmin
        .set('postLoading', true)
        .set('notification', PUBLISHING_TEXT)

    case types.PUBLISH_CONTRACT + SUCCESS:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', true)
        .set('notification', response.errors.length === 0 ? PROCESSING_COMPLETE_TEXT : null)
        .set('error', response.errors ? response.errors : [])
        .set('message', response.errors.length > 0 ?
          response.errors[0] :
          null
        )

    case types.PUBLISH_CONTRACT + FAIL:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('notification', null)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.SEND_CONTRACT + START:
      return templatesAdmin
        .set('postLoading', true)
        .set('postLoaded', false)

    case types.SEND_CONTRACT + SUCCESS:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', true)
        .set('error', response.data ? response.data : response.errors ? response.errors : [])
        .set('message', response.errors.length > 0 ?
          response.errors[0] :
          response.messages.length > 0 ?
            response.messages[0] :
            null
        )

    case types.SEND_CONTRACT + FAIL:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.GET_CONTRACT_SIGN_DATA + START:
      return templatesAdmin
        .set('loading', true)
        .set('error', null)
        .set('message', null)

    case types.GET_CONTRACT_SIGN_DATA + SUCCESS:
      return templatesAdmin
        .set('loading', false)
        .set('hsData', Object.keys(response.data).length > 0 ?
          response.data :
          response.redirect ?
            response.redirect :
          null
        )
        .set('error', response.errors ? response.errors : [])
        .set('message', response.errors.length > 0 ?
          response.errors[0] :
          response.messages.length > 0 ?
            response.messages[0] :
            null
        )

    case types.GET_CONTRACT_SIGN_DATA + FAIL:
      return templatesAdmin
        .set('loading', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.POST_USER_FIELDS + START:
      return templatesAdmin
        .set('postLoading', true)
        .set('error', [])
        .set('message', '')
        .set('notification', 'Saving...')

    case types.POST_USER_FIELDS + SUCCESS:
      return templatesAdmin
        .set('postLoaded', true)
        .set('postLoading', false)
        .set('error', response.errors.length > 0 ? response.errors : [])
        .set('message',
          response.errors.length > 0 ?
            response.errors[0].includes('Value is required') || response.errors[0] === 'The number of fields does not match' ?
              NOT_ALL_FIELDS_ERROR :
              response.errors[0] :
            ''
        )
        .set('notification',
          response.errors.length < 1 ?
            'Great job! Data is saved.' :
            ''
        )

    case types.POST_USER_FIELDS + FAIL:
      return templatesAdmin
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)
        .set('notification', null)

    case types.UPDATE_CONTRACT_SIGN_STATUS + START:
      return templatesAdmin
        .set('loading', true)
        .set('updated', false)
        .set('message', '')

    case types.UPDATE_CONTRACT_SIGN_STATUS + SUCCESS:
      return templatesAdmin
        .set('loaded', true)
        .set('loading', false)
        .set('updated', true)
        .set('error', response.errors.length > 0 ? response.errors : [])
        .set('message',
          response.errors.length > 0 ?
            response.errors[0] :
            ''
        )

    case types.UPDATE_CONTRACT_SIGN_STATUS + FAIL:
      return templatesAdmin
        .set('loading', false)
        .set('loaded', false)
        .set('updated', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.ATTACH_CONTRACT + START:
      return templatesAdmin

    case types.ATTACH_CONTRACT + SUCCESS:
      return templatesAdmin

    case types.ATTACH_CONTRACT + FAIL:
      return templatesAdmin

    case types.DELETE_CONTRACT_BLANK_PDF + START:
      return templatesAdmin

    case templatesTypes.GET_TEMPLATES + START:
    case templatesInProgressTypes.GET_PROGRESS_TEMPLATES + START:
    case templatesSentTypes.GET_SENT_TEMPLATES + START:
      return templatesAdmin
        .set('id', null)
        .set('tmpId', null)
        .set('status', null)
        .set('title', null)
        .set('categoryId', null)
        .set('sku', null)
        .set('partiesNumber', null)
        .set('hsData', null)
        .set('validationTypes', List([]))
        .set('categories', List([]))
        .set('parties', List([]))
        .set('steps', OrderedMap({}))
        .set('templates', OrderedMap({}))
        .set('field', OrderedMap({}))
        .set('contentPdf', null)
        .set('canBeUsed', false)
        .set('content', null)
        .set('parentContent', null)
        .set('isDownloadBlank', false)
        .set('downloadBlankTitle', null)
        .set('downloadBlankUrl', null)
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('getLoading', false)
        .set('getLoaded', false)
        .set('loading', false)
        .set('loaded', false)
        .set('error', null)
        .set('message', null)
        .set('notification', null)
        .set('stepLoading', false)
        .set('stepLoaded', false)
        .set('updated', false)

    case types.RESET_ERRORS:
      return templatesAdmin
        .set('error', null)
        .set('message', null)
        .set('notification', null)

    default:
      return templatesAdmin
  }
}

export default templatesAdmin;
