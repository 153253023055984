import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import templateData from './templateData/reducer'
import templates from './templates/reducer'
import templatesInProgress from './templatesInProgress/reducer'
import templatesSent from './templatesSent/reducer'
import templatePdf from './templatePdf/reducer'
import notifications from './notifications/reducer'
import user from './user/reducer'
import templatesAdmin from './templatesAdmin/reducer'



const rootReducer = (history) => combineReducers({
  templates: templates,
  templatesInProgress: templatesInProgress,
  templatesSent: templatesSent,
  templateData: templateData,
  templatePdf: templatePdf,
  templatesAdmin: templatesAdmin,
  notifications: notifications,
  user: user,
  router: connectRouter(history)
})

export default rootReducer;
