import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { message, Spin, Alert, Button, Modal } from 'antd'

import PageWrapper from '../components/PageWrapper'
import Product from '../components/Product'

import {
  loadingContractSelector,
  loadedContractSelector,
  errorContractSelector,
  messageContractSelector,
  titleContractSelector,
  idContractSelector
} from '../store/reducers/templatesAdmin/selectors'
import {
  getAdminTemplate,
  getTemplateTitle
} from '../store/reducers/templatesAdmin/actions'
import {
  emailUserSelector,
  loadedUserSelector,
  loadingUserSelector,
  nameUserSelector
} from '../store/reducers/user/selectors'
import { SITE_DOMAIN } from '../constants/variables'
import { BEFORE_LEAVE_PAGE_TITLE, BEFORE_LEAVE_PAGE_AUTH_TEXT, BEFORE_LEAVE_PAGE_NO_AUTH_TEXT } from '../constants/staticTexts'



class ProductPage extends Component {
  state = {
    error: null,
    errorInfo: null,
    isModalVisible: false,
    activeElement: null,
    isStartScreen: true,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error, errorInfo})
  }

  componentWillUnmount() {
    // window.removeEventListener('beforeunload', (event) => event.preventDefault())
    document.body.classList.remove('product')
    message.destroy()
  }

  componentDidMount() {
    const {contractId, getTemplate, getTemplateTitle} = this.props
    const locationContractId = this.props.match.params.ContractId
    const isEditPage = this.props.location && this.props.location.search && this.props.location.search.includes('edit')

    document.body.classList.add('product')
    window.scrollTo(0, 0)

    isEditPage ?
      getTemplate(locationContractId, true) :
      getTemplateTitle(locationContractId)

    // window.addEventListener('beforeunload', (event) => event.preventDefault())
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { productError, productMessage } = this.props

    if (prevProps.productError !== productError) {
      message.destroy()
      productMessage && message.error({
        content: productMessage,
        className: 'error-message',
        duration: 0
      })
    }
  }

  render() {
    const {productTitle, productLoading, productLoaded, userLoading, userLoaded, userEmail, userName} = this.props
    const {error, errorInfo, isModalVisible, activeElement, isStartScreen} = this.state
    const locationContractId = this.props.match.params.ContractId
    const locationProductId = this.props.match.params.ProductId
    const isEditPage = this.props.location && this.props.location.search ? this.props.location.search.includes('edit') : false

    if (error) {
      return <PageWrapper className='briefcase'>
        <Alert
          type='error'
          message={error}
          description={errorInfo}
        />
      </PageWrapper>
    }

    if (userLoading && !userLoaded) {
      return <div className='loader-wrapper'>
        <Spin size='middle' />
      </div>
    }

    if (productLoading && !productLoaded) {
      return <PageWrapper className='briefcase'>
        <div className='loader-wrapper'>
          <Spin size='middle' />
        </div>
      </PageWrapper>
    }

    return <PageWrapper className='product-page' withClickHandler={!isStartScreen ? this.handleBeforeLeaveAction : undefined}>
      <Helmet>
        <title>{productTitle ? productTitle : 'Product'} - Creators Legal</title>
      </Helmet>
      <Product
        templateId={locationContractId}
        productId={locationProductId}
        setIsStartScreen={this.toggleStartScreen}
        isStartScreen={isStartScreen}
        isEditPage={isEditPage}
      />
      <Modal
        visible={isModalVisible}
        title={BEFORE_LEAVE_PAGE_TITLE}
        centered={true}
        keyboard={true}
        maskClosable={true}
        className='ant-modal-terms ant-modal-leave'
        onCancel={this.hideModal.bind(this, { triggerCancel: true })}
        footer={[
          <Button
            key='cancel'
            onClick={this.hideModal}
          >
            No
          </Button>,
          <Button
            key='agree'
            type='primary'
            href={activeElement && activeElement.href ? activeElement.href : SITE_DOMAIN}
          >
            Yes
          </Button>
        ]}
      >
        <p>
          {
            userEmail || userName ?
              BEFORE_LEAVE_PAGE_AUTH_TEXT :
              BEFORE_LEAVE_PAGE_NO_AUTH_TEXT
          }
        </p>
      </Modal>
    </PageWrapper>
  }

  handleBeforeLeaveAction = (event) => {
    this.setState({
      activeElement: event ? event.target : null,
    }, () => {
      this.showModal()
    })

    event.preventDefault()

    return false
  }

  showModal = () => {
    this.setState({
      isModalVisible: true
    })
  }

  hideModal = () => {
    this.setState({
      isModalVisible: false
    })
  }

  toggleStartScreen = (bool) => {
    this.setState({
      isStartScreen: bool
    })
  }
}

export default connect(
  store => {
    return {
      contractId: idContractSelector(store),
      productTitle: titleContractSelector(store),
      productLoading: loadingContractSelector(store),
      productLoaded: loadedContractSelector(store),
      productError: errorContractSelector(store),
      productMessage: messageContractSelector(store),
      userEmail: emailUserSelector(store),
      userName: nameUserSelector(store),
      userLoading: loadingUserSelector(store),
      userLoaded: loadedUserSelector(store),
    }
  },
  {
    getTemplateTitle: getTemplateTitle,
    getTemplate: getAdminTemplate,
  }
)(withRouter(ProductPage));
