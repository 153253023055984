import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import { Button, DatePicker, Form, Input, Tabs, Tooltip, Modal, Spin } from 'antd'
import { ExportOutlined, QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons'

import { postUserFields, updateContractContent, deleteContract } from '../../store/reducers/templatesAdmin/actions'
import { getTokenCookies, setTokenCookies } from '../../store/utils'
import ProductDynamicForm from './ProductDynamicForm'
import PDFViewer from '../PDFViewer'
import DocumentPreviewer from '../DocumentPreviewer'
import styles from './product.module.scss'

import { EMAILS_MATCH_ERROR, FORM_VALIDATE_MESSAGES } from '../../constants/staticErrors'
import {
  PRODUCT_STEP_NO_DATA,
  BEFORE_EXIT_TITLE,
  BEFORE_EXIT_TEXT,
  TERMS_TITLE,
} from '../../constants/staticTexts'
import { API, WP_PRICING_PAGE, SITE_DOMAIN, TOKEN_DOMAIN, LOGIN_PAGE, APP_DOMAIN } from '../../constants/variables'
import { START_PAGE, THANK_YOU_PAGE } from '../../constants/siteMap'



const ProductStep = ({step, nextStep, prevStep, isNextStep, isLastStep, currentStep, handleStepsChange, handleDataUpdate, setValidationError, isDataUpdate, productId, ...props}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const {TabPane} = Tabs
  const {TextArea} = Input
  const token = getTokenCookies()

  const userEmail = useSelector(store => store.user.userEmail)
  const contractId = useSelector(store => store.templatesAdmin.id)
  const contractTmpId = useSelector(store => store.templatesAdmin.tmpId)
  const contractContent = useSelector(store => store.templatesAdmin.content)
  const contractParentContent = useSelector(store => store.templatesAdmin.parentContent)
  const contractParties = useSelector(store => store.templatesAdmin.parties.valueSeq().toArray())
  const contractSteps = useSelector(store => store.templatesAdmin.steps.valueSeq().toArray())
  const contractPostLoading = useSelector(store => store.templatesAdmin.postLoading)
  const contractPostLoaded = useSelector(store => store.templatesAdmin.postLoaded)
  const contractLoading = useSelector(store => store.templatesAdmin.loading)
  const contractDeleteUpdated = useSelector(store => store.templatesAdmin.updated)
  const contractStepLoaded = useSelector(store => store.templatesAdmin.stepLoaded)
  const contractCanBeUsed = useSelector(store => store.templatesAdmin.canBeUsed)

  const [docContent, setDocContent] = useState('')
  const [previewContent, setPreviewContent] = useState('')
  const [docStepData, setDocStepData] = useState([])
  const [isDocReplaced, setIsDocReplaced] = useState(false)
  const [isContentUpdated, setIsContentUpdated] = useState(false)
  const [isActiveStepSet, setActiveStepSet] = useState(false)
  const [stepData, setStepData] = useState(null)
  const [activeTabIndex, setActiveTabIndex] = useState('0')
  const [activeFieldId, setActiveFieldId] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isTermsModal, setIsTermsModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [contractFinishLater, setContractFinishLater] = useState(false)
  const [steps, setSteps] = useState(contractSteps.map(item => item.toJS()))
  const templateId = contractTmpId || contractId
  const isEditTemplate = !!contractParentContent
  let matcherId = ''

  useEffect(() => {
    setDocContent(contractParentContent ? contractParentContent : contractContent)
    setPreviewContent(contractParentContent ? contractParentContent : contractContent)
  }, [])

  useEffect(() => {
    const initialValues = steps.map((step, index) => {
      const values = Object.entries(step.fields).filter((field, i) => field[1] && field[1].is_predefined !== 3).map((field, i) => {
        return [ field[1].id, field[1].val ]
      })

      return Object.fromEntries(values)
    })

    initialValues.forEach(item => handlePostUserData(item, false))
  }, [steps])

  useEffect(() => {
    const templateId = contractTmpId || contractId

    if (step) {
      setStepData(step.data)

      step.data.is_parties_data !== 0 && onTabChange('0')
    }

    if (isLastStep) {
      handleReplaceContent(docStepData)
      isDocReplaced && dispatch( updateContractContent({content: docContent}, templateId) )
    } else {
      setDocContent(contractParentContent ? contractParentContent : contractContent)
      setIsContentUpdated(false)
      setIsDocReplaced(false)
    }

    // after Save Later
    isDocReplaced && contractFinishLater && dispatch( updateContractContent({content: docContent}, templateId) )
  }, [step, docContent, isDocReplaced])

  useEffect(() => {
    if (docStepData.length > 0) {
      let activeStep, activeIndex
      const fieldsWithoutData = docStepData.filter(item => !item.value)

      if (fieldsWithoutData.length > 0) {
        activeStep = steps.filter(x => x.fields.hasOwnProperty( fieldsWithoutData[0].id ))
        activeIndex = steps.findIndex(step => step.id === activeStep[0].id)
      } else {
        activeIndex = steps.length
      }

      handleReplacePreviewContent(docStepData)
      !isActiveStepSet && handleSetStartStep(activeIndex)
    }
  }, [docStepData])

  useEffect(() => {
    contractStepLoaded && setIsContentUpdated(true)
  }, [contractStepLoaded])

  useEffect(() => {
    if (isDataUpdate) {
      handleSaveData()
    }
  }, [isDataUpdate])

  useEffect(() => {
    if (!contractLoading && contractDeleteUpdated) {
      window.location.href = `${SITE_DOMAIN}/catalog`
    }
  }, [contractLoading, contractDeleteUpdated])

  useEffect(() => {
    if (!contractPostLoading && contractPostLoaded) {
      setSubmitLoading(false)
    }

    if (!contractPostLoading && contractPostLoaded && contractFinishLater) {
      hideModal()
      toggleFinishLaterRedirect()
    }
  }, [contractPostLoading, contractPostLoaded])

  const handlePostUserData = (values, isSubmit) => {
    const currentTabStep = Number.parseInt(activeTabIndex)
    const nextTabStep = currentTabStep + 1
    const partiesFieldsArr = contractParties.flat().filter(o => o.is_predefined !== 3)
    let formData = []
    let stepFormData = {
      fields: []
    }

    Object.entries(values).map(([key, value], index) => {
      const date = value && value._d ? new Date(value._d) : null

      if (!key.includes('deliverable') && !value) return formData.push({ id: key })

      // set deliverable items data
      if (key.includes('deliverable')) {
        const dataItem = formData.filter(item => key.includes(item.id.toString()))

        if (dataItem.length === 0) return

        if (value) {
          return dataItem[0].value = JSON.stringify(value.map((item) => {
            const itemDate = item.date._d ? new Date(item.date._d) : null

            return {
              date: itemDate ? (itemDate.getMonth() + 1) + '/' + itemDate.getDate() + '/' +  itemDate.getFullYear() : '',
              description: item.description
            }
          }))
        } else {
          return dataItem[0].value = JSON.stringify([])
        }
      }

      setDocStepData(oldArray => {
        const foundIndex = oldArray.findIndex(x => x.id === key)

        if (foundIndex >= 0 && oldArray[foundIndex]) {
          oldArray[foundIndex].value = date ? (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear() : value ? value : ''
        }

        return foundIndex >= 0 ?
          [...oldArray] :
          [...oldArray, {
            id: key,
            value: date ? (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear() : value ? value : ''
          }]
      })

      return stepFormData.fields.push({
        id: key,
        value: date ? (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear() : value ? value : ''
      })
    })

    formData.length !== 0 && formData.reduce((obj, item) => {
      if (!item.value) item.value = ''

      setDocStepData(oldArray => {
        const foundIndex = oldArray.findIndex(x => x.id === item.id)

        if (foundIndex >= 0 && oldArray[foundIndex]) {
          oldArray[foundIndex].value = item.value
        }

        return foundIndex >= 0 ?
          [...oldArray] :
          [...oldArray, item]
      })

      return stepFormData.fields.push(item)
    }, {})

    // Parties data saving
    if (stepData && stepData.is_parties_data !== 0 && contractParties.length > 0) {
      let partyData = []

      if (contractParties.length >= nextTabStep) {
        contractParties.forEach((currentValue, index, array) => {
          isSubmit && onTabChange( index.toString() )

          if (index === contractParties.length - nextTabStep) {
            Object.entries(form.getFieldsValue()).map(([key, value], index) => {
              setDocStepData(oldArray => {
                const foundIndex = oldArray.findIndex(x => x.id === key)

                if (foundIndex >= 0 && oldArray[foundIndex]) {
                  oldArray[foundIndex].value = value
                }

                return foundIndex >= 0 ?
                  [...oldArray] :
                  [...oldArray, {
                    id: key,
                    value: value
                  }]
              })

              !value && setValidationError('not-all-fields')

              return partyData.push({
                id: key,
                value: value
              })
            })
          }
        })

        stepFormData.fields = partyData
      }
    }

    if (stepFormData.fields.length > 0 && isSubmit) {
      if (stepData && stepData.is_parties_data !== 0 && partiesFieldsArr.length !== stepFormData.fields.length) {
        onTabChange( nextTabStep.toString() )
        setValidationError('not-all-fields')
      }

      dispatch( postUserFields(stepFormData, templateId, stepData.id) )
    }

    setValidationError(null)
  }

  const handleReplaceContent = (data) => {
    if (!data) return

    data.map((field) => {
      const step = steps.filter(x => x.fields.hasOwnProperty(field.id)) // .filter(item => item.fields[key])
      const replacedField = step[0].fields[field.id]
      const idRgx = new RegExp(`<img[^>]*id="${replacedField.id}"[^>]*>`, 'g')
      const originalIdRgx = new RegExp(`<img[^>]*id="${replacedField.original_id}"[^>]*>`, 'g')
      let replaceWith = replacedField.field_type === 2 ? `<span style="white-space: pre-wrap">${field.value}</span>` : `<span>${field.value}</span>`

      if (replacedField.field_type === 3) {
        const deliverableData = JSON.parse(field.value)

        if (deliverableData.length !== 0) {
          replaceWith = `<table class="quill-table">
            <colgroup>
              <col span="1" style="width: 80%; text-align: left;">
              <col span="1" style="width: 20%; text-align: center;">
            </colgroup>
            <thead>
              <tr>
                <th>Deliverable</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              ${
            deliverableData.map((item) => `<tr>
                    <td>${item.description}</td>
                    <td style="text-align: center">${item.date}</td>
                  </tr>`).join('')
          }
            </tbody>
          </table>`
        } else {
          replaceWith = ''
        }
      }

      if (isEditTemplate) {
        setDocContent(prevContent => {
          if (prevContent) return prevContent.replaceAll(originalIdRgx, replaceWith)
        })
      } else {
        setDocContent(prevContent => {
          if (prevContent) return prevContent.replaceAll(idRgx, replaceWith)
        })
      }
    })

    return setIsDocReplaced(true)
  }

  const handleSaveData = () => {
    form
      .validateFields()
      .then((values) => {
        const token = getTokenCookies()

        setSubmitLoading(true)

        if (userEmail && !token) {
          const instance = axios.create({
            withCredentials: true,
          })

          instance.post(TOKEN_DOMAIN)
            .then(tokenRefreshResponse => {
              const token = tokenRefreshResponse.data.token

              setTokenCookies(token)
              return handlePostUserData(values, true)
            })
            .catch(error => {
              console.log({ error })
            })
        } else {
          handlePostUserData(values, true)
        }

        handleDataUpdate(false)
      })
      .catch((info) => {
        const errorFieldId = info.errorFields && info.errorFields.length > 0 ? info.errorFields[0].name[0] : null
        const flattenedParties = [].concat.apply([], contractParties)

        if (errorFieldId && stepData.is_parties_data !== 0 && contractParties.length > 0) {
          const errorObj = flattenedParties.filter(field => field.id === errorFieldId)
          const errorTab = errorObj[0].party_number

          onTabChange(errorTab.toString())
          setValidationError('not-all-fields')
        }

        console.log('Validate Failed:', info)
        setSubmitLoading(false)
        handleDataUpdate(false)
        hideModal()
      })
  }

  const handleValuesChange = (changedValues) => {
    handlePostUserData(changedValues, false)
  }

  const handleReplacePreviewContent = (values) => {
    if (!values) return
    let content = isEditTemplate ?
      contractParentContent.replace(/<style\b[^<]*(?:(?!<\/style)<[^<]*)*<\/style>/g, '') :
      contractContent.replace(/<style\b[^<]*(?:(?!<\/style)<[^<]*)*<\/style>/g, '')

    values.map((field) => {
      const step = steps.filter(x => x.fields.hasOwnProperty(field.id)) // .filter(item => item.fields[key])
      const replacedField = step[0].fields[field.id]
      const idRgx = new RegExp(`<img[^>]*id="${replacedField.id}"[^>]*>`, 'g')
      const originalIdRgx = new RegExp(`<img[^>]*id="${replacedField.original_id}"[^>]*>`, 'g')
      let replaceWith = replacedField.field_type === 2 ? `<b style="white-space: pre-wrap">${field.value}</b>` : `<b>${field.value}</b>`

      if (replacedField.field_type === 3 && field.value) {
        const deliverableData = JSON.parse(field.value)

        if (deliverableData.length !== 0) {
          replaceWith = `<table class="quill-table">
            <colgroup>
              <col span="1" style="width: 80%; text-align: left;">
              <col span="1" style="width: 20%; text-align: center;">
            </colgroup>
            <thead>
              <tr>
                <th>Deliverable</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              ${
            deliverableData.map((item) => `<tr>
                    <td>${item.description}</td>
                    <td style="text-align: center">${item.date}</td>
                  </tr>`).join('')
          }
            </tbody>
          </table>`
        } else {
          replaceWith = ''
        }
      }

      if (field.value) {
        if (isEditTemplate) {
          content = content.replaceAll(originalIdRgx, replaceWith)
        } else {
          content = content.replaceAll(idRgx, replaceWith)
        }
      }
    })

    setPreviewContent(content)
  }

  const handleSetStartStep = index => {
    if (!isActiveStepSet) {
      handleStepsChange(index)
      // nextStep()
    }
    setActiveStepSet(true)
  }

  const showModal = (isTerms) => {
    setIsModalVisible(true)

    isTerms && setIsTermsModal(true)
  }

  const hideModal = () => {
    setIsModalVisible(false)
    setIsTermsModal(false)
  }

  const handleTemplateRemove = () => {
    // dispatch( deleteContract(templateId) )
    history.push({
      pathname: START_PAGE,
      search: '?tab=drafts',
    })
    window.scrollTo(0, 0)
  }

  const handleFinishLater = () => {
    if (isLastStep) return toggleFinishLaterRedirect()

    handleSaveData()
    handleReplaceContent(docStepData)
    setContractFinishLater(true)
  }

  const toggleFinishLaterRedirect = () => {
    return token && token !== 'undefined' ?
      history.push({
        pathname: START_PAGE,
        search: '?tab=drafts&send=later',
      }) :
      window.location.href = `${LOGIN_PAGE}/?redirect_to=/bcr/&send=later&attach=${contractTmpId}`
  }

  const onTabChange = (key) => {
    setActiveTabIndex(key)
  }

  const focusHandler = (id) => {
    setActiveFieldId(id)
  }

  return (
    <>
      <div>
        {stepData ?
          <>
            <h2>{stepData.title}</h2>
            <p>{stepData.description}</p>
            <Spin size='small' spinning={contractLoading || submitLoading} style={{maxHeight: `100%`}}>
              {isLastStep ?
                <>
                  {isContentUpdated ?
                    <PDFViewer
                      wide
                      key='completedDocument'
                      PDFUrl={`${API}template/${templateId}/pdf_create`}
                    /> :
                    <Spin
                      size='small'
                      spinning={true}
                      style={{ textAlign: 'center' }}
                    />
                  }
                </> :
                stepData.is_parties_data !== 0 && contractParties.length > 0 ?
                  <Form
                    form={form}
                    layout='vertical'
                    name='product-step-party-data'
                    autoComplete='off'
                    className={`${styles.form} ${styles.partyForm}`}
                    validateMessages={FORM_VALIDATE_MESSAGES}
                    validateTrigger='onSubmit'
                    onValuesChange={handleValuesChange}
                  >
                    <Tabs
                      type='card'
                      activeKey={activeTabIndex}
                      onChange={activeKey => onTabChange(activeKey)}
                    >
                      {contractParties.map((party, i) => {
                        const partyTitle = party.filter(party => party.is_predefined === 3)
                        const tabTitle = partyTitle[0] ? partyTitle[0].title : `Party ${i + 1}`

                        return <TabPane
                          key={i}
                          tab={tabTitle}
                        >
                          {party.length > 0 ?
                            party.map((field, i) => {
                              let isEmailCheck

                              if (field.title === 'Email') {
                                if (field.party_number > 0) {
                                  isEmailCheck = true
                                } else {
                                  isEmailCheck = false
                                  matcherId = field.id
                                }
                              }

                              return field.is_predefined !== 3 && (
                                <Form.Item
                                  key={field.id}
                                  name={field.id}
                                  label={field.title}
                                  initialValue={field.val}
                                  dependencies={[isEmailCheck ? matcherId : '']}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      type: field.title === 'Email' ? 'email' : 'string',
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(rule, value, callback) {
                                        if (isEmailCheck) {
                                          if (getFieldValue(matcherId).toString().toLowerCase() === value.toString().toLowerCase()) {
                                            // return Promise.resolve()
                                            callback(EMAILS_MATCH_ERROR)
                                          }

                                          // return Promise.reject(new Error(EMAILS_MATCH_ERROR))
                                        }

                                        return callback()
                                      },
                                    }),
                                  ]}
                                >
                                  <Input
                                    disabled={contractPostLoading}
                                    onFocus={() => focusHandler(isEditTemplate ? field.original_id : field.id)}
                                    // ref={(input) => {
                                    //   input && i === 0 && input.focus()
                                    // }}
                                    // placeholder={`Enter ${field.title}`}
                                  />
                                </Form.Item>
                              )
                            }) :
                            <p className='ant-row'>{PRODUCT_STEP_NO_DATA}</p>
                          }
                        </TabPane>
                      })}
                    </Tabs>
                  </Form> :
                  <Form
                    form={form}
                    layout='vertical'
                    name='product-step-data'
                    autoComplete='off'
                    className={styles.form}
                    validateMessages={FORM_VALIDATE_MESSAGES}
                    validateTrigger='onSubmit'
                    onValuesChange={handleValuesChange}
                  >
                    {Object.entries(stepData.fields).length > 0 ?
                      Object.entries(stepData.fields).map((field, i) => {
                        const item = field[1]
                        const dateFieldRules = [
                          {
                            required: item.is_required === 1,
                            message: `"${item.title}" is required`,
                          }
                        ]
                        const deliverableFieldRules = [{}]
                        const numberFiledRules = [
                          {
                            required: item.is_required === 1,
                            whitespace: true,
                            message: `"${item.title}" is required`,
                          },
                          {
                            pattern: /^(?:\d*)$/,
                            message: `"${item.title}" should contain just numbers`,
                          },
                          item.maxlength ?
                            {
                              max: item.maxlength,
                              message: `"${item.title}" cannot be greater than ${item.maxlength} symbols`
                            } : {},
                        ]
                        const textFieldRules = [
                          {
                            required: item.is_required === 1,
                            whitespace: true,
                            message: `"${item.title}" is required`,
                          },
                          item.maxlength ?
                            {
                              max: item.maxlength,
                              message: `"${item.title}" cannot be greater than ${item.maxlength} symbols`
                            } : {},
                          {
                            type: item.validation_type === 4 ?
                              'email' :
                              'string',
                            message: item.validation_error_text ? item.validation_error_text : null,
                          },
                          item.validation_type === 2 ?
                            {
                              pattern: /^[A-Za-z]*$/,
                              message: `"${item.title}" should contain just text`,
                            } : {},
                        ]
                        const deliverableValues = form.getFieldValue(`deliverable-${item.id}`)

                        // console.log( deliverableValues )

                        return (
                          <Form.Item
                            key={item.id}
                            name={item.id}
                            initialValue={
                              item.val && item.field_type === 4 ?
                                moment(item.val, 'MM/DD/YYYY') :
                                item.val ?
                                  item.val :
                                  null
                            }
                            label={
                              <span>
                                {item.title}
                                {item.tooltip &&
                                  <Tooltip placement='right' title={item.tooltip}>
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                }
                              </span>
                            }
                            rules={
                              item.field_type === 4 ?
                                dateFieldRules :
                                item.field_type === 3 ?
                                  deliverableFieldRules :
                                  item.validation_type === 3 ?
                                    numberFiledRules :
                                    textFieldRules
                            }
                          >
                            { // switch - case (?)
                              item.field_type === 1 ?
                                <Input
                                  disabled={contractPostLoading}
                                  onFocus={() => focusHandler(isEditTemplate ? item.original_id : item.id)}
                                  // ref={(input) => {
                                  //   input && i === 0 && input.focus()
                                  // }}
                                  // placeholder={`Enter ${item.title}`}
                                /> :
                                item.field_type === 2 ?
                                  <TextArea
                                    rows={4}
                                    disabled={contractPostLoading}
                                    onFocus={() => focusHandler(isEditTemplate ? item.original_id : item.id)}
                                    // ref={(textarea) => {
                                    //   textarea && i === 0 && textarea.focus()
                                    // }}
                                    // placeholder={`Enter ${item.title}`}
                                  /> :
                                  item.field_type === 3 ?
                                    <ProductDynamicForm
                                      form={form}
                                      item={item}
                                      disabled={contractPostLoading}
                                      isEdit={isEditTemplate}
                                      onFocus={focusHandler}
                                    /> :
                                    item.field_type === 4 ?
                                      <DatePicker
                                        disabled={contractPostLoading}
                                        onFocus={() => focusHandler(isEditTemplate ? item.original_id : item.id)}
                                        // ref={(date) => {
                                        //   date && i === 0 && date.focus()
                                        // }}
                                        format='MM/DD/YYYY'
                                        placeholder=''
                                        // placeholder={`Select ${item.title}`}
                                      /> :
                                      null
                            }
                          </Form.Item>
                        )
                      }) :
                      <p className='ant-row'>{PRODUCT_STEP_NO_DATA}</p>
                    }
                  </Form>
              }
            </Spin>
          </> :
          <p>{PRODUCT_STEP_NO_DATA}</p>
        }
        <Modal
          visible={isModalVisible}
          title={isTermsModal ? TERMS_TITLE : BEFORE_EXIT_TITLE}
          centered={true}
          keyboard={true}
          maskClosable={true}
          className={isTermsModal ? 'ant-modal-terms' : ''}
          onCancel={hideModal.bind(this, { triggerCancel: true })}
          footer={isTermsModal ?
            [
              <Button
                key='cancel'
                disabled={submitLoading}
                onClick={hideModal}
              >
                Cancel
              </Button>,
              <Button
                key='agree'
                type='primary'
                loading={submitLoading}
                onClick={() => setSubmitLoading(true)}
                href={contractCanBeUsed ? `${THANK_YOU_PAGE}/${contractId}` : `${WP_PRICING_PAGE}/?prod_id=${productId}&temp_id=${contractTmpId}&template_id=${contractId}&back_to=${APP_DOMAIN}/product/${templateId}/${productId}/&edit`}
              >
                Agree
              </Button>
            ] :
            [
              <Button
                key='submit'
                loading={contractPostLoading}
                disabled={contractPostLoading}
                onClick={handleFinishLater}
              >
                <span>Save</span>
              </Button>,
              <Button
                key='delete'
                type='primary'
                loading={contractLoading}
                disabled={contractLoading || contractPostLoading}
                onClick={handleTemplateRemove}
              >
                Don’t Save
              </Button>,
              <Button
                key='cancel'
                type='primary'
                disabled={contractLoading || contractPostLoading}
                onClick={hideModal}
              >
                Cancel
              </Button>
            ]
          }
        >
          <Spin size='small' spinning={contractPostLoading || contractLoading}>
            <p>{isTermsModal ?
              <>
                <span>Creators’ Legal is not a law firm and cannot provide legal advice. We are a self-help online and software service and do not participate in any legal representation.</span>
                <span>We are not a substitute for a lawyer, law firm or professional legal advice. We are not a ‘lawyer referral service’. Use of CreatorsLegal.com is subject to our <a href={`${SITE_DOMAIN}/terms-and-conditions/`} target='_blank'>Terms and Conditions</a> and <a href={`${SITE_DOMAIN}/privacy-policy/`} target='_blank'>Privacy Policy</a>.</span>
              </> :
              BEFORE_EXIT_TEXT
            }</p>
          </Spin>
        </Modal>
        <div className={styles.nav}>
          <div>
            <Button
              size='small'
              onClick={() => prevStep()}
              disabled={submitLoading || contractPostLoading}
            >
              Back
            </Button>
          </div>
          <div>
            {currentStep < contractSteps.length && (
              <>
                <Button
                  type='primary'
                  onClick={() => nextStep()}
                  disabled={submitLoading || contractPostLoading}
                >
                  Save & continue
                </Button>
                <Button
                  size='small'
                  onClick={() => prevStep()}
                  disabled={submitLoading || contractPostLoading}
                  className={styles.mobileBtn}
                >
                  Back
                </Button>
                <Button
                  onClick={handleFinishLater}
                  icon={<SaveOutlined />}
                  disabled={submitLoading || contractPostLoading}
                >
                  <span>Save & finish later</span>
                </Button>
                <Button
                  onClick={() => showModal(false)}
                  icon={<ExportOutlined />}
                  disabled={submitLoading || contractPostLoading}
                >
                  Exit
                </Button>
              </>
            )}
            {isLastStep && (
              <>
                <Button
                  type='primary'
                  disabled={submitLoading || contractPostLoading}
                  onClick={() => showModal(true)}
                >
                  Save & finish
                </Button>
                <Button
                  size='small'
                  onClick={() => prevStep()}
                  disabled={submitLoading || contractPostLoading}
                  className={styles.mobileBtn}
                >
                  Back
                </Button>
                <Button
                  onClick={handleFinishLater}
                  icon={<SaveOutlined />}
                  disabled={submitLoading || contractPostLoading}
                >
                  <span>Save & finish later</span>
                </Button>
                <Button
                  onClick={() => showModal(false)}
                  icon={<ExportOutlined />}
                  disabled={submitLoading || contractPostLoading}
                >
                  Exit
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {
        !isLastStep && !isContentUpdated &&
        <DocumentPreviewer
          html={previewContent}
          originalHtml={isEditTemplate ? contractParentContent : contractContent}
          activeFieldId={activeFieldId}
        />
      }
    </>
  )
}

export default ProductStep;
