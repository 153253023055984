import { OrderedMap, List, Record } from 'immutable'

import { START, SUCCESS, FAIL } from '../../../constants/actions'
import { arrToMap } from '../../utils'
import * as types from './types'
import * as templatesTypes from '../templates/types'
import * as activeTypes from '../templatesAdmin/types'



const TemplatesRecord = Record({
  folders: new OrderedMap({}),
  templates: new OrderedMap({}),
  newEntities: 0,
  loading: false,
  loaded: false,
  deleted: false,
  error: null,
  message: null,
  pdfResponse: null,
})

const FolderRecord = Record({
  id: null,
  key: null,
  index: 0,
  position: 0,
  parentId: null,
  title: null,
  chapter: null,
  new_objects_count: 0,
  isOpen: false,
  children: new List([]),
  templates: new OrderedMap({}),
})

const TemplateRecord = Record({
  id: null,
  key: null,
  index: 0,
  position: 0,
  folder_id: null,
  status: null,
  title: null,
  is_new: false,
  updated_at: null,
})

const templatesSent = (templatesSent = new TemplatesRecord(), action) => {
  const {type, response, error, payload} = action

  switch(type) {
    case types.GET_SENT_TEMPLATES + START:
      return templatesSent
        .set('loading', true)

    case types.GET_SENT_TEMPLATES + SUCCESS:
      const foldersData = []
      const templatesData = []
      let i = 1

      // folder
      response.data.folders &&
        response.data.folders.forEach((folder) => {
          const folderData = {
            id: folder.id,
            key: `${folder.id}-${(~~(Math.random() * 1e8)).toString(16)}`,
            index: i++,
            position: folder.position,
            parentId: folder.parent_id,
            title: folder.title,
            chapter: folder.chapter,
            new_objects_count: folder.new_objects_count,
            isOpen: folder.open_state === 1,
            children: new List([]),
          }

          // folder templates
          if (folder.envelopes && folder.envelopes.length > 0) {
            const subFolderTemplateData = []

            folder.envelopes.forEach((template) => {
              const templateData = {
                id: template.id,
                key: `${template.id}-${(~~(Math.random() * 1e8)).toString(16)}`,
                index: i++,
                position: template.position,
                folder_id: template.folder_id,
                status: template.status_title,
                title: template.title,
                updated_at: template.updated_at,
                is_new: template.is_new,
              }

              subFolderTemplateData.push(templateData)

              folderData.templates = arrToMap(subFolderTemplateData, TemplateRecord)
            })
          }

          foldersData.push(folderData)
        })

      // envelope
      response.data.envelopes &&
        response.data.envelopes.forEach((template) => {
          const templateData = {
            id: template.id,
            key: `${template.id}-${(~~(Math.random() * 1e8)).toString(16)}`,
            index: i++,
            position: template.position,
            folder_id: template.folder_id,
            status: template.status_title,
            title: template.title,
            updated_at: template.updated_at,
            is_new: template.is_new,
          }

          templatesData.push(templateData)
        })

      return templatesSent
        .setIn(['folders'], arrToMap(foldersData, FolderRecord))
        .setIn(['templates'], arrToMap(templatesData, TemplateRecord))
        .set('loaded', true)
        .set('loading', false)

    case types.GET_SENT_TEMPLATES + FAIL:
      return templatesSent
        .set('folders', new OrderedMap({}))
        .set('templates', new OrderedMap({}))
        .set('loading', false)
        .set('loaded', false)
        .set('error', error.response.data)
        .set('message', error.message)

    case types.DELETE_SENT_TEMPLATE_FOLDER + START:
      return templatesSent
        .set('loading', true)

    case types.DELETE_SENT_TEMPLATE_FOLDER + SUCCESS:
      const removedTemplates = payload.data.children.map(template => {
        template.folder_id = 0
        template.position = payload.data.position

        return template
      })

      return removedTemplates.length > 0 ?
        templatesSent
          .set('loading', false)
          .deleteIn(['folders', payload.data.id])
          .mergeIn(['templates'], arrToMap(removedTemplates, TemplateRecord))
        :
        templatesSent
          .set('loading', false)
          .deleteIn(['folders', payload.data.id])

    case types.DELETE_SENT_TEMPLATE_FOLDER + FAIL:
      return templatesSent
        .set('loading', false)
        .set('error', error.response.data)
        .set('message', error.response ? error.response.data.message : error.message)

    case types.ADD_SENT_TEMPLATE_FOLDER + START:
       return templatesSent
         .set('loading', true)

    case types.ADD_SENT_TEMPLATE_FOLDER + SUCCESS:
      const newFolder = [{
        id: response.data.folder_id,
        key: `${response.data.folder_id}-${(~~(Math.random() * 1e8)).toString(16)}`,
        index: payload.index,
        position: payload.position,
        parentId: payload.parent_id,
        chapter: 'ENVELOPE_COMPLETE',
        isOpen: false,
        title: payload.title,
      }]

      return templatesSent
        .set('loading', false)
        .mergeIn(['folders'], arrToMap(newFolder, FolderRecord))

    case types.ADD_SENT_TEMPLATE_FOLDER + FAIL:
      return templatesSent
        .set('loading', false)
        .set('error', error)
        .set('message', error.message)

    case types.UPDATE_SENT_TEMPLATE_FOLDER + START:
      return templatesSent

    case types.UPDATE_SENT_TEMPLATE_FOLDER + SUCCESS:
      return templatesSent
        .updateIn(['folders', payload.id],
          (val) => {
            return val.set('title', payload.title)
          }
        )

    case types.UPDATE_SENT_TEMPLATE_FOLDER + FAIL:
      return templatesSent
        .set('error', error.response.data)
        .set('message', error.message)

    case types.HANDLE_ACTION_SENT_TEMPLATE + START:
      return templatesSent
        .set('loading', true)
        .set('pdfResponse', null)

    case types.HANDLE_ACTION_SENT_TEMPLATE + SUCCESS:
      return templatesSent
        .set('loading', false)
        .set('pdfResponse', response.data.action_result.data.download_url)

    case types.HANDLE_ACTION_SENT_TEMPLATE + FAIL:
      return templatesSent
        .set('loading', false)

    case types.PUT_SENT_TEMPLATE_ACTION + START:
      return templatesSent
        .set('loading', true)

    case types.PUT_SENT_TEMPLATE_ACTION + SUCCESS:
      const result = response.data.action_result

      return templatesSent
        .set('loading', false)
        .set('error', result && result.errors.length > 0 ? result.errors : null)
        .set('message', result ?
          result.errors.length > 0 ?
            result.errors[0] :
            result.messages.length > 0 ?
              result.messages[0] :
              null :
          null
        )

    case types.PUT_SENT_TEMPLATE_ACTION + FAIL:
      return templatesSent
        .set('loading', false)
        .set('error', error.response.data)
        .set('message', error.message)

    case types.UPDATE_SENT_TEMPLATE_POSITION + START:
      return templatesSent

    case types.UPDATE_SENT_TEMPLATE_POSITION + SUCCESS:
      const dragResult = response.data.results
      const draggedTemplate = payload.dragTemplate
      const draggedItemArr = dragResult.filter(item => item.id === payload.dragId)
      const draggedItem = draggedItemArr[0]
      const newFolderId = parseInt(draggedItem.new_folder_id)
      const prevFolderId = payload.prevFolderId

      draggedTemplate.position = draggedItem.new_position

      return draggedItem.type === 'folder' ?
        templatesSent
          .update(['folders'], value => value.map(x => {
            const obj = x.toJS()
            const res = dragResult.find(el => el.id === obj.id)

            return res ?
              x.set('position', res.new_position) :
              x
          }))
          .update(['templates'], value => value.map(o => {
            const obj = o.toJS()
            const res = dragResult.find(el => el.id === obj.id)

            return res ?
              o.set('position', res.new_position) :
              o
          })) :
        newFolderId > 0 ?
          prevFolderId > 0 ?
            templatesSent
              .deleteIn(['folders', prevFolderId, 'templates', payload.dragId])
              .mergeIn(['folders', newFolderId, 'templates'], arrToMap([draggedTemplate], TemplateRecord))
              .updateIn(['folders', newFolderId, 'templates'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              })) :
            templatesSent
              .deleteIn(['templates', payload.dragId])
              .mergeIn(['folders', newFolderId, 'templates'], arrToMap([draggedTemplate], TemplateRecord))
              .updateIn(['folders', newFolderId, 'templates'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              })) :
          prevFolderId > 0 ?
            templatesSent
              .deleteIn(['folders', prevFolderId, 'templates', payload.dragId])
              .mergeIn(['templates'], arrToMap([draggedTemplate], TemplateRecord))
              .update(['folders'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              }))
              .update(['templates'], value => value.map(o => {
                const obj = o.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  o.set('position', res.new_position) :
                  o
              }))
            :
            templatesSent
              .update(['folders'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              }))
              .update(['templates'], value => value.map(o => {
                const obj = o.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  o.set('position', res.new_position) :
                  o
              }))

    case types.UPDATE_SENT_TEMPLATE_POSITION + FAIL:
      return templatesSent

    case activeTypes.GET_TEMPLATES_NOTIFICATIONS + SUCCESS:
      return templatesSent
        .set('newEntities', response.data.envelope_complete)

    case activeTypes.GET_TEMPLATES_NOTIFICATIONS + FAIL:
      return templatesSent
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case templatesTypes.HANDLE_TABS_CHANGE:
      return templatesSent
        .set('folders', new OrderedMap({}))
        .set('templates', new OrderedMap({}))
        .set('loading', false)
        .set('loaded', false)
        .set('deleted', false)
        .set('error', null)
        .set('message', null)

    default:
      return templatesSent
  }
}

export default templatesSent;
