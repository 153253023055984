import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Select, Spin, Upload, message } from 'antd'
import { CaretDownOutlined, CloudUploadOutlined } from '@ant-design/icons'

import { getTokenCookies } from '../../store/utils'
import { updateContract, deleteContractBlankPDF } from '../../store/reducers/templatesAdmin/actions'
import { FORM_VALIDATE_MESSAGES, SOMETHING_WENT_WRONG } from '../../constants/staticErrors'
import { API } from '../../constants/variables'

import AdminPartyTabs from './AdminPartyTabs'
import styles from './style.module.scss'



const AdminBasicsForm = (props) => {
  const { isDataUpdate, handleDataUpdate, handleSwitchStep } = props
  const [numberOfParties, setNumberOfParties] = useState([0, 1])
  const dispatch = useDispatch()
  const { Dragger } = Upload
  const firstInputRef = useRef(null)
  const contractId = useSelector(store => store.templatesAdmin.id)
  const contractTitle = useSelector(store => store.templatesAdmin.title)
  const contractCategoryId = useSelector(store => store.templatesAdmin.categoryId)
  const contractCategorySKU = useSelector(store => store.templatesAdmin.sku)
  const contractPartiesNumber = useSelector(store => store.templatesAdmin.partiesNumber)
  const contractLoading = useSelector(store => store.templatesAdmin.postLoading)
  const contractIsDownloadBlank = useSelector(store => store.templatesAdmin.isDownloadBlank)
  const contractDownloadBlankTitle = useSelector(store => store.templatesAdmin.downloadBlankTitle)
  const contractDownloadBlankUrl = useSelector(store => store.templatesAdmin.downloadBlankUrl)
  const contractCategories = useSelector(store => store.templatesAdmin.categories.valueSeq().toArray())
  const contractCategoriesOptions = contractCategories.map(item => {
    return {
      'label': item.title,
      'value': item.id
    }
  })
  const contractErrors = useSelector(store => store.templatesAdmin.error)
  const [form] = Form.useForm()
  const { Option } = Select
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  }
  const uploadProps = {
    name: 'file',
    maxCount: 1,
    action: `${API}template/${contractId}/upload_pdf`,
    headers: {
      'wp-token': getTokenCookies(),
    },
    data: file => {
      return {
        'pdf_file': file
      }
    },
    beforeUpload: file => {
      if (file.type !== 'application/pdf') {
        message.error({
          content: `${file.name} is not a PDF file`,
          className: 'error-message',
          duration: 0
        })
      }

      return file.type === 'application/pdf' ? true : Upload.LIST_IGNORE
    },
    defaultFileList: contractDownloadBlankUrl ? [
      {
        uid: '001',
        name: contractDownloadBlankTitle,
        status: 'done',
        url: contractDownloadBlankUrl,
      }
    ] : [],
    progress: { strokeWidth: 2, showInfo: false },
    onSuccess(res, file) {
      res.messages && message.success({
        content: res.messages[0],
        className: 'success-message',
        duration: 0
      })
    },
    onError(err) {
      console.log('onError', {err})
      message.error({
        content: SOMETHING_WENT_WRONG,
        className: 'error-message',
        duration: 0
      })
    },
    onRemove(file) {
      dispatch(deleteContractBlankPDF(contractId))
    }
  }

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus()
    }
  }, [firstInputRef])

  useEffect(() => {
    isDataUpdate &&
      form
        .validateFields()
        .then((values) => {
          const formData = {
            title: values.title,
            category_id: values.category,
            sku: values.sku,
            parties_number: Number.parseInt(values.parties)
          }

          dispatch(updateContract(formData, contractId))
          handleDataUpdate(false)
          handleSwitchStep(true)
        })
        .catch((info) => {
          console.log('Validate Failed:', info)
          handleDataUpdate(false)
        })
  }, [isDataUpdate])

  useEffect(() => {
    form.setFieldsValue({
      title: contractTitle,
      category: contractCategoryId ? contractCategoryId : contractCategoriesOptions.length > 0 ? contractCategoriesOptions[0].value : null,
      sku: contractCategorySKU,
      parties: contractPartiesNumber ? contractPartiesNumber : '2',
    })

    if (contractPartiesNumber) return setPartiesArray(contractPartiesNumber)
  }, [contractTitle, contractPartiesNumber])

  useEffect(() => {
    if (contractErrors && contractErrors.length > 0) {
      handlePartiesChange(2)
      form.setFieldsValue({
        parties: 2,
      })
    }
  }, contractErrors)

  const handlePartiesChange = (value) => {
    const partiesNum = Number.parseInt(value)
    const title = form.getFieldValue('title')
    const category = form.getFieldValue('category')
    const sku = form.getFieldValue('sku')
    const formData = {
      title: title,
      category_id: category,
      sku: sku,
      parties_number: partiesNum
    }

    value !== 2 && dispatch(updateContract(formData, contractId))
    return setPartiesArray(partiesNum)
  }

  const setPartiesArray = (length) => {
    const partiesArray = Array.from({length: length}, (x, i) => i)

    return setNumberOfParties(partiesArray)
  }

  return (
    <div className={styles.wrapper}>
      <Spin size='small' spinning={contractLoading}>
        <Form
          {...layout}
          form={form}
          name='new-template'
          className={styles.form}
          validateMessages={FORM_VALIDATE_MESSAGES}
          initialValues={{
            title: contractTitle ? contractTitle : null,
            category: contractCategoryId ? contractCategoryId : contractCategoriesOptions.length > 0 ? contractCategoriesOptions[0].value : null,
            sku: contractCategorySKU ? contractCategorySKU : null,
            parties: contractPartiesNumber ? contractPartiesNumber : '2'
          }}
        >
          <Form.Item
            name='title'
            label='Contract Name'
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input placeholder='Enter Contract Name' ref={firstInputRef} autoComplete='off' />
          </Form.Item>
          <Form.Item
            name='category'
            label='Category'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={contractCategoriesOptions}
              placeholder='Select Category'
              suffixIcon={<CaretDownOutlined />}
            />
          </Form.Item>
          <Form.Item
            name='sku'
            label='SKU Number'
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input placeholder='Enter SKU Number' autoComplete='off' />
          </Form.Item>
          <Form.Item
            name='parties'
            label='Number of Parties'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder='Set Number of Parties'
              suffixIcon={<CaretDownOutlined />}
              onChange={handlePartiesChange}
              style={{ maxWidth: `50%` }}
            >
              <Option value='1'>1</Option>
              <Option value='2'>2</Option>
              <Option value='3'>3</Option>
              <Option value='4'>4</Option>
              <Option value='5'>5</Option>
              <Option value='6'>6</Option>
              <Option value='7'>7</Option>
              <Option value='8'>8</Option>
            </Select>
          </Form.Item>
        </Form>
        <AdminPartyTabs tabs={numberOfParties} />
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined style={{fontSize: 43}} />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support for a single PDF upload.</p>
        </Dragger>
      </Spin>
    </div>
  )
}

export default AdminBasicsForm;
