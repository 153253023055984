export const POST_PROGRESS_TEMPLATE = 'POST_PROGRESS_TEMPLATE'
export const GET_PROGRESS_TEMPLATES = 'GET_PROGRESS_TEMPLATES'

export const ADD_PROGRESS_TEMPLATE_FOLDER = 'ADD_PROGRESS_TEMPLATE_FOLDER'
export const UPDATE_PROGRESS_TEMPLATE_FOLDER = 'UPDATE_PROGRESS_TEMPLATE_FOLDER'
export const UPDATE_PROGRESS_TEMPLATE_FOLDER_OPEN_STATUS = 'UPDATE_PROGRESS_TEMPLATE_FOLDER_OPEN_STATUS'
export const UPDATE_PROGRESS_TEMPLATE_POSITION = 'UPDATE_PROGRESS_TEMPLATE_POSITION'
export const DELETE_PROGRESS_TEMPLATE_FOLDER = 'DELETE_PROGRESS_TEMPLATE_FOLDER'

export const GET_PROGRESS_TEMPLATE_HISTORY = 'GET_PROGRESS_TEMPLATE_HISTORY'
export const DOWNLOAD_PROGRESS_TEMPLATE = 'DOWNLOAD_PROGRESS_TEMPLATE'

export const PUT_PROGRESS_TEMPLATE_ACTION = 'PUT_PROGRESS_TEMPLATE_ACTION'
export const CLEAR_PROGRESS_TEMPLATE_HS = 'CLEAR_PROGRESS_TEMPLATE_HS'
