export const loadingTemplatesInProgressSelector = (store) => store.templatesInProgress.loading
export const loadedTemplatesInProgressSelector = (store) => store.templatesInProgress.loaded
export const postLoadingTemplatesInProgressSelector = (store) => store.templatesInProgress.postLoading
export const postLoadedTemplatesInProgressSelector = (store) => store.templatesInProgress.postLoaded
export const updatedTemplatesInProgressSelector = (store) => store.templatesInProgress.updated
export const inProgressNewEntitiesSelector = (store) => store.templatesInProgress.newEntities
export const pdfResponseTemplatesInProgressSelector = (store) => store.templatesInProgress.pdfResponse
export const messageTemplatesInProgressSelector = (store) => store.templatesInProgress.message
export const errorTemplatesInProgressSelector = (store) => store.templatesInProgress.error
export const helloSignOriginTemplatesInProgressSelector = (store) => store.templatesInProgress.hsOrigin
export const historyTemplatesInProgressSelector = (store) => store.templatesInProgress.history.valueSeq().toArray()
export const foldersTemplatesInProgressSelector = (store) => store.templatesInProgress.folders.valueSeq().toArray()
export const templatesTemplatesInProgressSelector = (store) => store.templatesInProgress.templates.valueSeq().toArray()
