import { API } from '../../../constants/variables'
import * as types from './types'



export function convertHtmlToPdf(data) {
  return {
    type: types.POST_CONVERT_PDF,
    postAPI: `${API}pdf_pdfcrowd`,
    payload: data,
    postData: data
  }
}

export function setPdfCoordinates(data) {
  return {
    type: types.SET_PDF_COORDINATES,
    payload: data
  }
}

// export function updateTemplateTitle(data) {
//   return {
//     type: types.POST_CONVERT_PDF,
//     putAPI: `${API}template/update/title/${data.templateId}`,
//     putData: { title: data.title },
//     payload: { title: data.title },
//   }
// }
