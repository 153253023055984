import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Container, Draggable } from 'react-smooth-dnd'
import { Button, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import FieldItem from './FieldItem'
import styles from './style.module.scss'



const FieldsList = ({ onDrop, getChildPayload, onEdit, onDelete, onClick, item, itemFields, ...props }) => {
  const containerRef = useRef(null)
  const isPartiesData = item.is_parties_data === 1
  const contractParties = useSelector(store => store.templatesAdmin.parties.valueSeq().toArray())
  const shouldAcceptDrop = (sourceContainerOptions, payload) => {
    return !(isPartiesData || Array.isArray(payload.fields))
  }

  return <div className={styles.container} ref={containerRef}>
    <h3>
      {item.title ? item.title : 'New Step'}
    </h3>
    {isPartiesData ?
      <ul>
        {contractParties.map((party, index) => {
          const partyTitle = party.filter(party => party.is_predefined === 3)
          const tabTitle = partyTitle[0] ? partyTitle[0].title : `Party ${index + 1}`

          return <li key={party[0].id}>{tabTitle}</li>
        })}
      </ul> :
      <Container
        groupName='steps'
        onDrop={e => onDrop(item.id, e)}
        getChildPayload={index => getChildPayload(item.id, index)}
        shouldAcceptDrop={shouldAcceptDrop}
        getGhostParent={() => containerRef.current ? containerRef.current : document.getElementById('root')}
        dropPlaceholder={{
          animationDuration: 150,
          showOnTop: true,
          className: styles.fieldDropPreview
        }}
      >
        {itemFields.length > 0 ?
          itemFields.map((item, index) => {
            return <Draggable key={item.id}>
              <FieldItem item={item} onClick={onClick} />
            </Draggable>
          }) :
          <p className={styles.message}>No fields yet</p>
        }
      </Container>
    }
    <Tooltip title='Edit Step Name' placement='rightTop'>
      <Button
        type='text'
        onClick={() => onEdit({
          id: item.id,
          title: item.title,
          description: item.description
        })}
        className={styles.button}
        icon={<EditOutlined />}
      />
    </Tooltip>
    {!isPartiesData &&
      <Tooltip title='Delete Step' placement='rightTop'>
        <Button
          type='text'
          onClick={() => onDelete({
            id: item.id,
            title: item.title,
            description: item.description,
            isDelete: true
          })}
          className={styles.button}
          icon={<DeleteOutlined />}
        />
      </Tooltip>
    }
  </div>
}

export default FieldsList;
