import React from 'react'
import { SITE_DOMAIN } from '../constants/variables'


const Logo = ({ withClickHandler, ...props }) => {
  return (
    <a className='logo' href={SITE_DOMAIN} onClick={withClickHandler}>
      <svg role="img" aria-labelledby="logoTitle" width="970" height="310" viewBox="0 0 970 310" fill="none">
        <title id={'logoTitle'}>Creators Legal</title>
        <path d="M273.2 308.49V218.38H290.2V297.13H312.59V308.49H273.2Z" fill="black"/>
        <path d="M362.07 308.49V218.38H401.21V230H379.1V255.87H396.58V267.45H379.1V297.17H401.49V308.53L362.07 308.49Z"
              fill="black"/>
        <path
          d="M475.13 309.69C466.29 309.69 459.927 307.023 456.04 301.69C452.153 296.357 450.207 288.603 450.2 278.43V249.09C450.136 244.59 450.562 240.097 451.47 235.69C452.228 232.155 453.675 228.805 455.73 225.83C457.795 222.954 460.656 220.743 463.96 219.47C468.073 217.953 472.438 217.233 476.82 217.35C486.073 217.35 492.71 219.533 496.73 223.9C500.75 228.267 502.75 234.89 502.73 243.77V248.17H486.43V243.49C486.43 241.49 486.43 239.87 486.29 238.6C486.192 237.305 485.967 236.022 485.62 234.77C485.365 233.655 484.831 232.623 484.07 231.77C483.28 231.012 482.335 230.436 481.3 230.08C479.922 229.614 478.474 229.394 477.02 229.43C475.864 229.416 474.712 229.571 473.6 229.89C472.712 230.15 471.867 230.538 471.09 231.04C470.379 231.55 469.8 232.222 469.4 233C468.962 233.764 468.603 234.572 468.33 235.41C468.05 236.39 467.863 237.395 467.77 238.41C467.64 239.64 467.55 240.73 467.52 241.67C467.49 242.61 467.46 243.82 467.46 245.31V281.7C467.46 287.127 468.157 291.103 469.55 293.63C470.943 296.157 473.61 297.417 477.55 297.41C479.317 297.472 481.071 297.086 482.65 296.29C484.045 295.525 485.169 294.348 485.87 292.92C486.678 291.295 487.228 289.554 487.5 287.76C487.846 285.443 488.007 283.102 487.98 280.76V273.37H477.84V262.77H503.27V308.53H492.21L491 299.36C488.167 306.253 482.877 309.697 475.13 309.69Z"
          fill="black"/>
        <path
          d="M551.7 308.49L570.7 218.38H588.46L607.41 308.49H591.58L587.8 287.57H571.61L567.77 308.49H551.7ZM573.42 276.86H585.94L579.67 239.86L573.42 276.86Z"
          fill="black"/>
        <path d="M657 308.49V218.38H674V297.13H696.39V308.49H657Z" fill="black"/>
        <path
          d="M52.36 188.3C45.2653 188.414 38.1975 187.401 31.42 185.3C25.9 183.595 20.7928 180.766 16.42 176.99C12.3543 173.285 9.07594 168.799 6.77998 163.8C4.27394 158.438 2.52393 152.753 1.57999 146.91C0.526579 140.189 0.0249053 133.393 0.0799867 126.59V62.5901C0.0151806 55.4896 0.533592 48.3958 1.62999 41.3801C2.59402 35.4502 4.34618 29.6758 6.83998 24.2101C9.05906 19.237 12.327 14.8023 16.42 11.2101C20.8303 7.56067 25.9323 4.83961 31.42 3.2101C38.2303 1.17611 45.3135 0.204682 52.42 0.330098C61.5666 0.330098 69.3933 1.5501 75.9 3.9901C81.9222 6.09297 87.2499 9.81198 91.3 14.7401C95.1515 19.6284 97.9439 25.2645 99.5 31.2901C101.29 38.5135 102.13 45.9394 102 53.3801V69.1101H69.75V53.4801C69.75 44.3268 69.27 38.1634 68.31 34.9901C66.39 28.7768 61.7 25.4434 54.24 24.9901H52.47C49.9769 24.9497 47.4921 25.2868 45.1 25.9901C43.1359 26.5735 41.3598 27.6633 39.95 29.1501C38.6659 30.5224 37.585 32.0714 36.74 33.7501C35.8131 35.7693 35.1962 37.9168 34.91 40.1201C34.5314 42.5223 34.2744 44.942 34.14 47.3701C34.0333 49.5901 33.9767 52.3968 33.97 55.7901V133.55C33.97 144.343 35.3033 152.027 37.97 156.6C40.6367 161.173 45.4566 163.46 52.43 163.46C54.5463 163.497 56.6563 163.217 58.69 162.63C60.3036 162.171 61.8355 161.463 63.23 160.53C64.5395 159.574 65.6023 158.319 66.33 156.87C67.1092 155.455 67.7585 153.972 68.27 152.44C68.8027 150.581 69.1379 148.67 69.27 146.74C69.49 144.3 69.6166 142.23 69.65 140.53C69.6833 138.83 69.7033 136.497 69.71 133.53V117.18H102V132.24C102.054 138.437 101.572 144.626 100.56 150.74C99.6438 156.078 97.9843 161.262 95.63 166.14C93.4916 170.719 90.4271 174.805 86.63 178.14C82.4578 181.565 77.6337 184.106 72.45 185.61C65.9285 187.52 59.1543 188.427 52.36 188.3V188.3Z"
          fill="black"/>
        <path
          d="M141.66 176.13V12.3801H179.19C186.653 12.2641 194.105 12.9785 201.41 14.5101C207.222 15.7532 212.718 18.1728 217.56 21.6201C222.215 25.0465 225.782 29.7448 227.83 35.1501C230.103 40.8367 231.24 47.8001 231.24 56.0401C231.24 66.3067 229.707 74.7001 226.64 81.2201C223.598 87.7296 218.171 92.8208 211.48 95.4401L234.2 176.13H204.28L184.72 101.37H171.49V176.13H141.66ZM171.49 82.3101H178.7C187.52 82.3101 193.79 80.4334 197.51 76.6801C201.23 72.9267 203.09 66.5067 203.09 57.4201C203.09 48.6601 201.477 42.3901 198.25 38.6101C195.023 34.8301 189.097 32.9367 180.47 32.9301H171.47L171.49 82.3101Z"
          fill="black"/>
        <path
          d="M273.2 170.16V18.3501H336.74V37.8601H300.85V81.4401H329.23V100.94H300.85V151H337.2V170.13L273.2 170.16Z"
          fill="black"/>
        <path
          d="M366.5 168.72L396.77 19.8H425.06L455.24 168.72H430L424 134.14H398.21L392.1 168.72H366.5ZM401.08 116.44H421L411 55.2801L401.08 116.44Z"
          fill="black"/>
        <path d="M488.42 168.72V39.8401H463.42V19.8401H539.7V39.8401H515.54V168.72H488.42Z" fill="black"/>
        <path
          d="M613.57 172C597.937 172 586.733 167.76 579.96 159.28C573.187 150.8 569.8 138.373 569.8 122V66.0001C569.682 58.9319 570.451 51.8768 572.09 45.0001C573.449 39.4161 575.959 34.1776 579.46 29.6201C583 25.17 587.709 21.7938 593.06 19.8701C598.733 17.7035 605.57 16.6201 613.57 16.6201C629.13 16.6201 640.313 20.8001 647.12 29.1601C653.927 37.5201 657.33 49.8001 657.33 66.0001V122C657.431 129.043 656.649 136.072 655 142.92C653.61 148.588 651.086 153.915 647.58 158.58C644.053 163.097 639.351 166.556 633.99 168.58C628.31 170.86 621.503 172 613.57 172ZM606.65 150.3C608.856 151.147 611.208 151.552 613.57 151.49C615.913 151.554 618.245 151.15 620.43 150.3C622.161 149.624 623.714 148.56 624.97 147.19C626.196 145.729 627.099 144.025 627.62 142.19C628.251 140.161 628.68 138.074 628.9 135.96C629.134 133.494 629.241 131.017 629.22 128.54V59.5601C629.26 56.4975 629.076 53.436 628.67 50.4001C628.32 47.9984 627.594 45.6666 626.52 43.4901C625.505 41.3813 623.816 39.6711 621.72 38.6301C619.161 37.4806 616.373 36.9332 613.57 37.0301C610.751 36.9358 607.947 37.4829 605.37 38.6301C603.259 39.6531 601.561 41.3682 600.56 43.4901C599.524 45.6777 598.817 48.0061 598.46 50.4001C598.054 53.436 597.87 56.4975 597.91 59.5601V128.5C597.889 130.977 597.996 133.454 598.23 135.92C598.45 138.034 598.879 140.121 599.51 142.15C600.032 143.977 600.92 145.678 602.12 147.15C603.359 148.542 604.912 149.618 606.65 150.29V150.3Z"
          fill="black"/>
        <path
          d="M696.46 176.13V12.3801H734C741.463 12.2641 748.915 12.9785 756.22 14.5101C762.032 15.7532 767.528 18.1728 772.37 21.6201C777.025 25.0465 780.592 29.7448 782.64 35.1501C784.88 40.8367 786 47.7867 786 56.0001C786 66.2667 784.47 74.6601 781.41 81.1801C778.359 87.6866 772.93 92.7758 766.24 95.4001L789 176.13H759.08L739.52 101.37H726.29V176.13H696.46ZM726.29 82.3101H733.5C742.32 82.3101 748.59 80.4334 752.31 76.6801C756.03 72.9267 757.89 66.5067 757.89 57.4201C757.89 48.6601 756.277 42.3901 753.05 38.6101C749.823 34.8301 743.897 32.9367 735.27 32.9301H726.27L726.29 82.3101Z"
          fill="black"/>
        <path
          d="M879 188.3C846.66 188.3 829.66 169.613 828 132.24L857.13 126.15C857.87 150.45 864.813 162.6 877.96 162.6C883.047 162.6 886.83 161.047 889.31 157.94C891.79 154.833 893.027 150.663 893.02 145.43C893.076 140.622 892.132 135.855 890.25 131.43C888.688 127.635 886.519 124.12 883.83 121.02C880.538 117.507 877.063 114.169 873.42 111.02L849.27 90C842.183 83.8 836.903 77.3567 833.43 70.67C829.957 63.9833 828.22 55.62 828.22 45.58C828.22 31.3333 832.41 20.2567 840.79 12.35C849.17 4.44333 860.673 0.416649 875.3 0.269982C881.341 0.0996642 887.368 0.944741 893.13 2.76998C897.816 4.23876 902.165 6.61751 905.93 9.76998C909.407 12.8363 912.265 16.5395 914.35 20.68C916.566 24.9814 918.246 29.5389 919.35 34.25C920.523 39.4062 921.299 44.645 921.67 49.92L893.09 55.57C892.95 51.8767 892.71 48.6633 892.37 45.93C892.024 43.1218 891.452 40.3461 890.66 37.63C890.015 35.189 888.945 32.8804 887.5 30.81C886.134 29.0168 884.343 27.5908 882.29 26.66C879.912 25.5891 877.293 25.1681 874.7 25.44C869.76 25.74 865.977 27.3833 863.35 30.37C860.723 33.3567 859.413 37.3567 859.42 42.37C859.42 48.2767 860.583 53.1333 862.91 56.94C865.925 61.5311 869.602 65.6521 873.82 69.17L897.79 90C901.46 93.306 904.936 96.8219 908.2 100.53C911.332 104.168 914.165 108.054 916.67 112.15C919.419 116.624 921.492 121.48 922.82 126.56C924.246 131.981 924.956 137.565 924.93 143.17C924.93 156.543 920.7 167.4 912.24 175.74C903.78 184.08 892.7 188.267 879 188.3Z"
          fill="black"/>
        <path d="M940.35 20.5699V5.22989H934.88V2.10986H949.55V5.22989H944.08V20.5699H940.35Z" fill="black"/>
        <path
          d="M951.9 20.5699V2.10986H957.48L960.83 14.6999L964.14 2.10986H969.74V20.5699H966.27V5.99988L962.61 20.5299H959L955.37 5.99988V20.5299L951.9 20.5699Z"
          fill="black"/>
      </svg>
    </a>
  )
}

export default Logo
