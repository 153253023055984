import Cookies from 'universal-cookie'
import {TOKEN_COOKIE, TOKEN_COOKIE_TIMEOUT_SEC} from '../constants/variables'
import {OrderedMap} from 'immutable'


export function setRequestHeaders() {
  const cookies = new Cookies()
  const token = cookies.get(TOKEN_COOKIE)

  return {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'wp-token': `${token}`,
    }
  }
}

export function setTokenCookies(token) {
  const cookies = new Cookies()

  return cookies.set(TOKEN_COOKIE, token, {path: '/', maxAge: TOKEN_COOKIE_TIMEOUT_SEC})
}

export function getTokenCookies() {
  const cookies = new Cookies()

  return cookies.get(TOKEN_COOKIE)
}

export function arrToMap(arr, ItemRecord) {
  return arr.reduce(
    (acc, item) => acc.set(item.id, ItemRecord ? new ItemRecord(item) : item),
    new OrderedMap()
  )
}

export function arrToMapByPosition(arr, ItemRecord) {
  return arr.reduce(
    (acc, item) => acc.set(item.id, ItemRecord ? new ItemRecord(item) : item),
    new OrderedMap()
  ).sort((a, b) => parseFloat(a.position) - parseFloat(b.position))
}

export function redirectUser(url) {
  return window.open(url, '_self')
}

export function getGMTOffset() {
  const offset = new Date().getTimezoneOffset() / -60
  const offsetSign = Math.sign(offset) === 1 ? '+' : ''

  return offsetSign + offset.toString()
}

export function getGMTDateTime() {
  const D = new Date().toLocaleString('en-US', {hour12: false})

  return D
}
