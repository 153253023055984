import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Cookies from 'universal-cookie'
import { Spin } from 'antd'

import { userLogout } from '../store/reducers/user/actions'
import { redirectUser } from '../store/utils'

import { LOGOUT_PAGE, TOKEN_COOKIE } from '../constants/variables'



const LogoutPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const cookies = new Cookies()

    cookies.remove(TOKEN_COOKIE, {path: '/'})
    dispatch(userLogout())

    redirectUser(LOGOUT_PAGE)
  }, [])

  return <div className='loader-wrapper'>
    <Spin size='middle' />
  </div>
}

export default LogoutPage;
