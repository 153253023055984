export const CREATION_IN_PROGRESS = 'The creation of envelope in progress...'
export const LOADING_DOCUMENT = 'Loading document'

export const FOOTER_SUBSCRIPTION_TITLE = 'Subscribe to Our Newsletter'
export const FOOTER_COPYRIGHT_TEXT = `Creators’ Legal is not a law firm and cannot provide legal advice. We are a self-help online and software service and do not participate in any legal representation. We are not a substitute for a lawyer, law firm or professional legal advice. We are not a ‘lawyer referral service’. Use of CreatorsLegal.com is subject to our Terms and Conditions and Privacy Policy.`
export const FOOTER_SUBSCRIPTION_TEXT = 'Join our community and subscribe to the newsletter for updates, useful info and special offers.'

export const VALIDATION_TEXT = 'Validation...'
export const PUBLISHING_TEXT = 'Publishing...'
export const PROCESSING_COMPLETE_TEXT = 'Processing complete!'

export const PRODUCT_STEP_TITLE = 'Contract Name'
export const PRODUCT_STEP_DESCRIPTION = 'You can customize the name of the contract as it will appear in your Project Briefcase.'
export const PRODUCT_STEP_NO_DATA = 'No data for this Step'

export const THANK_YOU_WITH_SEND_TEXT = 'Your contract is now ready for execution. It has been automatically saved in your briefcase. You can now send to all parties for signature, download a PDF Version or come back to it later.'
export const THANK_YOU_COMPLETE_TEXT = 'The document signing is complete. We\'ll email you the final copy shortly.'

export const SEND_DONE_MESSAGE = 'Contract was successfully sent to Parties.'
export const SEND_LATER_MESSAGE = 'Contract was successfully saved as draft.'
export const SIGNED_DONE_MESSAGE = 'Contract was successfully signed.'

export const BEFORE_EXIT_TITLE = 'Do you want to save changes to this contract before exit?'
export const BEFORE_EXIT_TEXT = 'If you don’t save, your changes will be lost.'

export const BEFORE_LEAVE_PAGE_TITLE = 'Do you want to leave?'
export const BEFORE_LEAVE_PAGE_AUTH_TEXT = 'You are about to leave the current contract. All your progress will be saved and you can find the draft of this contract in your Briefcase.'
export const BEFORE_LEAVE_PAGE_NO_AUTH_TEXT = 'You are about to leave the current contract. Please note that your changes will not be saved if you are not logged in. If you would like to access the draft of your contract please login in or register before leaving this page.'

export const TERMS_TITLE = 'Terms and Conditions'
export const TERMS_TEXT = `Creators’ Legal is not a law firm and cannot provide legal advice. We are a self-help online and software service and do not participate in any legal representation.`
  + ``
  + `We are not a substitute for a lawyer, law firm or professional legal advice. We are not a ‘lawyer referral service’. Use of CreatorsLegal.com is subject to our Terms and Conditions and Privacy Policy.`
