export const idTemplateDataSelector = (store) => store.templateData.id
export const folderIdTemplateDataSelector = (store) => store.templateData.folderId
export const titleTemplateDataSelector = (store) => store.templateData.title
export const contentTemplateDataSelector = (store) => store.templateData.content
export const pdfTemplateDataSelector = (store) => store.templateData.pdf
export const signersTemplateDataSelector = (store) => store.templateData.signers.valueSeq().toArray()
export const loadingTemplateDataSelector = (store) => store.templateData.loading
export const loadedTemplateDataSelector = (store) => store.templateData.loaded
export const updateLoadingTemplateDataSelector = (store) => store.templateData.updateLoading
export const updateLoadedTemplateDataSelector = (store) => store.templateData.updateLoaded
export const errorTemplateDataSelector = (store) => store.templateData.error
export const messageTemplateDataSelector = (store) => store.templateData.message
export const activeTabTemplateDataSelector = (store) => store.templateData.activeTab
