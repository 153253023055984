import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, withRouter } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import Cookies from 'universal-cookie'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { Button, Dropdown, Menu, Space, Table, Skeleton, Input, message } from 'antd'
import {
  MoreOutlined,
  CloseOutlined,
  SearchOutlined,
  DownloadOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons'

import {
  idContractSelector,
  categoriesContractSelector,
  templatesContractSelector,
  loadedContractSelector,
  loadingContractSelector,
  errorContractSelector,
  messageContractSelector,
  updatedContractSelector,
} from '../../store/reducers/templatesAdmin/selectors'
import {
  deleteContract,
  cloneContract,
} from '../../store/reducers/templatesAdmin/actions'
import { roleUserSelector } from '../../store/reducers/user/selectors'
import { getTokenCookies, setTokenCookies } from '../../store/utils'

import { CONTRACT_PAGE } from '../../constants/siteMap'
import {API, TOKEN_COOKIE, TOKEN_DOMAIN} from '../../constants/variables'
import styles from './table.module.scss'



class AdminTemplatesTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalVisible: false,
      openModalButtonId: null,
      downloadLoading: false,
      searchText: '',
      searchedColumn: '',
      selectedFiles: [],
      templates: [],
      tableData: [],
      fullTableData: [],
      expandedRows: [],
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.templatesEntities.length !== this.props.templatesEntities.length ||
      prevProps.templatesDeleted !== this.props.templatesDeleted && this.props.templatesDeleted ||
      prevProps.templatesLoading !== this.props.templatesLoading && this.props.templatesLoaded && !this.props.templatesLoading
    ) {
      this.setState({
        tableData: this.props.templatesEntities.map(el => el.toJS()).sort((a,b) => {
          return new Date(b.updated_at) - new Date(a.updated_at)
        })
      }, () => {
        const fullDataArray = []

        this.state.tableData.map((item) => {
          fullDataArray.push(item)

          item.children &&
          item.children.map((item) => {
            fullDataArray.push(item)

            item.children &&
            item.children.map(item => fullDataArray.push(item))
          })
        })
        this.setState({
          fullTableData: fullDataArray
        })
      })
    }
  }

  componentWillUnmount() {
    message.destroy()
  }

  render() {
    return this.body
  }

  get body() {
    const {
      contractID,
      contractCategories,
      templatesLoaded,
      templatesLoading,
      templatesUpdated,
    } = this.props
    const { downloadLoading } = this.state
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: 500,
        className: 'column-with-search-bar',
        ...this.getColumnSearchProps('title'),
      },
      {
        title: 'Category',
        dataIndex: 'category_id',
        key: 'category',
        width: 200,
        filters: contractCategories.map((item) => {
          return {
            text: item.title,
            value: item.id
          }
        }),
        onFilter: (value, record) => record.category_id === value,
        render: (record) => {
          const contractCategoryName = contractCategories.filter(item => item.id === record)

          return contractCategoryName.length > 0 ?
            <span>{contractCategoryName[0].title}</span> :
            <Skeleton.Button active={true} />
        },
        className: styles.withFilterCol,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        render: (record) => {
          return record === 1 ?
            <span className={styles.statusMarker}>
              Published
            </span> :
            <span>
              Draft
            </span>
        }
      },
      {
        title: 'Updated at',
        dataIndex: 'updated_at',
        key: 'updated_at',
        width: 200,
        render: (record) => {
          const dateString = record
          const D = new Date(dateString)
          const dateGMTString = D.toLocaleString('en-US', {timeZone: 'Africa/Abidjan'})
          const date = dateGMTString.replace(/:\d\d([ ap]|$)/, ' ').toLowerCase()

          return record ?
            date.replace(/,/g, ' at') :
            null
        }
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: 50,
        render: (text, record) => {
          return <Space size='middle' align='end'>
            {
              <>
                <Dropdown
                  trigger={['click']}
                  placement='bottomRight'
                  overlay={() => this.moreActionsMenu(record)}>
                  <Button
                    size='large'
                    icon={<MoreOutlined />}
                    style={null}
                  />
                </Dropdown>
              </>
            }
          </Space>
        },
      },
    ]

    if (templatesLoaded && !templatesLoading && templatesUpdated && contractID) {
      return <Redirect to={`${CONTRACT_PAGE}/${contractID}`} />
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={styles.headButton}>
          <Link
            className='ant-btn ant-btn-primary ant-btn-lg'
            to={CONTRACT_PAGE}
            style={{
              paddingLeft: `10px`,
              paddingRight: `10px`
            }}
          >
            + New Template
          </Link>
        </div>
        <Table
          className={styles.table}
          loading={templatesLoading || downloadLoading}
          columns={columns}
          dataSource={this.state.tableData}
          rowClassName={record => this.setRowClassName(record)}
          pagination={{
            defaultPageSize: 20,
            hideOnSinglePage: true
          }}
          rowSelection={{
            type: 'checkbox',
            ...this.rowSelection,
          }}
        />
      </div>
    )
  }

  moreActionsMenu = (record) => {
    const { userRole } = this.props

    return <Menu className={styles.dropDown}>
      <Menu.Item key='000'>
        <EditOutlined />
        <Link to={`contract/${record.id}`}>Edit Contract</Link>
      </Menu.Item>
      {
        record.isDownloadBlank &&
          <Menu.Item key='1' onClick={() => this.downloadClickHandler(record)}>
            <DownloadOutlined />
            Download Blank
          </Menu.Item>
      }
      <Menu.Item key='2' onClick={() => this.cloneClickHandler(record)}>
        <CopyOutlined />
        Clone
      </Menu.Item>
      {
        userRole === 'um_super-admin-cl' &&
          <Menu.Item key='3' onClick={() => this.deleteClickHandler(record)} style={{ color: '#EB5757' }}>
            <DeleteOutlined color='#EB5757' />
            Delete
          </Menu.Item>
      }
    </Menu>
  }

  setRowClassName = (record) => {
    return record.parentId >= 0 ?
      'ant-table-row-with-children' :
      record.folder_id > 0 ?
        'ant-table-row-nested' : ''
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({
        selectedFiles: selectedRows
      })
    },
    getCheckboxProps: record => ({
      disabled: record.parentId >= 0,
      name: record.name,
    }),
    renderCell: (checked, record, index, originNode) => record.parentId >= 0 ? null : originNode,
  }

  downloadClickHandler = (record) => {
    const cookies = new Cookies()
    const token = cookies.get(TOKEN_COOKIE)
    const docTitle = `${record.title + '– Blank'}.pdf`
    let config = {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf',
      'wp-token': `${token}`,
    }
    const instance = axios.create({
      withCredentials: true,
    })

    this.setState({
      downloadLoading: true
    })

    // Use interceptor to inject the token to requests
    axios.interceptors.request.use(request => {
      request.headers['wp-token'] = getTokenCookies()

      return request
    })

    // Function that will be called to refresh authorization
    const refreshAuthLogic = failedRequest => instance.post(TOKEN_DOMAIN)
      .then(tokenRefreshResponse => {
        const token = tokenRefreshResponse.data.token

        setTokenCookies(token)
        // failedRequest.response.config.headers['wp-token'] = token

        return Promise.resolve()
      })

    createAuthRefreshInterceptor(axios, refreshAuthLogic)

    axios.get(`${API}template/${record.id}/download_template_pdf`,
      {
        responseType: 'blob',
        headers: config
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', docTitle)
        document.body.appendChild(link)
        link.click()
        this.setState({
          downloadLoading: false
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          downloadLoading: false
        })
      })
  }

  deleteClickHandler = (data) => {
    const { deleteTemplate } = this.props

    deleteTemplate(data.id)
  }

  cloneClickHandler = (data) => {
    const { cloneTemplate } = this.props

    cloneTemplate(data.id)
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdownVisible: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return <div className={styles.tableSearchWrapper}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({closeDropdown: false})
            this.setState({
              searchText: e.target.value,
              searchedColumn: dataIndex,
            })
          }}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          prefix={<SearchOutlined />}
          disabled={this.props.templatesLoading}
          placeholder='Search ...'
        />
        {selectedKeys.length > 0 &&
          <Button
            icon={<CloseOutlined/>}
            onClick={() => this.handleResetSearch(clearFilters)}
            aria-label='Reset'
            style={{zIndex: 99, width: 20}}
          />
        }
      </div>
    },
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    render: (text, record) => {
      return <Link to={`contract/${record.id}`} style={{color: record.title ? 'var(--blue-headline)' : 'var(--grey'}}>
        {this.state.searchedColumn === dataIndex ?
          <Highlighter
            autoEscape
            searchWords={[this.state.searchText]}
            highlightStyle={{ backgroundColor: '#FFE5BD', padding: `2px` }}
            textToHighlight={text ? text.toString() : ''}
          /> :
          record.title ?
            record.title :
            'Untitled'
        }
      </Link>
    }
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  handleResetSearch = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }
}

export default connect(
  store => {
    return {
      userRole: roleUserSelector(store),
      contractID: idContractSelector(store),
      contractCategories: categoriesContractSelector(store),
      templatesEntities: templatesContractSelector(store),
      templatesLoaded: loadedContractSelector(store),
      templatesLoading: loadingContractSelector(store),
      templatesError: errorContractSelector(store),
      templatesUpdated: updatedContractSelector(store),
      templatesMessage: messageContractSelector(store),
    }
  },
  {
    deleteTemplate: deleteContract,
    cloneTemplate: cloneContract,
  }
)(withRouter(AdminTemplatesTable));
