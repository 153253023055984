import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PDFViewer from '../PDFViewer'
import { updateContractContentAdmin } from '../../store/reducers/templatesAdmin/actions'
import { convertHtmlToPdf } from '../../store/reducers/templatePdf/actions'

import styles from './style.module.scss'
import { PDF_STYLE } from '../../constants/variables'



const AdminPublish = ({isDataUpdate, handleDataUpdate, handleSwitchStep, ...props}) => {
  const dispatch = useDispatch()
  const isInitialMount = useRef(true)
  const contractId = useSelector(store => store.templatesAdmin.id)
  const contractSku = useSelector(store => store.templatesAdmin.sku)
  const contractContent = useSelector(store => store.templatesAdmin.content)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false

      dispatch(convertHtmlToPdf({
        html: `<body>${PDF_STYLE}${contractContent}</body>`,
        sku: contractSku
      }))
    }
  })

  useEffect(() => {
    if (isDataUpdate) {
      handleDataUpdate(false)
      handleSwitchStep(true)
      dispatch(updateContractContentAdmin({content: PDF_STYLE + contractContent}, contractId))
    }
  }, [isDataUpdate])

  return (
    <div className={styles.wrapper}>
      <h2>Preview Contract</h2>
      <PDFViewer wide />
    </div>
  )
}

export default AdminPublish;
