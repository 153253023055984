import React from 'react'
import { LOGIN_PAGE } from './variables'


export const SOMETHING_WENT_WRONG = 'Oops, something went wrong. Please try again.'

export const REQUIRED_FIELD = '🚫 This field is required'
export const REQUIRED_LABEL_FIELD = '🚫 ${label} is required'
export const NOT_VALID_LABEL_EMAIL = '🚫 ${label} is invalid'
export const NOT_VALID_NUMBER = '🚫 ${label} is not a number'
export const MISSING_THE_DATE = '🚫 Missing the date'
export const MISSING_DESCRIPTION = '🚫 Missing description'
export const EMAILS_MATCH_ERROR = '🚫 The emails that you entered must not match'
export const MAX_SYMBOLS_LIMIT_80 = `This field needs to be less then 80 symbols`
export const MAX_SYMBOLS_LIMIT_90 = `This field needs to be less then 90 symbols`
export const NOTHING_TO_SHOW = `Oops, nothing to show`
export const NOT_ALL_FIELDS_ERROR = 'Not all fields are filled in'

export const AUTH_ERROR_MESSAGE = (<span>Auth Failed. Please <a href={`${LOGIN_PAGE}/?redirect_to=/bcr`} target='_blank'>log in</a></span>)

export const FORM_VALIDATE_MESSAGES = {
  required: '🚫 ${label} is required',
  email: '🚫 ${label} is invalid',
  whitespace: '🚫 ${label} cannot be empty',
  types: {
    number: '🚫 ${label} is not a number',
    integer: '🚫 ${label} is not a number',
    email: '🚫 ${label} is not a valid email',
  },
  string: {
    len: '🚫 ${label} must be exactly ${len} characters',
    min: '🚫 ${label} must be at least ${min} characters',
    max: '🚫 ${label} cannot be longer than ${max} characters',
    range: '🚫 ${label} must be between ${min} and ${max} characters',
  },
  number: {
    len: '🚫 ${label} must equal ${len}',
    min: '🚫 ${label} cannot be less than ${min}',
    max: '🚫 ${label} cannot be greater than ${max}',
    range: '🚫 ${label} must be between ${min} and ${max}',
  },
}
