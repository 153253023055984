import React, { Component } from 'react'
import { connect } from 'react-redux'
import { message, Spin, Alert } from 'antd'
import { Helmet } from 'react-helmet'

import BriefcaseTabs from '../components/BriefcaseTabs'
import PageWrapper from '../components/PageWrapper'

import { attachContractByTempId } from '../store/reducers/templatesAdmin/actions'
import { loadingUserSelector, loadedUserSelector, errorUserSelector, messageUserSelector } from '../store/reducers/user/selectors'
import { errorContractSelector, messageContractSelector } from '../store/reducers/templatesAdmin/selectors'
import { errorTemplatesSelector } from '../store/reducers/templates/selectors'
import { errorTemplatesInProgressSelector } from '../store/reducers/templatesInProgress/selectors'
import { errorTemplatesSentSelector } from '../store/reducers/templatesSent/selectors'

import { PROCESSING_COMPLETE_TEXT, SEND_DONE_MESSAGE, SEND_LATER_MESSAGE } from '../constants/staticTexts'
import { LOGIN_PAGE } from '../constants/variables'



class StartPage extends Component {
  state = {
    error: null,
    errorInfo: null
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error, errorInfo})
  }

  componentWillUnmount() {
    message.destroy()
  }

  componentDidMount() {
    const {attachContract} = this.props
    const isSendMessage = this.props.location && this.props.location.search && this.props.location.search.includes('send=')
    const isAttachTemplate = this.props.location && this.props.location.search && this.props.location.search.includes('attach=')

    message.destroy()

    if (isSendMessage) {
      this.props.location.search.includes('send=done') && message.success({
        content: SEND_DONE_MESSAGE,
        className: 'success-message',
        duration: 5
      })
      this.props.location.search.includes('send=later') && message.success({
        content: SEND_LATER_MESSAGE,
        className: 'success-message',
        duration: 5
      })
      this.props.location.search.includes('send=complete') && message.success({
        content: PROCESSING_COMPLETE_TEXT,
        className: 'success-message',
        duration: 5
      })
    }

    if (isAttachTemplate) {
      const str = this.props.location.search
      const tempId = str.slice(str.lastIndexOf('=') + 1)

      attachContract(tempId)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {userError, templatesAdminError, templatesError, templatesInProgressError, templatesSentError} = this.props

    if (prevProps.userError !== this.props.userError && this.props.userError) {
      message.destroy()

      if (this.props.userMessage) {
        return message.error({
          content: this.props.userMessage,
          className: 'error-message',
          duration: 0
        })
      }
    }

    if (
      prevProps.userError !== userError && userError && userError.status === 401 ||
      prevProps.templatesAdminError !== templatesAdminError && templatesAdminError && templatesAdminError.status === 401 ||
      prevProps.templatesError !== templatesError && templatesError && templatesError.status === 401 ||
      prevProps.templatesInProgressError !== templatesInProgressError && templatesInProgressError && templatesInProgressError.status === 401 ||
      prevProps.templatesSentError !== templatesSentError && templatesSentError && templatesSentError.status === 401
    ) {
      return window.location.href = `${LOGIN_PAGE}/?redirect_to=/bcr`
    }
  }

  render() {
    const { userLoading, userLoaded } = this.props
    const { error, errorInfo } = this.state

    if (error) {
      return <PageWrapper className='briefcase'>
        <Alert
          type='error'
          message={error}
          description={errorInfo}
        />
      </PageWrapper>
    }

    if (userLoading && !userLoaded) {
      return <div className='loader-wrapper'>
        <Spin size='middle' />
      </div>
    }

    return <PageWrapper className='briefcase'>
      <Helmet>
        <meta name='viewport' content='width=1200, initial-scale=1' />
        <title>Briefcase - Creators Legal</title>
      </Helmet>
      <>
        <h1>Project Briefcase</h1>
        <BriefcaseTabs />
      </>
    </PageWrapper>
  }
}

export default connect(
  store => {
    return {
      userLoading: loadingUserSelector(store),
      userLoaded: loadedUserSelector(store),
      userError: errorUserSelector(store),
      userMessage: messageUserSelector(store),
      contractMessage: messageContractSelector(store),
      templatesAdminError: errorContractSelector(store),
      templatesError: errorTemplatesSelector(store),
      templatesInProgressError: errorTemplatesInProgressSelector(store),
      templatesSentError: errorTemplatesSentSelector(store)
    }
  },
  {
    attachContract: attachContractByTempId
  }
)(StartPage);
