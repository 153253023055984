import { OrderedMap, List, Record } from 'immutable'

import { arrToMap } from '../../utils'
import { START, SUCCESS, FAIL } from '../../../constants/actions'
import * as types from './types'
import * as activeTypes from '../templatesAdmin/types'



const TemplatesRecord = Record({
  folders: new OrderedMap({}),
  templates: new OrderedMap({}),
  newEntities: 0,
  loading: false,
  loaded: false,
  deleted: false,
  error: null,
  message: null,
})

const FolderRecord = Record({
  id: null,
  key: null,
  index: 0,
  position: 0,
  parentId: null,
  title: null,
  chapter: null,
  new_objects_count: 0,
  isOpen: false,
  children: new List([]),
  templates: new OrderedMap({}),
})

const TemplateRecord = Record({
  id: null,
  wp_product_id: null,
  key: null,
  index: 0,
  position: 0,
  folder_id: null,
  parent_template_id: null,
  status: null,
  title: null,
  can_be_used: false,
  use_button_title: null,
  updated_at: null,
  is_new: false,
  isDownloadBlank: false,
})

const templates = (templates = new TemplatesRecord(), action) => {
  const {type, response, error, payload} = action

  switch(type) {
    case types.POST_TEMPLATE + START:
      return templates
        .set('loading', true)

    case types.POST_TEMPLATE + SUCCESS:
      return templates
        .set('loading', false)

    case types.POST_TEMPLATE + FAIL:
      return templates
        .set('loading', false)

    case types.GET_TEMPLATES + START:
      return templates
        .set('loading', true)

    case types.GET_TEMPLATES + SUCCESS:
      const foldersData = []
      const templatesData = []
      let i = 1

      // folder
      response.data.folders &&
        response.data.folders.forEach((folder) => {
        const folderData = {
          id: folder.id,
          key: `${folder.id}-${(~~(Math.random() * 1e8)).toString(16)}`,
          index: i++,
          position: folder.position,
          parentId: folder.parent_id,
          title: folder.title,
          chapter: folder.chapter,
          new_objects_count: folder.new_objects_count,
          isOpen: folder.open_state === 1,
          children: new List([]),
        }

        // folder templates
        if (folder.templates && folder.templates.length > 0) {
          const subFolderTemplateData = []

          // sub-folder templates
          folder.templates.forEach((template) => {
            const templateData = {
              id: template.id,
              wp_product_id: template.wp_product_id,
              key: `${template.id}-${(~~(Math.random() * 1e8)).toString(16)}`,
              index: i++,
              position: template.position,
              folder_id: template.folder_id,
              parent_template_id: template.parent_template_id,
              status: template.status,
              title: template.title,
              can_be_used: template.can_be_used,
              use_button_title: template.use_button_title,
              updated_at: template.updated_at,
              is_new: template.is_new,
              isDownloadBlank: template.download_template === 1,
            }

            subFolderTemplateData.push(templateData)

            folderData.templates = arrToMap(subFolderTemplateData, TemplateRecord)
          })
        }

        foldersData.push(folderData)
      })

      response.data.templates &&
        response.data.templates.forEach((template) => {
          const templateData = {
            id: template.id,
            wp_product_id: template.wp_product_id,
            key: `${template.id}-${(~~(Math.random() * 1e8)).toString(16)}`,
            index: i++,
            position: template.position,
            folder_id: template.folder_id,
            parent_template_id: template.parent_template_id,
            status: template.status,
            title: template.title,
            can_be_used: template.can_be_used,
            use_button_title: template.use_button_title,
            updated_at: template.updated_at,
            is_new: template.is_new,
            isDownloadBlank: template.download_template === 1,
          }

          templatesData.push(templateData)
        })

      return templates
        .setIn(['folders'], arrToMap(foldersData, FolderRecord))
        .setIn(['templates'], arrToMap(templatesData, TemplateRecord))
        .set('loaded', true)
        .set('loading', false)

    case types.GET_TEMPLATES + FAIL:
      return templates
        .set('folders', new OrderedMap({}))
        .set('templates', new OrderedMap({}))
        .set('loading', false)
        .set('loaded', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.DELETE_TEMPLATE_FOLDER + START:
      return templates
        .set('loading', true)

    case types.DELETE_TEMPLATE_FOLDER + SUCCESS:
      const removedTemplates = payload.data.children.map(template => {
        template.folder_id = 0
        template.position = payload.data.position

        return template
      })

      return removedTemplates.length > 0 ?
        templates
          .set('loading', false)
          .deleteIn(['folders', payload.data.id])
          .mergeIn(['templates'], arrToMap(removedTemplates, TemplateRecord))
        :
        templates
          .set('loading', false)
          .deleteIn(['folders', payload.data.id])

    case types.DELETE_TEMPLATE_FOLDER + FAIL:
      return templates
        .set('loading', false)
        .set('error', error)
        .set('message', error.response.data.message)

    case types.POST_TEMPLATE_FOLDER + START:
      return templates
        .set('loading', true)

    case types.POST_TEMPLATE_FOLDER + SUCCESS:
      const newFolder = [{
        id: response.data.folder_id,
        key: `${response.data.folder_id}-${(~~(Math.random() * 1e8)).toString(16)}`,
        index: payload.index,
        position: payload.position,
        parentId: payload.parent_id,
        chapter: 'TEMPLATE',
        title: payload.title,
        isOpen: false,
      }]

      return templates
        .set('loading', false)
        .mergeIn(['folders'], arrToMap(newFolder, FolderRecord))

    case types.POST_TEMPLATE_FOLDER + FAIL:
      return templates
        .set('loading', false)
        .set('error', error.response.data)
        .set('message', error.message)

    case types.UPDATE_TEMPLATE_FOLDER + START:
      return templates

    case types.UPDATE_TEMPLATE_FOLDER + SUCCESS:
      return templates
        .updateIn(['folders', payload.id],
          (val) => {
            return val.set('title', payload.title)
          }
        )

    case types.UPDATE_TEMPLATE_FOLDER + FAIL:
      return templates
        .set('error', error.response.data)
        .set('message', error.message)

    case types.UPDATE_TEMPLATE_POSITION + START:
      return templates

    case types.UPDATE_TEMPLATE_POSITION + SUCCESS:
      const dragResult = response.data.results
      const draggedTemplate = payload.dragTemplate
      const draggedItemArr = dragResult.filter(item => item.id === payload.dragId)
      const draggedItem = draggedItemArr[0]
      const newFolderId = parseInt(draggedItem.new_folder_id)
      const prevFolderId = payload.prevFolderId

      draggedTemplate.position = draggedItem.new_position

      return draggedItem.type === 'folder' ?
        templates
          .update(['folders'], value => value.map(x => {
            const obj = x.toJS()
            const res = dragResult.find(el => el.id === obj.id)

            return res ?
              x.set('position', res.new_position) :
              x
          }))
          .update(['templates'], value => value.map(o => {
            const obj = o.toJS()
            const res = dragResult.find(el => el.id === obj.id)

            return res ?
              o.set('position', res.new_position) :
              o
          })) :
        newFolderId > 0 ?
          prevFolderId > 0 ?
            templates
              .deleteIn(['folders', prevFolderId, 'templates', payload.dragId])
              .mergeIn(['folders', newFolderId, 'templates'], arrToMap([draggedTemplate], TemplateRecord))
              .updateIn(['folders', newFolderId, 'templates'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              })) :
            templates
              .deleteIn(['templates', payload.dragId])
              .mergeIn(['folders', newFolderId, 'templates'], arrToMap([draggedTemplate], TemplateRecord))
              .updateIn(['folders', newFolderId, 'templates'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              })) :
          prevFolderId > 0 ?
            templates
              .deleteIn(['folders', prevFolderId, 'templates', payload.dragId])
              .mergeIn(['templates'], arrToMap([draggedTemplate], TemplateRecord))
              .update(['folders'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              }))
              .update(['templates'], value => value.map(o => {
                const obj = o.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  o.set('position', res.new_position) :
                  o
              }))
            :
            templates
              .update(['folders'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              }))
              .update(['templates'], value => value.map(o => {
                const obj = o.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  o.set('position', res.new_position) :
                  o
              }))

    case types.UPDATE_TEMPLATE_POSITION + FAIL:
      return templates

    case types.CLONE_TEMPLATES_CONTRACT + START:
      return templates
        .set('loading', true)

    case types.CLONE_TEMPLATES_CONTRACT + SUCCESS:
      const template = response.data.template
      const newTemplate = [{
        id: template.id,
        key: `${response.data.folder_id}-${(~~(Math.random() * 1e8)).toString(16)}`,
        wp_product_id: template.wp_product_id,
        index: 0,
        position: 0,
        folder_id: template.folder_id,
        status: template.status,
        title: template.title,
        can_be_used: template.can_be_used,
        use_button_title: template.use_button_title,
        updated_at: template.updated_at,
        isDownloadBlank: template.download_template === 1,
      }]

      return templates
        .set('loading', false)
        .mergeIn(['templates'], arrToMap(newTemplate, TemplateRecord))

    case types.CLONE_TEMPLATES_CONTRACT + FAIL:
      return templates
        .set('loading', false)
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case activeTypes.GET_TEMPLATES_NOTIFICATIONS + SUCCESS:
      return templates
        .set('newEntities', response.data.template)

    case activeTypes.GET_TEMPLATES_NOTIFICATIONS + FAIL:
      return templates
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case types.HANDLE_TABS_CHANGE:
      return templates
        .set('folders', new OrderedMap({}))
        .set('templates', new OrderedMap({}))
        .set('loading', false)
        .set('loaded', false)
        .set('deleted', false)
        .set('error', null)
        .set('message', null)

    default:
      return templates
  }
}

export default templates;
