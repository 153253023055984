export const START_PAGE = '/'

export const CONTRACT_PAGE = '/contract'
export const PRODUCT_PAGE = '/product'
export const THANK_YOU_PAGE = '/thank-you'
export const SIGN_PAGE = '/sign'

export const EDIT_PAGE = '/edit'
export const VIEW_PAGE = '/view'

export const LOGOUT_PAGE = '/logout'
