import { API } from '../../../constants/variables'
import * as types from './types'
import { getGMTDateTime } from '../../utils'



export function postProgressTemplate(data) {
  return {
    type: types.POST_PROGRESS_TEMPLATE,
    postAPI: `${API}envelope/create`,
    postData: data
  }
}

export function getProgressTemplatesList() {
  const dt = getGMTDateTime()

  return {
    type: types.GET_PROGRESS_TEMPLATES,
    getAPI: `${API}folder/list/envelope_progress?dt=${dt}`,
  }
}

export function postProgressTemplatesFolder(data) {
  return {
    type: types.ADD_PROGRESS_TEMPLATE_FOLDER,
    postAPI: `${API}folder/create`,
    payload: data,
    postData: data,
  }
}

export function updateProgressTemplatesPosition(data) {
  return {
    type: types.UPDATE_PROGRESS_TEMPLATE_POSITION,
    postAPI: `${API}folder/${data.id}/envelope_progress/set_positions`,
    payload: data,
    postData: data.data
  }
}

export function updateProgressTemplatesFolder(data) {
  return {
    type: types.UPDATE_PROGRESS_TEMPLATE_FOLDER,
    putAPI: `${API}folder/update/${data.id}`,
    payload: data,
    putData: {
      title: data.title,
      chapter: data.chapter,
      parent_id: 0
    }
  }
}

export function updateProgressTemplatesFolderOpenStatus(data) {
  return {
    type: types.UPDATE_PROGRESS_TEMPLATE_FOLDER_OPEN_STATUS,
    putAPI: `${API}folder/open_state/${data.id}`,
    payload: data,
    putData: {
      state: data.status === true ? 1 : 0,
    }
  }
}

export function deleteProgressTemplatesFolder(data) {
  return {
    type: types.DELETE_PROGRESS_TEMPLATE_FOLDER,
    deleteAPI: `${API}folder/delete/${data.id}`,
    payload: {data},
  }
}

export function deleteProgressTemplatesFolderGlobal(data) {
  return {
    type: types.DELETE_PROGRESS_TEMPLATE_FOLDER,
    deleteAPI: `${API}folder/delete/${data.id}/global`,
    payload: {data},
  }
}

export function downloadProgressTemplatePdf(id) {
  return {
    type: types.DOWNLOAD_PROGRESS_TEMPLATE,
    getAPI: `${API}envelope/${id}/download_template`,
    isBlob: true,
  }
}

export function putProgressTemplatesAction(data) {
  return {
    type: types.PUT_PROGRESS_TEMPLATE_ACTION,
    putAPI: `${API}envelope/${data.id}/action`,
    payload: data,
    putData: {
      action: data.action
    }
  }
}

export function getTemplateHistory(id) {
  const dt = getGMTDateTime()

  return {
    type: types.GET_PROGRESS_TEMPLATE_HISTORY,
    getAPI: `${API}envelope/${id}/history?dt=${dt}`,
    payload: {id},
  }
}

export function clearTemplateHS() {
  return {
    type: types.CLEAR_PROGRESS_TEMPLATE_HS,
  }
}
