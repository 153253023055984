import { Quill } from 'react-quill'
const Base = Quill.import('blots/block/embed')



class DividerBlot extends Base {
  static blotName = 'divider'
  static tagName = 'hr'
  static className = 'quill-divider'

  static create(value) {
    // const node = super.create(value)

    // node.setAttribute('style', 'page-break-before:always') // height:0px; margin-top:10px; margin-bottom:10px;

    return super.create(value)
  }

  static value(domNode) {
    // const elHeight = domNode.clientHeight

    // if (elHeight === 0) return

    // const ph1 = 1122
    // const dividerTopPosition = domNode.offsetTop - elHeight
    // const dividerTop = 1122 - dividerTopPosition
    //
    // if (dividerTopPosition !== 1094) {
    //   domNode.setAttribute('style', `margin-top: ${dividerTop}px`)
    // }

    return {}
  }
}

export default DividerBlot;
