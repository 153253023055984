import { Quill } from 'react-quill'
const Base = Quill.import('blots/embed')



class FieldBlot extends Base {
  static blotName = 'field'
  static className = 'quill-field'
  static tagName = 'img'

  static create(value) {
    let node = super.create()

    value.id && node.setAttribute('id', value.id)
    value.src && node.setAttribute('src', value.src)
    value.alt && node.setAttribute('alt', value.alt)
    value.class && node.setAttribute('class', value.class)
    if (value.type || Number.isInteger(value.type)) node.setAttribute('type', value.type)
    if (value.party || Number.isInteger(value.party)) node.setAttribute('party', value.party)

    return node
  }

  static value(domNode) {
    return {
      id: domNode.getAttribute('id') || null,
      src: domNode.getAttribute('src') || null,
      alt: domNode.getAttribute('alt') || null,
      class: domNode.getAttribute('class') || null,
      type: domNode.getAttribute('type') || null,
      party: domNode.getAttribute('party') || null,
    }
  }

  constructor(domNode, value) {
    super(domNode)

    this.data = {
      id: Number.parseInt(domNode.id),
      alt: domNode.alt,
    }

    domNode.removeAttribute('style')
    domNode.setAttribute('draggable', 'true')
    domNode.addEventListener('dragstart', this.dragHandler, false)
    domNode.addEventListener('dragend', this.dragEndHandler, false)
    domNode.addEventListener('mouseenter', this.hoverInHandler, false)
    domNode.addEventListener('mouseleave', this.hoverOutHandler, false)
    domNode.addEventListener('click', this.clickHandler, false)
  }

  hoverInHandler = (e) => {
    const event = new CustomEvent('field-hover-in', {
      bubbles: true,
      cancelable: true,
      detail: this
    })

    this.domNode.dispatchEvent(event)
  }

  hoverOutHandler = (e) => {
    const event = new CustomEvent('field-hover-out', {
      bubbles: true,
      cancelable: true,
      detail: this
    })

    this.domNode.dispatchEvent(event)
  }

  clickHandler = (event) => {
    this.domNode.dispatchEvent(new CustomEvent('field-clicked', {
      bubbles: true,
      detail: this
    }))
  }

  dragHandler = (event) => {
    event.dataTransfer.effectAllowed = 'move' // none
    event.dataTransfer.dropEffect = 'move' // none

    event.dataTransfer.setData('blot', JSON.stringify(this.data))
  }

  dragEndHandler = (event) => {
    event.target.removeAttribute('style')
  }

  remove = () => {
    this.domNode.dispatchEvent(new CustomEvent('field-removed', {
      bubbles: true,
      detail: this
    }))

    super.remove()
  }
}

export default FieldBlot;
