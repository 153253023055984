import React from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { MenuOutlined } from '@ant-design/icons'

import { useMediaQuery } from '../utils'
import styles from './header.module.scss'

import { SITE_DOMAIN } from '../../constants/variables'
import { LOGOUT_PAGE, START_PAGE } from '../../constants/siteMap'



const HeaderNav = ({ withClickHandler, ...props }) => {
  const userName = useSelector(store => store.user.toJS().userName)
  const userEmail = useSelector(store => store.user.toJS().userEmail)
  let isTablet = useMediaQuery('(max-width: 1024px)')
  const mobileMenu = (
    <Menu
      mode='vertical'
      style={{ width: `100%` }}
      triggerSubMenuAction={['click']}
    >
      <Menu.Item key='catalog'>
        <a href={`${SITE_DOMAIN}/catalog`} onClick={withClickHandler}>Contracts</a>
      </Menu.Item>
      <Menu.Item key='pricing'>
        <a href={`${SITE_DOMAIN}/pricing`} onClick={withClickHandler}>Pricing</a>
      </Menu.Item>
      <Menu.Item key='about'>
        <a href={`${SITE_DOMAIN}/about-us`} onClick={withClickHandler}>About</a>
      </Menu.Item>
      <Menu.Item key='blog'>
        <a href={`${SITE_DOMAIN}/blog-elementor`} onClick={withClickHandler}>Blog</a>
      </Menu.Item>
      {
        userName || userEmail ?
          <>
            <Menu.Item key='briefcase'>
              <Link to={{pathname: START_PAGE, search: '?tab=drafts'}} onClick={withClickHandler}>Go to Briefcase</Link>
            </Menu.Item>
            {/*<Menu.SubMenu key='sub-1' title='My Account'>*/}
            {/*  <Menu.Item key='history'>*/}
            {/*    <a href={`${SITE_DOMAIN}/profile/orders/`}>Order History</a>*/}
            {/*  </Menu.Item>*/}
            {/*  <Menu.Item key='subscriptions'>*/}
            {/*    <a href={`${SITE_DOMAIN}/profile/subscriptions/`}>Subscriptions</a>*/}
            {/*  </Menu.Item>*/}
            {/*  <Menu.Item key='payment'>*/}
            {/*    <a href={`${SITE_DOMAIN}/profile/payment-methods/`}>Billing Information</a>*/}
            {/*  </Menu.Item>*/}
            {/*  <Menu.Item key='edit'>*/}
            {/*    <a href={`${SITE_DOMAIN}/profile/edit-account/`}>Contact information</a>*/}
            {/*  </Menu.Item>*/}
            {/*  <Menu.Item key='logout'>*/}
            {/*    <Link to={LOGOUT_PAGE}>Logout</Link>*/}
            {/*  </Menu.Item>*/}
            {/*</Menu.SubMenu>*/}
            <Menu.Item key='account'>
              <a href={`${SITE_DOMAIN}/profile/edit-account/`} onClick={withClickHandler}>My Account</a>
            </Menu.Item>
            <Menu.Item key='logout'>
              <Link to={LOGOUT_PAGE} onClick={withClickHandler}>Logout</Link>
            </Menu.Item>
          </> :
          <>
            <Menu.Item key='sign-in'>
              <a href={`${SITE_DOMAIN}/login`} onClick={withClickHandler}>Sign In</a>
            </Menu.Item>
            <Menu.Item key='sign-up'>
              <a href={`${SITE_DOMAIN}/register`} onClick={withClickHandler}>Sign Up</a>
            </Menu.Item>
          </>
      }
    </Menu>
  )

  return isTablet ?
    <Dropdown
      overlay={mobileMenu}
      placement='bottomCenter'
      trigger={['click']}
      overlayClassName={styles.mobileDropDown}
    >
      <Button
        type='text'
        onClick={e => e.preventDefault()}
        icon={<MenuOutlined />}
      />
    </Dropdown> :
    (
      <nav>
        <a href={`${SITE_DOMAIN}/catalog`} onClick={withClickHandler}>Contracts</a>
        <a href={`${SITE_DOMAIN}/pricing`} onClick={withClickHandler}>Pricing</a>
        <a href={`${SITE_DOMAIN}/about-us`} onClick={withClickHandler}>About</a>
        <a href={`${SITE_DOMAIN}/blog-elementor`} onClick={withClickHandler}>Blog</a>
      </nav>
    )
}

export default HeaderNav;
