import { List, Record } from 'immutable'
import { START, SUCCESS, FAIL } from '../../../constants/actions'
import * as types from './types'
import * as templatesTypes from '../templates/types'
import * as templateDataTypes from '../templateData/types'



const TemplatePdfRecord = Record({
  pdfUrl: null,
  pdfName: null,
  coordinates: new List([]),
  loading: false,
  loaded: false,
  error: null,
  message: null,
})

const templatePdf = (templatePdf = new TemplatePdfRecord(), action) => {
  const {type, response, error, payload} = action

  switch(type) {
    case types.POST_CONVERT_PDF + START:
      return templatePdf
        .set('loaded', false)
        .set('loading', true)

    case types.POST_CONVERT_PDF + SUCCESS:
      return templatePdf
        .set('pdfUrl', response.file_path)
        .set('pdfName', response.file_name)
        .set('loading', false)
        .set('loaded', true)

    case types.POST_CONVERT_PDF + FAIL:
      return templatePdf
        .set('pdfUrl', null)
        .set('pdfName', null)
        .set('loading', false)
        .set('loaded', false)
        .set('error', error)
        .set('message', error.message)

    case types.SET_PDF_COORDINATES:
      return templatePdf
        .setIn(['coordinates'], payload.length > 0 ? List(payload) : new List([]))

    case templatesTypes.HANDLE_TABS_CHANGE:
      return templatePdf
        .set('pdfUrl', null)
        .set('pdfName', null)
        .set('coordinates', new List([]))
        .set('loading', false)
        .set('loaded', false)
        .set('error', null)
        .set('message', null)

    default:
      return templatePdf
  }
}

export default templatePdf
