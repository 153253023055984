export const loadingTemplatesSentSelector = (store) => store.templatesSent.loading
export const loadedTemplatesSentSelector = (store) => store.templatesSent.loaded
export const createdTemplatesSentSelector = (store) => store.templatesSent.created
export const deletedTemplatesSentSelector = (store) => store.templatesSent.deleted
export const sentNewEntitiesSelector = (store) => store.templatesSent.newEntities
export const pdfResponseTemplatesSentSelector = (store) => store.templatesSent.pdfResponse
export const errorTemplatesSentSelector = (store) => store.templatesSent.error
export const messageTemplatesSentSelector = (store) => store.templatesSent.message
export const foldersTemplatesSentSelector = (store) => store.templatesSent.folders.valueSeq().toArray()
export const templatesTemplatesSentSelector = (store) => store.templatesSent.templates.valueSeq().toArray()

