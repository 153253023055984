import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Draggable } from 'react-smooth-dnd'
import { Tabs, Button, Form, Input, Spin, Tooltip } from 'antd'
import { DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import styles from './style.module.scss'
import { FORM_VALIDATE_MESSAGES } from '../../constants/staticErrors'
import { FIELDS_COLORS } from '../../constants/variables'
import { postContractField, updateContractField, deleteContractField, updateContractFieldPosition } from '../../store/reducers/templatesAdmin/actions'



const AdminPartyTabs = ({tabs, ...props}) => {
  const { TabPane } = Tabs
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [isFormVisible, setFormVisible] = useState(false)
  const [fieldIndex, setFieldIndex] = useState(null)
  const [partyStepId, setPartyStepId] = useState(null)
  const [parties, setParties] = useState([])
  const [partyDropResult, setPartyDropResult] = useState(null)
  const contractId = useSelector(store => store.templatesAdmin.id)
  const contractLoading = useSelector(store => store.templatesAdmin.stepLoading)
  const contractLoaded = useSelector(store => store.templatesAdmin.stepLoaded)
  const contractParties = useSelector(store => store.templatesAdmin.parties.valueSeq().toArray())

  useEffect(() => {
    if (contractParties.length > 0) {
      const sortedParties = contractParties.map(item => item.sort((a, b) => parseFloat(a.position) - parseFloat(b.position)))

      setPartyStepId(contractParties[0][0].step_id)
      setParties(sortedParties)
    }
  }, [contractParties.length])

  useEffect(() => {
    if (!contractLoading && contractLoaded) {
      setParties(contractParties)
      hideForm()
    }
  }, [contractLoading])

  useEffect(() => {
    if (parties.length > 0 && partyDropResult) {
      const fieldValues = {
        position: partyDropResult.addedIndex,
      }

      dispatch(
        updateContractFieldPosition(fieldValues, parties, contractId, partyStepId, partyDropResult.payload.id)
      )
      setPartyDropResult(null)
    }
  }, [parties, partyDropResult])

  const showForm = (party, index) => {
    if (party) {
      form.setFieldsValue({
        title: party.title,
        partyNumber: party.party_number,
        fieldId: party.id
      })
    }

    setFormVisible(true)
    setFieldIndex(index)
  }

  const hideForm = () => {
    setFormVisible(false)
    setFieldIndex(null)
  }

  const handleFieldUpdate = (values) => {
    const fieldValues = {
      title: values.title,
      is_required: 1,
      val: 'FIELD',
      validation_type: 2,
      party_number: activeTabIndex,
      field_index: fieldIndex,
      field_type: 1
    }

    dispatch(
      updateContractField(fieldValues, contractId, partyStepId, values.fieldId)
    )
  }

  const handleFieldCreate = (values) => {
    const fieldValues = {
      field_index: fieldIndex,
      title: values.title,
      is_required: 1,
      val: 'FIELD',
      validation_type: 2,
      party_number: activeTabIndex,
      field_type: 1
    }

    dispatch(
      postContractField(fieldValues, contractId, partyStepId)
    )
  }

  const onFormFinishFailed = errorInfo => {
    console.log( 'Failed form: ', errorInfo )
  }

  const deleteField = () => {
    const fieldId = form.getFieldValue('fieldId')
    const fieldPartyNumber = form.getFieldValue('partyNumber')

    dispatch(
      deleteContractField(contractId, partyStepId, fieldId, {fieldPartyNumber, fieldIndex})
    )
  }

  const getPartyPayload = (index) => {
    return parties[activeTabIndex][index]
  }

  const applyDrag = (items, dropResult) => {
    const { removedIndex, addedIndex, payload } = dropResult
    if (removedIndex === null && addedIndex === null) return items

    const result = Array.from(items)
    let itemToAdd = payload

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0]
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd)
    }

    return result
  }

  const handlePartyDrop = (dropResult, index) => {
    setParties(
      parties.map((item, i) => i === index ? applyDrag(item, dropResult) : item)
    )
    setPartyDropResult(dropResult)
  }

  const onTabChange = (key) => {
    setActiveTabIndex(Number.parseInt(key))
  }

  return (
    <Tabs
      type='card'
      onChange={activeKey => onTabChange(activeKey)}
    >
      {tabs.map((item) => {
        const partiesList = tabs.length <= parties.length ? parties[item] : [] // .sort((a, b) => (a.is_predefined < b.is_predefined) ? 1 : -1) : []
        const tabTitleField = partiesList.length > 0 ? partiesList.filter(party => party.is_predefined === 3) : null
        const tabTitle = partiesList.length > 0 && tabTitleField[0] ? tabTitleField[0].title : `Party ${item + 1}`

        return <TabPane
          key={item}
          tab={
            <>
              <span>{tabTitle}</span>
              <Button
                type='text'
                onClick={() => {
                  const tabIndex = partiesList.findIndex(x => x.is_predefined === 3)
                  showForm(tabTitleField[0], tabIndex)
                }}
                icon={<span role="img" className="anticon anticon-edit"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.4805 2.28125C14.8086 2.63672 15 3.04688 15 3.53906C15 4.03125 14.8086 4.44141 14.4805 4.76953L4.85547 14.3945L1.65625 14.75C1.4375 14.75 1.27344 14.6953 1.16406 14.5312C1.02734 14.3945 0.972656 14.2305 1 14.0117L1.35547 10.8945L10.9805 1.26953C11.3086 0.941406 11.7188 0.75 12.2109 0.75C12.7031 0.75 13.1133 0.941406 13.4688 1.26953L14.4805 2.28125ZM4.44531 13.5742L11.6641 6.35547L9.39453 4.08594L2.17578 11.3047L1.90234 13.8477L4.44531 13.5742ZM13.8789 4.14062C14.043 3.97656 14.125 3.78516 14.125 3.53906C14.125 3.29297 14.043 3.07422 13.8789 2.91016L12.8398 1.87109C12.6758 1.70703 12.457 1.625 12.2109 1.625C11.9648 1.625 11.7734 1.70703 11.6094 1.87109L10.0234 3.45703L12.293 5.72656L13.8789 4.14062Z" fill="#19177D"/></svg></span>}
              />
            </>
          }
        >
          {partiesList.length > 0 &&
            <Container
              onDrop={e => handlePartyDrop(e, item)}
              getChildPayload={getPartyPayload}
              dropPlaceholder={{
                animationDuration: 150,
                showOnTop: true,
                className: styles.dropPreview
              }}
            >
              {partiesList.map((party, i) => {
                return party ?
                  <Draggable key={party.id}>
                    {party.is_predefined === 3 || party.is_deletable ?
                      isFormVisible && fieldIndex === i ?
                        <Spin size='small' spinning={contractLoading}>
                          <Form
                            form={form}
                            layout='inline'
                            name='edit-party'
                            className={party.is_predefined === 3 ? styles.itemTabForm : styles.itemForm}
                            onFinish={handleFieldUpdate}
                            onFinishFailed={onFormFinishFailed}
                            validateMessages={FORM_VALIDATE_MESSAGES}
                            initialValues={{
                              title: null,
                              fieldId: null,
                            }}
                          >
                            <Form.Item
                              name='title'
                              label='Field'
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                },
                              ]}
                            >
                              <Input
                                ref={(input) => {input && input.focus()}}
                                placeholder='Field name'
                              />
                            </Form.Item>
                            <Form.Item
                              name='fieldId'
                            >
                              <Input hidden={true} />
                            </Form.Item>
                            <Form.Item>
                              <Button type='text' icon={<CheckOutlined />} htmlType='submit'>Save</Button>
                              <Button type='text' icon={<CloseOutlined />} onClick={() => hideForm()}>Cancel</Button>
                              {party.is_predefined !== 3 &&
                                <Button type='text' icon={<DeleteOutlined />} onClick={() => deleteField()}>Delete</Button>
                              }
                            </Form.Item>
                          </Form>
                        </Spin> :
                        <Tooltip title='Edit field' placement='rightTop' className={party.is_predefined === 3 ? 'hidden' : ''}>
                          <p
                            className={styles.item}
                            style={FIELDS_COLORS[item]}
                            onClick={() => showForm(party, i)}
                          >{party.title}</p>
                        </Tooltip> :
                      <div key={party.id}>
                        <p className={styles.item + ' ' + styles.itemNotEditable}>{party.title}</p>
                      </div>
                    }
                  </Draggable> :
                  null
              })}
            </Container>
          }
          {
            isFormVisible && !Number.isInteger(fieldIndex) ?
              <Spin size='small' spinning={contractLoading}>
                <Form
                  layout='inline'
                  name='new-party'
                  className={styles.itemForm}
                  onFinish={handleFieldCreate}
                  onFinishFailed={onFormFinishFailed}
                  validateMessages={FORM_VALIDATE_MESSAGES}
                >
                  <Form.Item
                    name='title'
                    label='Field'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      ref={(input) => {input && input.focus()}}
                      placeholder='Field name'
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type='text' icon={<CheckOutlined />} htmlType='submit'>Save</Button>
                    <Button type='text' icon={<CloseOutlined />} onClick={() => hideForm()}>Cancel</Button>
                    <Button type='text' icon={<DeleteOutlined />} onClick={() => deleteField()}>Delete</Button>
                  </Form.Item>
                </Form>
              </Spin> :
              <Button
                type='text'
                onClick={() => showForm()}
              >
                + Add New Field
              </Button>
          }
        </TabPane>
      })}
    </Tabs>
  )
}

export default AdminPartyTabs;
