import { OrderedMap, List, Record } from 'immutable'

import { START, SUCCESS, FAIL } from '../../../constants/actions'
import { arrToMap } from '../../utils'
import * as types from './types'
import * as templatesTypes from '../templates/types'
import * as dataTypes from '../templateData/types'
import * as activeTypes from '../templatesAdmin/types'



const TemplatesRecord = Record({
  folders: new OrderedMap({}),
  templates: new OrderedMap({}),
  history: List(),
  newEntities: 0,
  loading: false,
  loaded: false,
  postLoading: false,
  postLoaded: false,
  updated: false,
  pdfResponse: null,
  error: null,
  message: null,
  hsOrigin: null,
})

const FolderRecord = Record({
  id: null,
  key: null,
  index: 0,
  position: 0,
  parentId: null,
  title: null,
  chapter: null,
  new_objects_count: 0,
  isOpen: false,
  children: new List([]),
  templates: new OrderedMap({}),
})

const TemplateRecord = Record({
  id: null,
  key: null,
  index: 0,
  position: 0,
  folder_id: null,
  status: null,
  title: null,
  updated_at: null,
  is_new: false,
  actions: null,
})

const templatesInProgress = (templatesInProgress = new TemplatesRecord(), action) => {
  const {type, response, error, payload} = action

  switch(type) {
    case types.GET_PROGRESS_TEMPLATES + START:
      return templatesInProgress
        .set('loading', true)
        .set('updated', false)
        .set('history', List())

    case types.GET_PROGRESS_TEMPLATES + SUCCESS:
      const foldersData = []
      const templatesData = []
      let i = 1

      // folder
      response.data.folders &&
      response.data.folders.forEach((folder) => {
        const folderData = {
          id: folder.id,
          key: `${folder.id}-${(~~(Math.random() * 1e8)).toString(16)}`,
          index: i++,
          position: folder.position,
          parentId: folder.parent_id,
          title: folder.title,
          chapter: folder.chapter,
          new_objects_count: folder.new_objects_count,
          isOpen: folder.open_state === 1,
          children: new List([]),
        }

        // folder templates
        if (folder.envelopes && folder.envelopes.length > 0) {
          const subFolderTemplateData = []

          // sub-folder templates
          folder.envelopes.forEach((template) => {
            const templateData = {
              id: template.id,
              key: `${template.id}-${(~~(Math.random() * 1e8)).toString(16)}`,
              index: i++,
              position: template.position,
              folder_id: template.folder_id,
              status: template.complete_status,
              title: template.title,
              updated_at: template.updated_at,
              is_new: template.is_new,
              actions: template.available_actions,
            }

            subFolderTemplateData.push(templateData)

            folderData.templates = arrToMap(subFolderTemplateData, TemplateRecord)
          })
        }

        foldersData.push(folderData)
      })

      // envelope
      response.data.envelopes &&
        response.data.envelopes.forEach((template) => {
          const templateData = {
            id: template.id,
            key: `${template.id}-${(~~(Math.random() * 1e8)).toString(16)}`,
            index: i++,
            position: template.position,
            folder_id: template.folder_id,
            status: template.complete_status,
            title: template.title,
            updated_at: template.updated_at,
            is_new: template.is_new,
            actions: template.available_actions,
          }

          templatesData.push(templateData)
        })

      return templatesInProgress
        .setIn(['folders'], arrToMap(foldersData, FolderRecord))
        .setIn(['templates'], arrToMap(templatesData, TemplateRecord))
        .set('loaded', true)
        .set('loading', false)
        .set('updated', true)

    case types.GET_PROGRESS_TEMPLATES + FAIL:
      return templatesInProgress
        .set('folders', new OrderedMap({}))
        .set('templates', new OrderedMap({}))
        .set('loading', false)
        .set('loaded', false)
        .set('updated', false)
        .set('error', error.response.data)
        .set('message', error.message)

    case types.POST_PROGRESS_TEMPLATE + START:
      return templatesInProgress
        .set('postLoading', true)
        .set('postLoaded', false)

    case types.POST_PROGRESS_TEMPLATE + SUCCESS:
      return templatesInProgress
        .set('postLoading', false)
        .set('postLoaded', true)

    case types.POST_PROGRESS_TEMPLATE + FAIL:
      return templatesInProgress
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('error', error)
        .set('message', error.response.data.message)

    case types.ADD_PROGRESS_TEMPLATE_FOLDER + START:
      return templatesInProgress
        .set('loading', true)

    case types.ADD_PROGRESS_TEMPLATE_FOLDER + SUCCESS:
      const newFolder = [{
        id: response.data.folder_id,
        key: `${response.data.folder_id}-${(~~(Math.random() * 1e8)).toString(16)}`,
        index: payload.index,
        position: payload.position,
        parentId: payload.parent_id,
        chapter: 'ENVELOPE_IN_PROGRESS',
        isOpen: false,
        title: payload.title,
      }]

      return templatesInProgress
        .set('loading', false)
        .mergeIn(['folders'], arrToMap(newFolder, FolderRecord))

    case types.ADD_PROGRESS_TEMPLATE_FOLDER + FAIL:
      return templatesInProgress
        .set('loading', false)
        .set('error', error.response.data)
        .set('message', error.message)

    case types.UPDATE_PROGRESS_TEMPLATE_FOLDER + START:
      return templatesInProgress
        .set('loading', true)

    case types.UPDATE_PROGRESS_TEMPLATE_FOLDER + SUCCESS:
      return templatesInProgress
        .set('loading', false)
        .updateIn(['folders', payload.id],
          (val) => {
            return val.set('title', payload.title)
          }
        )

    case types.UPDATE_PROGRESS_TEMPLATE_FOLDER + FAIL:
      return templatesInProgress
        .set('loading', false)
        .set('error', error.response.data)
        .set('message', error.message)

    case types.DELETE_PROGRESS_TEMPLATE_FOLDER + START:
      return templatesInProgress
        .set('loading', true)
        .set('message', null)

    case types.DELETE_PROGRESS_TEMPLATE_FOLDER + SUCCESS:
      const removedTemplates = payload.data.children.map(template => {
        template.folder_id = 0
        template.position = payload.data.position

        return template
      })

      return removedTemplates.length > 0 ?
        templatesInProgress
          .set('loading', false)
          .deleteIn(['folders', payload.data.id])
          .mergeIn(['templates'], arrToMap(removedTemplates, TemplateRecord))
        :
        templatesInProgress
          .set('loading', false)
          .deleteIn(['folders', payload.data.id])

    case types.DELETE_PROGRESS_TEMPLATE_FOLDER + FAIL:
      return templatesInProgress
        .set('loading', false)
        .set('error', error.response.data)
        .set('message', error.message)

    case types.DOWNLOAD_PROGRESS_TEMPLATE + START:
      return templatesInProgress
        .set('loading', true)
        .set('pdfResponse', null)

    case types.DOWNLOAD_PROGRESS_TEMPLATE + SUCCESS:
      return templatesInProgress
        .set('loading', false)
        .set('pdfResponse', response)

    case types.DOWNLOAD_PROGRESS_TEMPLATE + FAIL:
      return templatesInProgress
        .set('loading', false)
        .set('error', error.response.data)
        .set('message', error.message)

    case types.GET_PROGRESS_TEMPLATE_HISTORY + START:
      return templatesInProgress
        .set('loading', true)
        .set('history', List())

    case types.GET_PROGRESS_TEMPLATE_HISTORY + SUCCESS:
      const historyData = []

      response.data.history &&
      response.data.history.forEach((item) => {
        const historyItemData = {
          id: payload.id,
          date: item.date,
          status: item.status,
          action: item.action,
          activity: item.activity,
        }

        historyData.push(historyItemData)
      })

      response.errors.length > 0 &&
      response.errors.forEach((item) => {
        const historyErrorItemData = {
          key: payload.id,
          parentId: payload.id,
          title: item,
        }

        historyData.push(historyErrorItemData)
      })

      return templatesInProgress
        .set('loading', false)
        .mergeIn(['history'], List(historyData))

    case types.GET_PROGRESS_TEMPLATE_HISTORY + FAIL:
      return templatesInProgress
        .set('loading', false)
        .set('error', error.response.data)
        .set('message', error.message)

    case types.UPDATE_PROGRESS_TEMPLATE_POSITION + START:
      return templatesInProgress

    case types.UPDATE_PROGRESS_TEMPLATE_POSITION + SUCCESS:
      const dragResult = response.data.results
      const draggedTemplate = payload.dragTemplate
      const draggedItemArr = dragResult.filter(item => item.id === payload.dragId)
      const draggedItem = draggedItemArr[0]
      const newFolderId = parseInt(draggedItem.new_folder_id)
      const prevFolderId = payload.prevFolderId

      draggedTemplate.position = draggedItem.new_position

      // console.log('NEW folderID – ', newFolderId)
      // console.log('PREV folderID – ', prevFolderId)
      // console.log( draggedItem )
      // console.log( draggedTemplate )

      return draggedItem.type === 'folder' ?
        templatesInProgress
          .update(['folders'], value => value.map(x => {
            const obj = x.toJS()
            const res = dragResult.find(el => el.id === obj.id)

            return res ?
              x.set('position', res.new_position) :
              x
          }))
          .update(['templates'], value => value.map(o => {
            const obj = o.toJS()
            const res = dragResult.find(el => el.id === obj.id)

            return res ?
              o.set('position', res.new_position) :
              o
          })) :
        newFolderId > 0 ?
          prevFolderId > 0 ?
            templatesInProgress
              .deleteIn(['folders', prevFolderId, 'templates', payload.dragId])
              .mergeIn(['folders', newFolderId, 'templates'], arrToMap([draggedTemplate], TemplateRecord))
              .updateIn(['folders', newFolderId, 'templates'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              })) :
            templatesInProgress
              .deleteIn(['templates', payload.dragId])
              .mergeIn(['folders', newFolderId, 'templates'], arrToMap([draggedTemplate], TemplateRecord))
              .updateIn(['folders', newFolderId, 'templates'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              })) :
          prevFolderId > 0 ?
            templatesInProgress
              .deleteIn(['folders', prevFolderId, 'templates', payload.dragId])
              .mergeIn(['templates'], arrToMap([draggedTemplate], TemplateRecord))
              .update(['folders'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              }))
              .update(['templates'], value => value.map(o => {
                const obj = o.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  o.set('position', res.new_position) :
                  o
              }))
            :
            templatesInProgress
              .update(['folders'], value => value.map(x => {
                const obj = x.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  x.set('position', res.new_position) :
                  x
              }))
              .update(['templates'], value => value.map(o => {
                const obj = o.toJS()
                const res = dragResult.find(el => el.id === obj.id)

                return res ?
                  o.set('position', res.new_position) :
                  o
              }))

    case types.UPDATE_PROGRESS_TEMPLATE_POSITION + FAIL:
      return templatesInProgress

    case types.PUT_PROGRESS_TEMPLATE_ACTION + START:
      return templatesInProgress
        .set('loading', true)
        .set('hsOrigin', null)
        .set('message', null)
        .set('updated', false)

    case types.PUT_PROGRESS_TEMPLATE_ACTION + SUCCESS:
      const result = response.data.action_result
      let hsData = result.original ? result.original : null

      if (hsData) hsData.templateId = payload.id

      return payload.action === 'cancel' || payload.action === 'delete' ?
        payload.record.folder_id ?
          templatesInProgress
            .deleteIn(['folders', payload.record.folder_id, 'templates', payload.record.id])
            .set('loading', false)
            .set('hsOrigin', hsData)
            .set('updated', true)
            .set('error', result && result.errors && result.errors.length > 0 ? result.errors : null)
            .set('message', result ?
              result.errors && result.errors.length > 0 ?
                result.errors[0] :
                result.messages && result.messages.length > 0 ?
                  result.messages[0] :
                  null :
              null
            ) :
          templatesInProgress
            .deleteIn(['templates', payload.record.id])
            .set('loading', false)
            .set('hsOrigin', hsData)
            .set('updated', true)
            .set('error', result && result.errors && result.errors.length > 0 ? result.errors : null)
            .set('message', result ?
              result.errors && result.errors.length > 0 ?
                result.errors[0] :
                result.messages && result.messages.length > 0 ?
                  result.messages[0] :
                  null :
              null
            ) :
        templatesInProgress
          .set('loading', false)
          .set('hsOrigin', hsData)
          .set('error', result && result.errors && result.errors.length > 0 ? result.errors : null)
          .set('message', result ?
            result.errors && result.errors.length > 0 ?
              result.errors[0] :
              result.messages && result.messages.length > 0 ?
                result.messages[0] :
                null :
            null
          )

    case types.PUT_PROGRESS_TEMPLATE_ACTION + FAIL:
      return templatesInProgress
        .set('loading', false)
        .set('hsOrigin', null)
        .set('error', error.response.data)
        .set('message', error.message)

    case types.CLEAR_PROGRESS_TEMPLATE_HS:
      return templatesInProgress
        .set('loading', false)
        .set('hsOrigin', null)
        .set('error', null)
        .set('message', null)

    case activeTypes.GET_TEMPLATES_NOTIFICATIONS + SUCCESS:
      return templatesInProgress
        .set('newEntities', response.data.envelope_progress)

    case activeTypes.GET_TEMPLATES_NOTIFICATIONS + FAIL:
      return templatesInProgress
        .set('error', error.response ? error.response : error)
        .set('message', error.response && error.response.data.message ? error.response.data.message : error.message)

    case dataTypes.GET_ENVELOPE_DATA + SUCCESS:
      return templatesInProgress
        .set('loading', false)
        .set('loaded', false)
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('error', null)
        .set('message', null)

    case templatesTypes.HANDLE_TABS_CHANGE:
      return templatesInProgress
        .set('folders', new OrderedMap({}))
        .set('templates', new OrderedMap({}))
        .set('loading', false)
        .set('loaded', false)
        .set('postLoading', false)
        .set('postLoaded', false)
        .set('updated', false)
        .set('error', null)
        .set('message', null)

    default:
      return templatesInProgress
  }
}

export default templatesInProgress;
