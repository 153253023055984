import { List, Record } from 'immutable'
import { START, SUCCESS, FAIL } from '../../../constants/actions'
import * as types from './types'
import * as templatesTypes from '../templates/types'



const NotificationsRecord = Record({
  entities: List([]),
  loading: false,
  loaded: false,
  error: null,
  message: null,
})

const notifications = (notifications = new NotificationsRecord(), action) => {
  const {type, response, error, payload} = action

  switch(type) {
    case types.GET_NOTIFICATIONS + START:
      return notifications
        .set('loading', true)

    case types.GET_NOTIFICATIONS + SUCCESS:
      return notifications
        .set('entities', response.data ? response.data : List([]))
        .set('loading', false)
        .set('loaded', true)
        .set('error', response.errors.length > 0 ? response.errors : null)
        .set('message', response.errors.length > 0 ? response.errors[0] : null)

    case types.GET_NOTIFICATIONS + FAIL:
      return notifications
        .set('loading', false)
        .set('loaded', false)
        .set('error',
          error.response ?
            error.response.data.errors.length > 0 ?
              error.response.data.errors :
              null :
            error
        )
        .set('message',
          error.response ?
            error.response.data.errors.length > 0 ?
              error.response.data.errors[0] :
              null :
            error.message
        )

    case templatesTypes.HANDLE_TABS_CHANGE:
      return notifications
        .set('entities', List([]))
        .set('loading', false)
        .set('loaded', false)
        .set('error', null)
        .set('message', null)

    default:
      return notifications
  }
}

export default notifications
