const TOOLBAR_OFFSET = 105

class EditorStickyToolbar {
  constructor() {
    this.el = null
    this.anchor = null
    this.removedScrollClass = ''

    this.events()
  }

  didRender() {
    this.el = document.getElementsByClassName('ql-toolbar')[0]
    this.anchor = document.getElementsByClassName('toolbar-anchor')[0]
    this.removedScrollClass = this.el.className

    this.onResize()
  }

  onResize() {
    let { paddingLeft, paddingRight } = window.getComputedStyle(this.el.parentNode)
    let parentWidth = this.el.parentNode.offsetWidth - parseInt(paddingLeft) - parseInt(paddingRight)

    this.el.style.width = `${parentWidth}px`
  }

  onScroll() {
    let scroll = Math.max(document.documentElement.scrollTop, document.body.scrollTop)
    let topOffset = this.anchor.offsetTop + 75

    if (this.el.className.indexOf('ql-toolbar--fixed') !== -1) {
      if (scroll <= topOffset) {
        this.el.className = this.removedScrollClass
      }

    } else if (scroll >= topOffset) {
      this.el.className += ' ql-toolbar--fixed'
    }
  }

  events() {
    this.didRender()
    window.addEventListener('scroll', () => { this.onScroll() })
    window.addEventListener('resize', () => { this.onResize() })
  }
}

export default EditorStickyToolbar;
