import { API } from '../../../constants/variables'
import * as types from './types'



export function getUserData() {
  return {
    type: types.GET_USER_DATA,
    getAPI: `${API}user/profile`,
  }
}

export function userLogout() {
  return {
    type: types.LOGOUT_USER,
  }
}
