import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Tabs, Badge } from 'antd'

import AdminTemplatesTable from '../AdminTemplatesTable'
import TemplatesTable from '../TemplatesTable'
import TemplatesInProgressTable from '../TemplatesInProgressTable'
import TemplatesSentTable from '../TemplatesSentTable'
import styles from './tabs.module.scss'

import { getTemplatesList, handleTabsChange } from '../../store/reducers/templates/actions'
import { getProgressTemplatesList } from '../../store/reducers/templatesInProgress/actions'
import { getSentTemplatesList } from '../../store/reducers/templatesSent/actions'
import {
  getAdminTemplates,
  getContractCategories,
  getTemplatesNotifications
} from '../../store/reducers/templatesAdmin/actions'

import { categoriesContractSelector } from '../../store/reducers/templatesAdmin/selectors'
import { templatesNewEntitiesSelector } from '../../store/reducers/templates/selectors'
import { inProgressNewEntitiesSelector } from '../../store/reducers/templatesInProgress/selectors'
import { sentNewEntitiesSelector } from '../../store/reducers/templatesSent/selectors'
import { isAdminUserSelector } from '../../store/reducers/user/selectors'

import { START_PAGE } from '../../constants/siteMap'



const { TabPane } = Tabs
class BriefcaseTabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      stateActiveTab:
        props.location.search && props.location.search.includes('tab=admin') ?
          'admin' :
          props.location.search && props.location.search.includes('tab=drafts') ?
            '1' :
            props.location.search && props.location.search.includes('tab=in-progress') ?
              '2' :
              props.location.search && props.location.search.includes('tab=executed') ?
                '3' :
                props.isUserAdmin ?
                  'admin' :
                  '1'
    }
  }

  componentDidMount() {
    const { getContractCategories, contractCategories } = this.props
    const { stateActiveTab } = this.state

    !contractCategories.length && getContractCategories()

    stateActiveTab && this.changeTabCallback(stateActiveTab)
  }

  render() {
    const { isUserAdmin, templatesNewEntities, inProgressNewEntities, sentNewEntities } = this.props
    const { stateActiveTab } = this.state

    return (
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey={stateActiveTab.toString()}
        onChange={this.changeTabCallback}
        className={styles.tabs}
      >
        {isUserAdmin &&
          <TabPane key='admin' tab={<Link to={{pathname: START_PAGE, search: '?tab=admin'}}>Admin Contracts</Link>}>
            <AdminTemplatesTable
              tabKey={0}
            />
          </TabPane>
        }
        <TabPane
          key='1'
          tab={
            <Badge count={templatesNewEntities}>
              <Link to={{pathname: START_PAGE, search: '?tab=drafts'}}>
                Drafts
              </Link>
            </Badge>
          }
        >
          <TemplatesTable
            tabKey={1}
          />
        </TabPane>
        <TabPane key='2' tab={
          <Badge count={inProgressNewEntities}>
            <Link to={{pathname: START_PAGE, search: '?tab=in-progress'}}>
              Contracts in Progress
            </Link>
          </Badge>
        }>
          <TemplatesInProgressTable
            tabKey={2}
          />
        </TabPane>
        <TabPane key='3' tab={
          <Badge count={sentNewEntities}>
            <Link to={{pathname: START_PAGE, search: '?tab=executed'}}>
              Executed Contracts
            </Link>
          </Badge>
        }>
          <TemplatesSentTable
            tabKey={3}
          />
        </TabPane>
      </Tabs>
    )
  }

  changeTabCallback = (key) => {
    const { handleTabsChange, getContractCategories, getTemplatesList, getInProgressTemplatesList, getSentTemplatesList, getAdminTemplates, getTemplatesNotifications, history } = this.props

    handleTabsChange()
    getTemplatesNotifications()
    this.setState({
      stateActiveTab: key,
    }, () => {
      if (key === 'admin') {
        getAdminTemplates()
        getContractCategories()
        history.push({pathname: START_PAGE, search: '?tab=admin'})
      } else if (key === '1') {
        getTemplatesList()
        history.push({pathname: START_PAGE, search: '?tab=drafts'})
      } else if (key === '2') {
        getInProgressTemplatesList()
        history.push({pathname: START_PAGE, search: '?tab=in-progress'})
      } else {
        getSentTemplatesList()
        history.push({pathname: START_PAGE, search: '?tab=executed'})
      }
    })
  }
}

export default connect(
  store => {
    return {
      contractCategories: categoriesContractSelector(store),
      isUserAdmin: isAdminUserSelector(store),
      templatesNewEntities: templatesNewEntitiesSelector(store),
      inProgressNewEntities: inProgressNewEntitiesSelector(store),
      sentNewEntities: sentNewEntitiesSelector(store),
    }
  },
  {
    getContractCategories: getContractCategories,
    getAdminTemplates: getAdminTemplates,
    getTemplatesList: getTemplatesList,
    getInProgressTemplatesList: getProgressTemplatesList,
    getSentTemplatesList: getSentTemplatesList,
    getTemplatesNotifications: getTemplatesNotifications,
    handleTabsChange: handleTabsChange,
  }
)(withRouter(BriefcaseTabs));
