import React from 'react'
import { Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import styles from './preview.module.scss'



const DocumentNavigator = ({ currentPage, numPages, onPreviousPage, onNextPage }) => {
  if (numPages <= 1) return null

  return (
    <div className={styles.navigator}>
      <Button
        shape='circle'
        disabled={currentPage <= 1}
        icon={<LeftOutlined />}
        onClick={onPreviousPage}
        aria-label='Previous'
        className={styles.navigatorBtn}
      />
      <p className={styles.navigatorText}>
        Contract preview. Page {currentPage} of {numPages}
      </p>
      <Button
        shape='circle'
        disabled={currentPage === numPages}
        icon={<RightOutlined />}
        onClick={onNextPage}
        aria-label='Next'
        className={styles.navigatorBtn}
      />
    </div>
  )
}

export default DocumentNavigator;
