export const GET_TEMPLATE_DATA = 'GET_TEMPLATE_DATA'
export const GET_ENVELOPE_DATA = 'GET_ENVELOPE_DATA'
export const UPDATE_TEMPLATE_TITLE = 'UPDATE_TEMPLATE_TITLE'
export const UPDATE_TEMPLATE_FOLDER = 'UPDATE_TEMPLATE_FOLDER'
export const UPDATE_TEMPLATE_SIGNERS = 'UPDATE_TEMPLATE_SIGNERS'
export const UPDATE_TEMPLATE_CONTENT = 'UPDATE_TEMPLATE_CONTENT'
export const UPDATE_TEMPLATE_COORDINATES = 'UPDATE_TEMPLATE_COORDINATES'
export const RESET_TEMPLATE_DATA = 'RESET_TEMPLATE_DATA'
export const GET_ENVELOPE_PDF = 'GET_ENVELOPE_PDF'
export const HANDLE_TAB_CHANGE = 'HANDLE_TAB_CHANGE'
