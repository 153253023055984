import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { setRequestHeaders, setTokenCookies, getTokenCookies } from '../utils'
import { START, SUCCESS, FAIL } from '../../constants/actions'
import { TOKEN_DOMAIN } from '../../constants/variables'



export default store => next => action => {
  const {postAPI, postData, ...rest} = action

  if (!postAPI && !postData) return next(rest)

  let config = {}

  const instance = axios.create({
    withCredentials: true,
  })

  // Use interceptor to inject the token to requests
  axios.interceptors.request.use(request => {
    const token = getTokenCookies()

    if (token) {
      request.headers['wp-token'] = token
    } else {
      delete request.headers['wp-token']
    }

    return request
  })

  // Function that will be called to refresh authorization
  const refreshAuthLogic = failedRequest => instance.post(TOKEN_DOMAIN)
    .then(tokenRefreshResponse => {
      const token = tokenRefreshResponse.data.token

      setTokenCookies(token)

      return Promise.resolve()
    })

  createAuthRefreshInterceptor(axios, refreshAuthLogic)

  config = setRequestHeaders()

  next({...rest, type: rest.type + START})

  return axios.post(postAPI, postData, config)
    .then(res => res.data)
    .then(res => {
      next({...rest, type: rest.type + SUCCESS, response: res})
    })
    .catch(error => {
      next({...rest, type: rest.type + FAIL, error})
    })
}
