import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { message, Spin, Alert } from 'antd'

import {
  errorUserSelector,
  isAdminUserSelector,
  loadedUserSelector,
  loadingUserSelector,
  messageUserSelector
} from '../store/reducers/user/selectors'
import {
  titleContractSelector,
  categoriesContractSelector,
  loadingContractSelector,
  messageContractSelector,
  notificationContractSelector
} from '../store/reducers/templatesAdmin/selectors'
import {
  createContractDraft,
  getAdminTemplate,
  getContractCategories
} from '../store/reducers/templatesAdmin/actions'

import PageWrapper from '../components/PageWrapper'
import AdminSteps from '../components/AdminSteps'



class ContractPage extends Component {
  state = {
    error: null,
    errorInfo: null,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error, errorInfo})
  }

  componentWillUnmount() {
    message.destroy()
  }

  componentDidMount() {
    const { contractCategories, getContractCategories, getAdminTemplate, createContractDraft } = this.props
    const contractId = this.props.match.params.ContractId

    !contractCategories.length && getContractCategories()
    window.scrollTo(0, 0)

    contractId ?
      getAdminTemplate(contractId) :
      createContractDraft()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { contractMessage, contractNotification } = this.props

    if (
      prevProps.contractMessage !== contractMessage ||
      prevProps.contractNotification !== contractNotification
    ) {
      message.destroy()

      contractMessage ?
        message.error({
          content: contractMessage,
          className: 'error-message',
          duration: 0
        }) :
        contractNotification ?
          message.loading(contractNotification, 0) :
        message.destroy()
    }
  }

  render() {
    const { userIsAdmin, userLoading, userLoaded, userError, contractTitle, contractLoading } = this.props
    const { error, errorInfo } = this.state
    const contractId = this.props.match.params.ContractId

    if (error) {
      return <PageWrapper className='briefcase'>
        <Alert
          type='error'
          message={error}
          description={errorInfo}
        />
      </PageWrapper>
    }

    if (userLoading && !userLoaded) {
      return <div className='loader-wrapper'>
        <Spin size='middle' />
      </div>
    }

    if (contractLoading) {
      return <PageWrapper className='briefcase'>
        <div className='loader-wrapper'>
          <Spin size='middle' />
        </div>
      </PageWrapper>
    }

    if (userError && userError.status === 401 || userLoaded && !userIsAdmin) {
      console.log('Redirect to /')
      console.log(userError)

      return <Redirect to='/' />
    }

    return <PageWrapper className='briefcase'>
      <Helmet>
        <meta name='viewport' content='width=1200, initial-scale=1' />
        <title>{contractTitle ? contractTitle : 'New Contract'} - Creators Legal</title>
      </Helmet>
      <h1 style={{ marginTop: `20px`, marginBottom: `20px`, textAlign: 'center' }}>
        {contractTitle ? contractTitle : 'New Contract'}
      </h1>
      <AdminSteps locationContractId={contractId} />
    </PageWrapper>
  }
}

export default connect(
  store => {
    return {
      contractTitle: titleContractSelector(store),
      contractCategories: categoriesContractSelector(store),
      contractLoading: loadingContractSelector(store),
      contractMessage: messageContractSelector(store),
      contractNotification: notificationContractSelector(store),
      userIsAdmin: isAdminUserSelector(store),
      userLoading: loadingUserSelector(store),
      userLoaded: loadedUserSelector(store),
      userError: errorUserSelector(store),
      userMessage: messageUserSelector(store),
    }
  },
  {
    getAdminTemplate: getAdminTemplate,
    getContractCategories: getContractCategories,
    createContractDraft: createContractDraft,
  }
)(ContractPage);
