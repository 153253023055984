import { fromJS, List, Record } from 'immutable'
import { START, SUCCESS, FAIL } from '../../../constants/actions'
import * as types from './types'
import * as templatesTypes from '../templates/types'
import * as inProgressTypes from '../templatesInProgress/types'
import * as pdfTypes from '../templatePdf/types'



const TemplateDataRecord = Record({
  id: null,
  folderId: null,
  title: null,
  content: null,
  signers: List(),
  loading: false,
  loaded: false,
  updateLoading: false,
  updateLoaded: false,
  error: null,
  message: null,
  pdf: null,
  activeTab: 0
})

const templateData = (templateData = new TemplateDataRecord(), action) => {
  const {type, response, error, payload} = action

  switch(type) {
    case types.GET_TEMPLATE_DATA + START:
      return templateData
        .set('loaded', false)
        .set('loading', true)

    case types.GET_TEMPLATE_DATA + SUCCESS:
      const signersData = response.data.template ? response.data.template.signers : null

      return templateData
        .set('id', response.data.template ? response.data.template.id : null)
        .set('folderId', response.data.template ? response.data.template.folder_id : null)
        .set('title', response.data.template ? response.data.template.title : null)
        .set('content', response.data.template ? response.data.template.content : null)
        .setIn(['signers'], signersData ? List(signersData) : List())
        .set('loading', false)
        .set('loaded', true)
        .set('error', response.errors.length > 0 ? response.errors : null)
        .set('message', response.errors.length > 0 ? response.errors[0] : null)

    case types.GET_TEMPLATE_DATA + FAIL:
      return templateData
        .set('id', null)
        .set('folderId', null)
        .set('title', null)
        .set('content', null)
        .set('loading', false)
        .set('loaded', false)
        .set('error', error)
        .set('message', error.message)

    case types.UPDATE_TEMPLATE_TITLE + START:
      return templateData
        .set('updateLoading', true)
        .set('updateLoaded', false)

    case types.UPDATE_TEMPLATE_TITLE + SUCCESS:
      return templateData
        .set('title', payload.title)
        .set('updateLoading', false)
        .set('updateLoaded', true)

    case types.UPDATE_TEMPLATE_TITLE + FAIL:
      return templateData
        .set('updateLoading', false)
        .set('updateLoaded', false)
        .set('error', error)
        .set('message', error.message)

    case types.UPDATE_TEMPLATE_FOLDER + START:
      return templateData
        .set('updateLoading', true)
        .set('updateLoaded', false)

    case types.UPDATE_TEMPLATE_FOLDER + SUCCESS:
      return templateData
        .set('folderId', payload.folderId)
        .set('updateLoading', false)
        .set('updateLoaded', true)

    case types.UPDATE_TEMPLATE_FOLDER + FAIL:
      return templateData
        .set('updateLoading', false)
        .set('updateLoaded', false)
        .set('error', error)
        .set('message', error.message)

    case types.UPDATE_TEMPLATE_SIGNERS + START:
      return templateData

    case types.UPDATE_TEMPLATE_SIGNERS + SUCCESS:
      return templateData
        .setIn(['signers'], payload.length > 0 ? List(payload) : List())

    case types.UPDATE_TEMPLATE_SIGNERS + FAIL:
      return templateData
        .set('error', error)
        .set('message', error.message)

    case types.UPDATE_TEMPLATE_COORDINATES + START:
      return templateData

    case types.UPDATE_TEMPLATE_COORDINATES + SUCCESS:
      return templateData
        .setIn(['signers'], payload.length > 0 ? List(payload) : List())

    case types.UPDATE_TEMPLATE_COORDINATES + FAIL:
      return templateData
        .set('error', error)
        .set('message', error.message)

    case types.UPDATE_TEMPLATE_CONTENT + START:
      return templateData

    case types.UPDATE_TEMPLATE_CONTENT + SUCCESS:
      return templateData
        .set('content', payload.content)

    case types.UPDATE_TEMPLATE_CONTENT + FAIL:
      return templateData
        .set('error', error)
        .set('message', error.message)

    case types.GET_ENVELOPE_DATA + START:
      return templateData
        .set('loaded', false)
        .set('loading', true)

    case types.GET_ENVELOPE_DATA + SUCCESS:
      const signersArray = response.data.envelope.template.signers

      return templateData
        .set('id', response.data.envelope.id)
        .set('folderId', response.data.envelope.folder_id)
        .set('title', response.data.envelope.title)
        .set('content', response.data.envelope.template.content)
        .setIn(['signers'], signersArray.length > 0 ? List(signersArray) : List())
        .set('loading', false)
        .set('loaded', true)

    case types.GET_ENVELOPE_DATA + FAIL:
      return templateData
        .set('id', null)
        .set('folderId', null)
        .set('title', null)
        .set('content', null)
        .set('loading', false)
        .set('loaded', false)
        .set('error', error)
        .set('message', error.message)

    case types.GET_ENVELOPE_PDF + START:
      return templateData
        .set('loaded', false)
        .set('loading', true)

    case types.GET_ENVELOPE_PDF + SUCCESS:
      return templateData
        .set('pdf', response.data && response.data.action_result.data ? response.data.action_result.data.download_url : null)
        .set('loading', false)
        .set('loaded', true)
        .set('error', response.errors.length > 0 ? response.errors : null)
        .set('message', response.errors.length > 0 ? response.errors[0] : null)

    case types.GET_ENVELOPE_PDF + FAIL:
      return templateData
        .set('loading', false)
        .set('loaded', false)
        .set('error', error.response ? error.response.data.errors.length > 0 ? error.response.data.errors : null : error)
        .set('message', error.response ? error.response.data.errors.length > 0 ? error.response.data.errors[0] : null : error.message)

    case types.RESET_TEMPLATE_DATA:
      return templateData
        .set('id', null)
        .set('folderId', null)
        .set('title', null)
        .set('content', null)
        .setIn(['signers'], List())
        .set('loading', false)
        .set('loaded', false)

    case types.HANDLE_TAB_CHANGE:
      return templateData
        .set('activeTab', payload)

    case templatesTypes.HANDLE_TABS_CHANGE:
      return templateData
        .set('id', null)
        .set('folderId', null)
        .set('title', null)
        .set('content', null)
        .setIn(['signers'], List())
        .set('error', null)
        .set('message', null)
        .set('loading', false)
        .set('loaded', false)

    case inProgressTypes.POST_PROGRESS_TEMPLATE + SUCCESS:
      return templateData
        .set('id', response.data.envelope_id)

    //! Must updated templateData ['signers'] data
    case pdfTypes.SET_PDF_COORDINATES:
      return templateData
        .updateIn(['signers'], (list) => {
          return list.setIn([0, 'sign_here'], [{
            'x': payload[0].x,
            'y': payload[0].pageY,
            'page': payload[0].page
          }])
        })
        .updateIn(['signers'], (list) => {
          return list.setIn([0, 'initial_here'], [{
            'x': payload[2].x,
            'y': payload[2].pageY,
            'page': payload[2].page
          }])
        })
        .updateIn(['signers'], (list) => {
          return list.setIn([0, 'date_signed'], [{
            'x': payload[4].x,
            'y': payload[4].pageY,
            'page': payload[4].page
          }])
        })
        .updateIn(['signers'], (list) => {
          return list.setIn([1, 'sign_here'], [{
            'x': payload[1].x,
            'y': payload[1].pageY,
            'page': payload[1].page
          }])
        })
        .updateIn(['signers'], (list) => {
          return list.setIn([1, 'initial_here'], [{
            'x': payload[3].x,
            'y': payload[3].pageY,
            'page': payload[3].page
          }])
        })
        .updateIn(['signers'], (list) => {
          return list.setIn([1, 'date_signed'], [{
            'x': payload[5].x,
            'y': payload[5].pageY,
            'page': payload[5].page
          }])
        })

    default:
      return templateData
  }
}

export default templateData
