import React from 'react'
import { Spin } from 'antd'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { LOGIN_PAGE } from '../constants/variables'
import PageWrapper from '../components/PageWrapper'



const ErrorPage = () => {
  const userLoading = useSelector(store => store.user.toJS().loading)
  const userLoaded = useSelector(store => store.user.toJS().loaded)

  if (userLoading && !userLoaded) {
    return <div className='loader-wrapper'>
      <Spin size='middle' />
    </div>
  }

  return <PageWrapper className='error-page'>
    <Helmet>
      <title>Oops, That page doesn’t exist - Creators Legal</title>
    </Helmet>
    <h1>That page doesn’t exist...</h1>
    <h3>The link you clicked may be broken or the page may have been removed.</h3>
    <nav>
      <a href={`${LOGIN_PAGE}/?redirect_to=/bcr`} className='ant-btn ant-btn-primary'>Go to home page</a>
      <Link to={'/'} className='ant-btn'>Go to briefcase</Link>
    </nav>
  </PageWrapper>
}

export default ErrorPage;
