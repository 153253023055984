export const idContractSelector = (store) => store.templatesAdmin.id
export const tmpIdContractSelector = (store) => store.templatesAdmin.tmpId
export const titleContractSelector = (store) => store.templatesAdmin.title
export const contentContractSelector = (store) => store.templatesAdmin.content
export const hsDataSelector = (store) => store.templatesAdmin.hsData
export const validationTypesContractSelector = (store) => store.templatesAdmin.validationTypes.valueSeq().toArray()
export const partiesContractSelector = (store) => store.templatesAdmin.parties.valueSeq().toArray()
export const categoriesContractSelector = (store) => store.templatesAdmin.categories.valueSeq().toArray()
export const templatesContractSelector = (store) => store.templatesAdmin.templates.valueSeq().toArray()
export const stepsContractSelector = (store) => store.templatesAdmin.steps.valueSeq().toArray()
export const fieldContractSelector = (store) => store.templatesAdmin.field.valueSeq().toArray()[0]
export const contentPDFSelector = (store) => store.templatesAdmin.contentPdf
export const canBeUsedContractSelector = (store) => store.templatesAdmin.canBeUsed
export const postLoadingContractSelector = (store) => store.templatesAdmin.postLoading
export const postLoadedContractSelector = (store) => store.templatesAdmin.postLoaded
export const getLoadingContractSelector = (store) => store.templatesAdmin.getLoading
export const getLoadedContractSelector = (store) => store.templatesAdmin.getLoaded
export const stepLoadingContractSelector = (store) => store.templatesAdmin.stepLoading
export const stepLoadedContractSelector = (store) => store.templatesAdmin.stepLoaded
export const loadingContractSelector = (store) => store.templatesAdmin.loading
export const loadedContractSelector = (store) => store.templatesAdmin.loaded
export const updatedContractSelector = (store) => store.templatesAdmin.updated
export const errorContractSelector = (store) => store.templatesAdmin.error
export const messageContractSelector = (store) => store.templatesAdmin.message
export const notificationContractSelector = (store) => store.templatesAdmin.notification
